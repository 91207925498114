import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Typography } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import CountryExcluder from 'src/components/shippingtiers/CountryExcluder';
import { Countries } from 'src/utils/Countries';
import { useLazyQuery, gql } from '@apollo/client';
import ShippingTierList from 'src/components/shippingtiers/ShippingTierList';
import AddShippingTier from 'src/components/shippingtiers/AddShippingTier';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { ROLES, useAuthState } from 'src/contexts/authContext';

const GET_SHIPPINGTIERS = gql`
  query {
    ShippingTier {
      id
      name
      carrierCode
      costCad
      costUsd
      countries {
        name
        excludeShipping
      }
      warehouse
      shipDutyPaid
      notes
      queueOrderFulfillment
    }
    Country {
      name
      excludeShipping
      shippingTier {
        id
      }
    }
  }
`;

type ShippingTier = {
  carrierCode: string;
  costCad: number;
  costUsd: number;
  countries: string[];
  id: string;
  name: string;
  notes: string;
  shipDutyPaid: boolean;
  warehouse: string;
};

const InventoryShippingTiers: FC = () => {
  const authState = useAuthState();
  const { settings } = useSettings();
  const [editTier, setEditTier] = useState<ShippingTier | undefined>(undefined);
  const [availableCountries, setAvailableCountries] = useState(
    Array.from(Countries.keys())
  );
  const [open, setOpen] = useState(false);

  const [getShippingTiers, { loading, data, error }] = useLazyQuery(
    GET_SHIPPINGTIERS,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    getShippingTiers();
  }, []);

  useEffect(() => {
    if (data) {
      const takenCountries = new Set(
        data.Country.filter((y) => y.excludeShipping || y.shippingTier).map(
          (y) => y.name
        )
      );
      setAvailableCountries(
        Array.from(Countries.keys())
          .filter((a: string) => !takenCountries.has(a))
          .sort((a, b) => {
            var nameA = a.toUpperCase(); // ignore upper and lowercase
            var nameB = b.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
      );
    }
    if (error) {
      console.log('error', error);
    }
    if (error) {
      console.log('error', error);
    }
  }, [data, error, error]);

  const handleClose = () => {
    getShippingTiers();
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Inventory | Sens.ai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Typography color="textSecondary" variant="overline">
                Inventory Shipping Tiers
              </Typography>
            </Grid>
            {authState.minAuthLevelMet(ROLES.CSA_MANAGER) && (
              <Grid item md={12} xs={12}>
                <CountryExcluder availableCountries={availableCountries} />
              </Grid>
            )}
            {authState.minAuthLevelMet(ROLES.CSA_MANAGER) && (
              <>
                {loading ? (
                  <Grid item md={12} xs={12}>
                    <CircularProgress color="secondary" />
                  </Grid>
                ) : (
                  <Grid item md={12} xs={12}>
                    <Button
                      onClick={() => {
                        setEditTier(undefined);
                        setOpen(true);
                      }}
                      color="primary"
                    >
                      Add New Tier
                    </Button>
                    <ShippingTierList
                      onEdit={(t) => {
                        setEditTier(t);
                        setOpen(true);
                      }}
                      shippingTiers={data ? data.ShippingTier : []}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Container>
      </Box>

      {(authState.roles.includes('ADMIN') ||
        authState.roles.includes('CSA_MANAGER') ||
        authState.roles.includes('CSA_ADMIN')) && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add New Tier</DialogTitle>
          <DialogContent>
            <AddShippingTier
              onSave={() => {
                handleClose();
              }}
              tier={editTier}
              remainingCountries={availableCountries}
              isDefault={editTier?.name.toLowerCase() === 'default'}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default InventoryShippingTiers;
