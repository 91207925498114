import { FC } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  experimentalStyled,
  Paper,
  Button,
  CircularProgress
} from '@mui/material';
import { format } from 'date-fns';

import { getDollars } from '../../../utils/currency';

export type InvoiceData = {
  id: string;
  created: {
    formatted: string;
  };
  updated: {
    formatted: string;
  };
  subscriptionId: string;
  time: number;
  provider: string;
  providerCustomerId: string;
  providerInvoiceId: string;
  providerEventType: string;
  providerStatus: string;
  status: string;
  amountDue: number;
  amountPaid: number;
  attemptCount: number;
  attempted: number;
  paid: boolean;
  billingReason: string;
  hostedInvoiceUrl: string;
  period: string;
  periodStart: {
    formatted: string;
  };
  periodEnd: {
    formatted: string;
  };
  currency: string;
  tax: number;
  total: number;
  couponCode?: string;
  discountAmount?: number;
};

const StyledTable = experimentalStyled(Table)({
  minWidth: 650
});

type Props = {
  isShown: boolean;
  invoices: InvoiceData[];
  handleClickInvoiceRefund: (id: string, provider: string) => void;
  refundInvoiceLoading: boolean;
  userLoading: boolean;
};

const UserInvoices: FC<Props> = ({
  invoices,
  isShown,
  handleClickInvoiceRefund,
  refundInvoiceLoading,
  userLoading
}) => {
  let invoiceData: InvoiceData[] = [];

  if (invoices) {
    invoiceData = invoices.filter((item) => item);
  }

  return (
    <>
      {isShown && invoices && (
        <TableContainer component={Paper}>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">Refund</TableCell>
                <TableCell align="left">Provider Invoice Id</TableCell>
                <TableCell align="left">Provider</TableCell>
                <TableCell align="left">Period</TableCell>
                <TableCell align="left">Event Type</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Price</TableCell>
                <TableCell align="left">Coupon</TableCell>
                <TableCell align="left">Start Period</TableCell>
                <TableCell align="left">End Period</TableCell>
                <TableCell align="left">Subscription Id</TableCell>
              </TableRow>
            </TableHead>
            {refundInvoiceLoading || userLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={10}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {invoiceData.map((invoice: InvoiceData) => (
                  <TableRow>
                    <TableCell align="left">
                      {(invoice?.provider === 'stripe' ||
                        invoice.provider === 'google') &&
                      invoice.status === 'paid' ? (
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={() =>
                            handleClickInvoiceRefund(
                              invoice.providerInvoiceId,
                              invoice.provider
                            )
                          }
                        >
                          Refund
                        </Button>
                      ) : (
                        'n/a'
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {invoice?.providerInvoiceId}
                    </TableCell>
                    <TableCell align="left">{invoice?.provider}</TableCell>
                    <TableCell align="left">{invoice?.period}</TableCell>
                    <TableCell align="left">
                      {invoice?.providerEventType}
                    </TableCell>
                    <TableCell align="left">{invoice?.status}</TableCell>
                    <TableCell align="left" sx={{ minWidth: '150px' }}>
                      {`${getDollars(
                        invoice?.total
                      )} ${invoice?.currency?.toUpperCase()}`}
                    </TableCell>
                    <TableCell align="left" sx={{ minWidth: '150px' }}>
                      {invoice.couponCode && invoice.discountAmount
                        ? `${getDollars(invoice.discountAmount)} (${
                            invoice.couponCode
                          })`
                        : 'N/A'}
                    </TableCell>
                    <TableCell align="left" sx={{ minWidth: '150px' }}>
                      {format(
                        new Date(invoice?.periodStart?.formatted),
                        'MMM dd, yyyy'
                      )}
                    </TableCell>
                    <TableCell align="left" sx={{ minWidth: '150px' }}>
                      {format(
                        new Date(invoice?.periodEnd?.formatted),
                        'MMM dd, yyyy'
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <Box sx={{ display: 'flex' }}>
                        <Tooltip
                          title={invoice?.subscriptionId}
                          placement="top-start"
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              maxWidth: '150px',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {invoice?.subscriptionId}
                          </span>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </StyledTable>
        </TableContainer>
      )}
    </>
  );
};

export default UserInvoices;
