import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { gql, useMutation } from '@apollo/client';
import { Formik, FormikErrors, Form, FormikHelpers } from 'formik';

import {
  Box,
  Button,
  CardActions,
  Container,
  Grid,
  Typography,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Card
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($emailFrom: String!, $emailTo: String!) {
    UpdateUserEmail(emailFrom: $emailFrom, emailTo: $emailTo)
  }
`;

interface props {
  email: string;
  onDone: Function;
}

const UpdateUserEmail = ({ email, onDone }: props) => {
  const navigate = useNavigate();
  const [emailSubmitted, setEmailSubmitted] = useState('');
  const [updateEmail, { loading, data, error }] =
    useMutation(UPDATE_USER_EMAIL);

  useEffect(() => {
    if (data && data.UpdateUserEmail === true) {
      onDone(emailSubmitted);
      navigate(`/dashboard/users/${emailSubmitted}`);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <Card
          elevation={0}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <CircularProgress color="secondary" />
        </Card>
      ) : (
        <>
          <Formik
            initialValues={{
              emailTo: email
            }}
            validationSchema={Yup.object().shape({
              emailTo: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required')
            })}
            onSubmit={(values, actions) => {
              setEmailSubmitted(values.emailTo);
              updateEmail({
                variables: { emailFrom: email, emailTo: values.emailTo }
              });
            }}
          >
            {({ errors, handleBlur, handleChange, values }) => (
              <Form style={{ width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      label="New Email"
                      id="emailTo"
                      name="emailTo"
                      type="email"
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      value={values.emailTo}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Button color="primary" variant="contained" type="submit">
                      Update Email
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="button"
                      onClick={() => {
                        onDone();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  {error && (
                    <Grid item xs={12}>
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>{error.message}</FormHelperText>
                      </Box>
                    </Grid>
                  )}
                  {data && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText>
                        Email Updated Succesfully. Please update the Klaviyo and
                        Stripe email.
                      </FormHelperText>
                    </Box>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default UpdateUserEmail;
