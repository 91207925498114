import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  CardHeader
} from '@mui/material';
import MultiListSelector from '../MultiListSelector';

const GET_COUNTRIES = gql`
  query {
    Country(filter: { excludeShipping: true }) {
      name
      excludeShipping
    }
  }
`;

const UPDATE_EXCLUDE_COUNTRIES = gql`
  mutation UpdateShippingExcludeCountries(
    $countries: [ShippingExcludeCoutryInput]
  ) {
    UpdateShippingExcludeCountries(countries: $countries)
  }
`;

export interface CountryExcluderProps {
  availableCountries: string[];
}

const CountryExcluder = ({ availableCountries }: CountryExcluderProps) => {
  const [getExcludeCountries, { loading, data, error }] = useLazyQuery(
    GET_COUNTRIES,
    {
      fetchPolicy: 'network-only'
    }
  );
  const [
    executeUpdateExclude,
    {
      loading: loadingUpdateExclude,
      data: dataUpdateExclude,
      error: errorUpdateExclude
    }
  ] = useMutation(UPDATE_EXCLUDE_COUNTRIES, { errorPolicy: 'all' });

  const [right, setRight] = useState<string[]>([]);
  const [left, setLeft] = useState<string[]>(availableCountries);
  const [selectedRight, setSelectedRight] = useState<string[]>([]);
  const [selectedLeft, setSelectedLeft] = useState<string[]>([]);

  useEffect(() => {
    getExcludeCountries();
  }, []);

  useEffect(() => {
    if (data) {
      const r = data.Country.map((x) => x.name);
      setRight(r);
      const b = new Set(r);
      setLeft(
        Array.from(
          new Set(
            availableCountries.map((x: string) => x).filter((x) => !b.has(x))
          )
        )
      );
    }
    if (error) {
      console.log('error', error);
    }
  }, [data, error, availableCountries]);

  useEffect(() => {
    if (dataUpdateExclude) {
      console.log('dataUpdateExclude', dataUpdateExclude);
      getExcludeCountries();
    }
    if (errorUpdateExclude) {
      console.log('error', errorUpdateExclude);
    }
  }, [dataUpdateExclude, errorUpdateExclude, getExcludeCountries]);

  const updateSelectedLeft = (checked: string[]) => {
    const selected = new Set(checked);
    console.log('selected', { selected, left });
    const ls = left
      .map((x: string) => x)
      .filter((x: string) => selected.has(x.toLowerCase()));
    console.log('Selected Left:', ls);
    setSelectedLeft(ls);
  };
  const updatedSelectedRight = (checked: string[]) => {
    const selected = new Set(checked);
    const rs = right
      .map((x: string) => x)
      .filter((x: string) => selected.has(x.toLowerCase()));
    console.log('Selected Right:', rs);
    setSelectedRight(rs);
  };

  const addCountriesToExclude = () => {
    executeUpdateExclude({
      variables: {
        countries: selectedLeft.map((x: string) => ({
          name: x,
          exclude: true
        }))
      }
    });
    updateSelectedLeft([]);
  };

  const removeCountriesFromExclude = () => {
    executeUpdateExclude({
      variables: {
        countries: selectedRight.map((x: string) => ({
          name: x,
          exclude: false
        }))
      }
    });
    updatedSelectedRight([]);
  };

  return (
    <>
      <Card>
        <CardHeader title="Country Exclusion Mappings" />
        <CardContent
          sx={{
            alignItems: 'center'
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant={'subtitle1'}>Countries</Typography>
              <MultiListSelector
                items={left}
                onCheckChange={updateSelectedLeft}
              />
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                {loadingUpdateExclude || loading ? (
                  'Loading'
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={addCountriesToExclude}
                      disabled={selectedLeft.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <br />
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={removeCountriesFromExclude}
                      disabled={selectedRight.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant={'subtitle1'}>Exclude Countries</Typography>
              <MultiListSelector
                items={right}
                onCheckChange={updatedSelectedRight}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CountryExcluder;
