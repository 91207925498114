import { Helmet } from 'react-helmet';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Button, Container, Grid, Modal, Typography } from '@mui/material';

import useSettings from '../../hooks/useSettings';
import CreateCoupon from '../../components/dashboard/coupons/CreateCoupon';
import CouponList from '../../components/dashboard/coupons/CouponList';
import { useState } from 'react';
import EditCoupon from 'src/components/dashboard/coupons/EditCoupon';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import CouponListDataGrid from 'src/components/dashboard/coupons/CouponListDataGrid';

export type DiscountType = 'numberOfDays' | 'percentage' | 'fixedAmount';

interface CreateCouponInputs {
  validStartDate: Dayjs;
  validEndDate: Dayjs;
  code: string;
  description: string;
  discountType: DiscountType;
  discountDays?: number;
  discountAmountCad?: number;
  discountAmountUsd?: number;
  discountPercentage?: number;
  maxRedemptions?: number | string;
  disabled?: boolean;
  productIds?: string[];
  affiliateId?: string;
}

const initialValues: CreateCouponInputs = {
  validStartDate: dayjs(),
  validEndDate: dayjs(),
  code: '',
  description: '',
  discountType: 'numberOfDays',
  discountDays: 0,
  discountAmountCad: 0,
  discountAmountUsd: 0,
  discountPercentage: 0,
  maxRedemptions: '',
  disabled: false,
  productIds: [],
  affiliateId: ''
};

const Coupons = () => {
  const { settings } = useSettings();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState(initialValues);
  const [isEditMode, setIsEditMode] = useState(false);
  const handleCloseModal = () => setModalOpen(false);
  const handleOpenModal = () => setModalOpen(true);

  const handleOpenCreate = () => {
    setIsEditMode(false);
    handleOpenModal();
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Coupons | Sens.ai</title>
      </Helmet>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-edit-coupons"
        aria-describedby="modal-edit-coupons"
        sx={{
          width: '80%',
          margin: '100px auto 0 auto'
        }}
      >
        <>
          {!isEditMode && (
            <CreateCoupon
              initialValues={initialValues}
              setModalOpen={setModalOpen}
            />
          )}
          {isEditMode && (
            <EditCoupon
              currentCoupon={currentCoupon}
              setModalOpen={setModalOpen}
            />
          )}
        </>
      </Modal>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize="" color="textPrimary" variant="h5">
                Coupons
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                endIcon={<ArrowRightIcon fontSize="small" />}
                variant="text"
                onClick={handleOpenCreate}
              >
                Create a new coupon
              </Button>
            </Grid>
            <Grid item xs={12}>
              {/* <CouponList
                handleOpenEdit={handleOpenModal}
                setCurrentCoupon={setCurrentCoupon}
                setIsEditMode={setIsEditMode}
              /> */}
              <CouponListDataGrid
                handleOpenEdit={handleOpenModal}
                setCurrentCoupon={setCurrentCoupon}
                setIsEditMode={setIsEditMode}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Coupons;
