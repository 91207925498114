import React, { useEffect, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Box, FormHelperText } from '@mui/material';
import ArrowRightIcon from '../../../icons/ArrowRight';

const UNLOCK_USER_PROTOCOLS = gql`
  mutation UnlockUserProtocols(
    $userEmail: String!
    $protocolId: String!
    $numSessions: Int!
  ) {
    UnlockUserProtocols(
      userEmail: $userEmail
      protocolId: $protocolId
      numSessions: $numSessions
    )
  }
`;

const UnlockUserProtocolsExecuter = (props: {
  email: string;
  requirements?: { protocolId: string; numberOfSessions: number }[];
}) => {
  const [executeUnlockUserProtocols, { loading, data, error }] = useMutation(
    UNLOCK_USER_PROTOCOLS,
    { errorPolicy: 'all' }
  );

  const [overallLoading, setOverallLoading] = useState(false);
  const [overallDone, setOverallDone] = useState(false);
  const [requirements, setRequirements] = useState<
    { protocolId: string; numberOfSessions: number }[]
  >([]);

  useEffect(() => {
    setOverallDone(false);
    if (props.requirements && props.requirements.length > 0) {
      // if there are requirements the run them
      setRequirements(props.requirements);
    }
  }, []);

  const maxSessions = 2;
  let isFirst = true;

  useEffect(() => {
    if (requirements && requirements.length > 0) {
      setOverallLoading(true);
      // if there are requirements the run them
      const overMaxSessions = requirements[0].numberOfSessions > maxSessions;
      const numSessions = overMaxSessions
        ? maxSessions
        : requirements[0].numberOfSessions;
      // run
      console.log(
        'running requirements...',
        requirements[0].protocolId,
        numSessions
      );
      executeUnlockUserProtocols({
        variables: {
          userEmail: props.email,
          protocolId: requirements[0].protocolId,
          numSessions: numSessions
        }
      });
    } else if (overallLoading) {
      setOverallLoading(false);
      setOverallDone(true);
    }
  }, [requirements]);

  useEffect(() => {
    if (!error && data) {
      const req = [...requirements];
      const overMaxSessions = requirements[0].numberOfSessions > maxSessions;
      if (overMaxSessions) {
        req[0].numberOfSessions = req[0].numberOfSessions - maxSessions;
      } else {
        req.shift();
      }

      if (req.length > 0) {
        // extra delay first time
        // delay all times

        const delay = isFirst ? 4500 : 1500;
        isFirst = false;
        setTimeout(() => {
          setRequirements(req);
        }, delay);
      } else {
        setRequirements(req);
        setOverallLoading(false);
        setOverallDone(true);
      }
    }
    if (error) {
      console.log('error', error);
    }
  }, [data, error]);

  return (
    <>
      {error && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{error.message}</FormHelperText>
        </Box>
      )}
      {overallLoading && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText>Running Sessions...</FormHelperText>
        </Box>
      )}
      {overallDone && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText>Sessions succesfully run!</FormHelperText>
        </Box>
      )}
    </>
  );
};

export default UnlockUserProtocolsExecuter;
