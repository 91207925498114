import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Typography } from '@mui/material';
import { OverviewUsers } from '../../components/dashboard/overview';
import useSettings from '../../hooks/useSettings';
import { useAuthState } from '../../contexts/authContext';

const Overview: FC = () => {
  const { settings } = useSettings();
  const authState = useAuthState();

  return (
    <>
      <Helmet>
        <title>Dashboard: Overview | Sens.ai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container spacing={3}>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <Grid item>
                <Typography color="textSecondary" variant="overline">
                  Overview
                </Typography>
                <Typography color="textPrimary" variant="h5">
                  Hi{`, ${authState && authState.firstName}`}
                </Typography>
                {/* <Typography color="textSecondary" variant="subtitle2">
                  Here&apos;s what&apos;s happening today
                </Typography> */}
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <OverviewUsers />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Overview;
