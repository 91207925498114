import { useState, useEffect, FC } from 'react';
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import {
  Box,
  Card,
  Grid,
  Typography,
  Button,
  Divider,
  FormHelperText,
  CircularProgress,
  Modal,
  CardContent,
  TextField,
  CardActions
} from '@mui/material';
import * as Yup from 'yup';
import { OrderData, User } from './UserOrders';
import ConfirmModal from '../../shared/ConfirmModal';
import { getDollars } from '../../../utils/currency';

import { Form, Formik, FormikHelpers } from 'formik';
import ArrowRightIcon from '../../../icons/ArrowRight';
import { Navigate } from 'react-router';

const GET_ORDER = gql`
  query GetOrderByConfirmationCode($confirmationCode: String!) {
    GetOrderByConfirmationCode(input: { confirmationCode: $confirmationCode }) {
      id
      confirmationCode
      email
      user {
        id
        email
      }
    }
  }
`;

type Values = { confirmationCode: string };

const EXECUTE_MERGE = gql`
  mutation MergeGuestOrderToUser(
    $userEmail: String!
    $confirmationCode: String!
  ) {
    MergeGuestOrderToUser(
      input: { userEmail: $userEmail, confirmationCode: $confirmationCode }
    )
  }
`;

type Props = {
  user: User;
  onClose: () => void;
};

const UserOrderGuestMerge: FC<Props> = ({ user, onClose }) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [allowMerge, setAllowMerge] = useState(false);

  const handleOpenConfirm = () => setConfirmModalOpen(true);
  const handleCloseConfirm = () => setConfirmModalOpen(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [
    fetchOrder,
    { loading: orderLoading, data: orderData, error: orderFetchError }
  ] = useLazyQuery(GET_ORDER, {
    fetchPolicy: 'no-cache'
  });

  const [mergeOrder, { loading, data, error }] = useMutation(EXECUTE_MERGE, {
    fetchPolicy: 'no-cache'
  });

  const handleFormSubmit = (
    values: Values,
    actions: FormikHelpers<{ confirmationCode: string }>
  ) => {
    const { confirmationCode } = values;
    setAllowMerge(false);
    try {
      fetchOrder({
        variables: {
          confirmationCode
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const [
  //   executePartialRefund,
  //   {
  //     loading: partialRefundLoading,
  //     data: partialRefundData,
  //     error: partialRefundError
  //   }
  // ] = useMutation(REFUND_PARTIAL_ORDER, {
  //   errorPolicy: 'all',
  //   refetchQueries: [{ query: GET_USER, variables: { email } }]
  // });

  useEffect(() => {
    setErrorMessage('');
    if (orderFetchError?.message) {
      setErrorMessage(orderFetchError.message);
    } else if (error?.message) {
      setErrorMessage(error.message);
    }
  }, [orderFetchError, error]);

  useEffect(() => {
    if (
      orderData &&
      orderData.GetOrderByConfirmationCode &&
      orderData.GetOrderByConfirmationCode.user != null
    ) {
      setErrorMessage('This is not a guest order');
      setAllowMerge(false);
    } else if (orderData && orderData.GetOrderByConfirmationCode) {
      setErrorMessage('');
      setAllowMerge(true);
    }
    //TODO set error message if not a guest order

    // if (orderData?.message) {
    //   setErrorMessage(orderFetchError.message);
    // }
  }, [orderData]);

  useEffect(() => {
    if (data && data.MergeGuestOrderToUser === true) {
      //it worked
      window.location.reload();
    }
  }, [data]);

  const handleClickMerge = async () => {
    if (loading) {
      return;
    }
    setErrorMessage('');
    await mergeOrder({
      variables: {
        confirmationCode: orderData.GetOrderByConfirmationCode.confirmationCode,
        userEmail: user.email
      }
    });
  };

  return (
    <>
      {orderData && orderData.GetOrderByConfirmationCode && (
        <ConfirmModal
          handleConfirm={handleClickMerge}
          closeConfirmModal={handleCloseConfirm}
          confirmModalOpen={confirmModalOpen}
          handleCloseConfirm={handleCloseConfirm}
          heading={`Confirm Merge`}
          subheading={`Type 'MERGE' to confirm you want to merge order ${orderData.GetOrderByConfirmationCode.confirmationCode} with user ${user.email}.`}
          confirmText="MERGE"
        />
      )}
      <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-user-order-details"
        aria-describedby="modal-user-order-details"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '5%',
            left: '5%',
            right: '5%'
          }}
        >
          <Card sx={{ p: 4 }}>
            <Typography
              id="modal-refund"
              variant="h4"
              component="h2"
              sx={{ pb: 2 }}
            >
              Merge Guest Order to {user.email}
            </Typography>
            <Box sx={{ pb: 2 }}>
              <Formik
                initialValues={{
                  confirmationCode: ''
                }}
                validationSchema={Yup.object().shape({
                  confirmationCode: Yup.string()
                    .max(255)
                    .required('Confirmation Code is required')
                })}
                onSubmit={(values, actions) => {
                  handleFormSubmit(values, actions);
                }}
              >
                {({ errors, handleBlur, handleChange, touched, values }) => {
                  return (
                    <Form>
                      <CardContent
                        sx={{
                          alignItems: 'center'
                        }}
                      >
                        <TextField
                          error={Boolean(
                            touched &&
                              touched.confirmationCode &&
                              errors &&
                              errors.confirmationCode
                          )}
                          fullWidth
                          helperText={
                            touched &&
                            touched.confirmationCode &&
                            errors &&
                            errors.confirmationCode
                          }
                          label="Confirmation Code"
                          margin="normal"
                          name="confirmationCode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values?.confirmationCode ?? ''}
                          variant="outlined"
                        />
                        {errorMessage && (
                          <Box sx={{ mt: 3 }}>
                            <FormHelperText error>
                              {errorMessage}
                            </FormHelperText>
                          </Box>
                        )}
                        {errors.confirmationCode && (
                          <Box sx={{ mt: 3 }}>
                            <FormHelperText error>
                              {errors.confirmationCode}
                            </FormHelperText>
                          </Box>
                        )}
                        {orderData &&
                          orderData.GetOrderByConfirmationCode === null && (
                            <Box sx={{ mt: 3 }}>
                              <FormHelperText error>
                                Order Not Found
                              </FormHelperText>
                            </Box>
                          )}
                      </CardContent>
                      <Divider />
                      <CardActions>
                        <Button
                          color="primary"
                          endIcon={<ArrowRightIcon fontSize="small" />}
                          variant="text"
                          type="submit"
                          disabled={orderLoading}
                        >
                          Find Order
                        </Button>
                      </CardActions>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
            {allowMerge &&
              orderData &&
              orderData.GetOrderByConfirmationCode && (
                <>
                  <Divider />
                  <Grid container sx={{ py: 1, fontWeight: 700 }}>
                    <Grid item xs={6}>
                      <Typography color="textSecondary" variant="overline">
                        Order Confirmation Code
                      </Typography>
                      <Typography>
                        <Typography>
                          {
                            orderData.GetOrderByConfirmationCode
                              .confirmationCode
                          }
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="textSecondary" variant="overline">
                        Order Email
                      </Typography>
                      <Typography>
                        <Typography>
                          {orderData.GetOrderByConfirmationCode.email}
                        </Typography>
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        color="primary"
                        endIcon={<ArrowRightIcon fontSize="small" />}
                        variant="text"
                        disabled={orderLoading}
                        onClick={handleOpenConfirm}
                      >
                        Merge This Order
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              )}
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default UserOrderGuestMerge;
