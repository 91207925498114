import { FC, useState } from 'react';
import { Modal, Box, Card, Typography, Divider, MenuItem } from '@mui/material';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation, gql } from '@apollo/client';
import { TextField, Button, CircularProgress } from '@mui/material';
import { WAREHOUSE_MAP } from 'src/utils/fulfillmentUtils';
import { OrderData } from './UserOrders';

type Props = {
  closeConfirmModal: Function;
  handleCloseConfirm: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void;
  confirmModalOpen: boolean;
  orders: OrderData[];
};

const UPDATE_WAREHOUSE = gql`
  mutation UpdateOrderWarehouse($input: OrderUpdateWarehouseInput!) {
    UpdateOrderWarehouse(input: $input)
  }
`;

const UpdateWarehouseModal: FC<Props> = ({
  orders,
  closeConfirmModal,
  confirmModalOpen,
  handleCloseConfirm
}) => {
  const [submitting, setSubmitting] = useState(false);

  const [updateWarehouse, { loading, data, error }] = useMutation(
    UPDATE_WAREHOUSE,
    {
      errorPolicy: 'all'
    }
  );

  const handleUpdateWarehouse = async (warehouse: string) => {
    console.log('SUBMITTING');
    for (let i = 0; i < orders.length; i++) {
      if (error) {
        console.log('error', error);
        alert(error.message);
        break;
      }
      await updateWarehouse({
        variables: {
          input: {
            id: orders[i].id,
            warehouse
          }
        }
      });
      //let's put a little gap between each one
      await new Promise((r) => setTimeout(r, 200));
    }
    closeConfirmModal();
    setSubmitting(false);
  };

  return (
    <Modal
      // hideBackdrop
      open={confirmModalOpen}
      onClose={handleCloseConfirm}
      aria-labelledby="modal-confirm"
      aria-describedby="modal-confirm"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: 400,
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Card sx={{ p: 4 }}>
          <Typography
            id="modal-confirm-heading"
            variant="h4"
            component="h2"
            sx={{ pb: 1 }}
          >
            Update Warehouse
          </Typography>
          <Typography variant="h6" component="h4">
            Select a warehouse for these orders.
          </Typography>
          <Divider />
          <Box sx={{ m: 2 }} />

          <Formik
            initialValues={{
              warehouse: 'VIR'
            }}
            onSubmit={(values, actions) => {
              setSubmitting(true);
              handleUpdateWarehouse(values.warehouse);
            }}
          >
            {({ errors, handleBlur, handleChange, touched, values }) => (
              <Form>
                {!submitting && (
                  <>
                    <TextField
                      select
                      id="warehouse"
                      name="warehouse"
                      fullWidth
                      label="Warehouse"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.warehouse}
                      sx={{ mt: 1 }}
                    >
                      <MenuItem value={'VIR'}>
                        {WAREHOUSE_MAP.get('VIR')} Warehouse
                      </MenuItem>
                      <MenuItem value={'BOC'}>
                        {WAREHOUSE_MAP.get('BOC')} Warehouse
                      </MenuItem>
                      <MenuItem value={'CHR'}>
                        {WAREHOUSE_MAP.get('CHR')} Warehouse
                      </MenuItem>
                    </TextField>
                    <Button type="submit">Submit</Button>
                    <Button onClick={() => closeConfirmModal()}>Cancel</Button>
                  </>
                )}
                {submitting && (
                  <>
                    <CircularProgress />
                  </>
                )}
              </Form>
            )}
          </Formik>
        </Card>
      </Box>
    </Modal>
  );
};

export default UpdateWarehouseModal;
