import type { FC } from 'react';

const Logo: FC = (props) => (
  <img
    src={`${process.env.PUBLIC_URL}/static/logo.png`}
    height="50"
    width="178"
    alt="logo"
  />
);

export default Logo;
