const getLs = (item: string) => {
  if (typeof window !== `undefined`) {
    const itemJson = localStorage.getItem(item);
    return itemJson === null ? null : JSON.parse(itemJson);
  }
};

const getLsProperty = (item: string, propertyName: string): string => {
  return getLs(item) ? getLs(item)[propertyName] : '';
};

const setLs = (itemName: string, item: object) => {
  if (typeof window !== `undefined`) {
    localStorage.setItem(itemName, JSON.stringify(item));
  }
};

const removeLs = (itemName: string) => {
  if (typeof window !== `undefined`) {
    localStorage.removeItem(itemName);
  }
};

export { getLs, getLsProperty, setLs, removeLs };
