import { FC, useEffect, useState } from 'react';
import { Formik, FormikErrors, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  CardActions,
  Divider,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  experimentalStyled,
  MenuItem,
  Container
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowRightIcon from '../../../icons/ArrowRight';
import DateFnsUtils from '@mui/lab/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { gql, useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { getVariableValues } from 'graphql/execution/values';
import { WAREHOUSE_MAP } from 'src/utils/fulfillmentUtils';
import useSettings from 'src/hooks/useSettings';

type GoodsInType = {
  id: string;
  created: any;
  updated: any;
  expectedStockQuantity: number;
  maxNotifyQuantity: number;
  expectedStockingDate: string;
  confirmAddressAgeInDays: number;
  warehouseCode: string;
  isArchived: boolean;
  fulfillmentTriggered: boolean;
  bladeStockSku: string;
};

const GET_GOODS_IN = gql`
  query GetGoodsIn {
    GetGoodsIn {
      id
      warehouseCode
      expectedStockQuantity
      maxNotifyQuantity
      expectedStockingDate
      confirmAddressAgeInDays
      created {
        formatted
      }
      bladeStockSku
      isArchived
      fulfillmentTriggered
    }
  }
`;

const ADD_GOODS_IN = gql`
  mutation AddGoodsIn($input: InventoryEventInput!) {
    AddGoodsIn(input: $input)
  }
`;

const DELETE_GOODS_IN = gql`
  mutation DeleteGoodsIn($id: String!) {
    DeleteGoodsIn(id: $id)
  }
`;

const TRIGGER_INVENTORY_EVENT = gql`
  mutation TriggerInventoryEvent($input: TriggerInventoryEventInput!) {
    TriggerInventoryEvent(input: $input) {
      message
      backordersProcessed
    }
  }
`;

const LoaderWrapper = experimentalStyled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
});

const TableWrapper = experimentalStyled('div')({
  marginTop: 24,
  marginLeft: 24,
  width: '100%'
});

const StyledTable = experimentalStyled(Table)({
  minWidth: 650
});

const GoodsIn: FC = (props) => {
  const { settings } = useSettings();
  const [goodsInItems, setGoodsInItems] = useState<GoodsInType[] | []>([]);

  const {
    loading: goodsInLoading,
    data: goodsInData,
    error: goodsInError
  } = useQuery(GET_GOODS_IN);

  const [
    executeAddGoodsIn,
    { loading: loadingAddGoodsIn, data: dataAddGoodsIn, error: errorAddGoodsIn }
  ] = useMutation(ADD_GOODS_IN, { errorPolicy: 'all' });

  const [
    executeDeleteGoodsIn,
    {
      loading: loadingDeleteGoodsIn,
      data: dataDeleteGoodsIn,
      error: errorDeleteGoodsIn
    }
  ] = useMutation(DELETE_GOODS_IN, { errorPolicy: 'all' });

  const [
    executeTriggerInventoryEvent,
    {
      loading: loadingTriggerInventoryEvent,
      data: dataTriggerInventoryEvent,
      error: errorTriggerInventoryEvent
    }
  ] = useMutation(TRIGGER_INVENTORY_EVENT, { errorPolicy: 'all' });

  type Values = {
    warehouseCode: string;
    expectedStockQuantity: number;
    maxNotifyQuantity: number;
    itemSku: string;
    confirmAddressAgeInDays: number;
    expectedStockingDate: string | Date;
  };
  const handleFormSubmit = async (
    values: Values,
    actions: FormikHelpers<{
      warehouseCode: string;
      expectedStockQuantity: number;
      maxNotifyQuantity: number;
      confirmAddressAgeInDays: number;
      itemSku: string;
      expectedStockingDate: string | Date;
    }>
  ) => {
    try {
      await executeAddGoodsIn({
        variables: {
          input: {
            expectedStockQuantity: values.expectedStockQuantity,
            maxNotifyQuantity: values.maxNotifyQuantity,
            expectedStockingDate: values.expectedStockingDate,
            confirmAddressAgeInDays: values.confirmAddressAgeInDays,
            bladeStockSku: values.itemSku,
            warehouseCode: values.warehouseCode
          }
        },
        refetchQueries: [GET_GOODS_IN]
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (goodsInData) {
      setGoodsInItems(goodsInData.GetGoodsIn);
    }
  }, [goodsInData]);

  const handleBackorderTrigger = async ({ id }: GoodsInType) => {
    return executeTriggerInventoryEvent({
      variables: {
        input: {
          goodsInId: id
        }
      },
      refetchQueries: [GET_GOODS_IN]
    });
  };

  const handleDelete = async (goodsIn: GoodsInType) => {
    return executeDeleteGoodsIn({
      variables: {
        id: goodsIn.id
      },
      refetchQueries: [GET_GOODS_IN]
    });
  };

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <Formik
        initialValues={{
          warehouseCode: '',
          expectedStockQuantity: 0,
          maxNotifyQuantity: 1,
          confirmAddressAgeInDays: 60,
          itemSku: 'SEN_HEADSET_V1',
          expectedStockingDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30)
        }}
        validationSchema={Yup.object().shape({
          warehouseCode: Yup.string()
            .length(3)
            .required('Warehouse code is required'),
          confirmAddressAgeInDays: Yup.number()
            .positive()
            .min(30)
            .required('Confirm Address Age is Days is required'),
          expectedStockQuantity: Yup.number()
            .positive()
            .min(0)
            .required('Expected stock quantity is required'),
          maxNotifyQuantity: Yup.number()
            .positive()
            .min(1)
            .required('Expected stock quantity is required'),
          itemSku: Yup.string().required('Item sku is required'),
          expectedStockingDate: Yup.date()
            .defined()
            .required('Expected stocking date is required')
        })}
        onSubmit={(values, actions) => {
          handleFormSubmit(values, actions);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <Container maxWidth={settings.compact ? 'xl' : false}>
            <Form>
              {loadingAddGoodsIn ? (
                <CircularProgress />
              ) : (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={2}>
                      <TextField
                        error={Boolean(
                          touched.warehouseCode && errors.warehouseCode
                        )}
                        fullWidth
                        select
                        helperText={errors.warehouseCode}
                        label="Warehouse"
                        margin="normal"
                        name="warehouseCode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.warehouseCode}
                        variant="outlined"
                      >
                        <MenuItem value={'VIR'}>
                          {WAREHOUSE_MAP.get('VIR')} Warehouse
                        </MenuItem>
                        <MenuItem value={'BOC'}>
                          {WAREHOUSE_MAP.get('BOC')} Warehouse
                        </MenuItem>
                        <MenuItem value={'CHR'}>
                          {WAREHOUSE_MAP.get('CHR')} Warehouse
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        error={Boolean(
                          touched.expectedStockQuantity &&
                            errors.expectedStockQuantity
                        )}
                        fullWidth
                        helperText={errors.expectedStockQuantity}
                        label="Stock Quantity"
                        margin="normal"
                        name="expectedStockQuantity"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={values.expectedStockQuantity}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        error={Boolean(
                          touched.maxNotifyQuantity && errors.maxNotifyQuantity
                        )}
                        fullWidth
                        helperText={errors.maxNotifyQuantity}
                        label="Max Notify Quantity"
                        margin="normal"
                        name="maxNotifyQuantity"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        InputProps={{ inputProps: { min: 1 } }}
                        value={values.maxNotifyQuantity}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        error={Boolean(
                          touched.confirmAddressAgeInDays &&
                            errors.confirmAddressAgeInDays
                        )}
                        fullWidth
                        helperText={errors.confirmAddressAgeInDays}
                        label="Confirm Address Age (days)"
                        margin="normal"
                        name="confirmAddressAgeInDays"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        InputProps={{ inputProps: { min: 30 } }}
                        value={values.confirmAddressAgeInDays}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        error={Boolean(touched.itemSku && errors.itemSku)}
                        fullWidth
                        select
                        helperText={errors.itemSku}
                        label="Item SKU"
                        margin="normal"
                        name="itemSku"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.itemSku}
                        variant="outlined"
                      >
                        <MenuItem value={'SEN_HEADSET_V1'}>
                          SEN_HEADSET_V1
                        </MenuItem>
                        <MenuItem value={'SEN_HEADSET_V1_5X'}>
                          SEN_HEADSET_V1_5X
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={2}>
                      <DatePicker
                        label="Stocking Date"
                        value={values.expectedStockingDate}
                        onChange={(newValue) => {
                          setFieldValue('expectedStockingDate', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            value={values.expectedStockingDate}
                            {...params}
                            error={Boolean(
                              touched.expectedStockingDate &&
                                errors.expectedStockingDate
                            )}
                            helperText={errors.expectedStockingDate}
                            style={{ marginTop: '16px', marginBottom: '8px' }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: '12px' }} />
                  <CardActions>
                    <Button
                      color="primary"
                      endIcon={<ArrowRightIcon fontSize="small" />}
                      variant="text"
                      type="submit"
                      disabled={
                        !touched.warehouseCode &&
                        !touched.expectedStockQuantity &&
                        !touched.maxNotifyQuantity &&
                        !touched.confirmAddressAgeInDays &&
                        !touched.expectedStockingDate
                      }
                    >
                      Submit new shipment arrival date
                    </Button>
                  </CardActions>
                </>
              )}
            </Form>
          </Container>
        )}
      </Formik>

      <TableWrapper>
        <Typography color="textSecondary" variant="overline">
          List of Scheduled Goods In
        </Typography>
        <TableContainer component={Paper}>
          {goodsInLoading ||
          loadingDeleteGoodsIn ||
          loadingTriggerInventoryEvent ? (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          ) : (
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell>Warehouse</TableCell>
                  <TableCell align="right">Expected Stock Quantity</TableCell>
                  <TableCell align="right">Max Notify Quantity</TableCell>

                  <TableCell align="right">
                    Confirm Address Age (days)
                  </TableCell>
                  <TableCell align="right">Item SKU</TableCell>
                  <TableCell align="right">Expected Stocking Date</TableCell>

                  <TableCell align="right">Date Created</TableCell>
                  <TableCell align="right">Backorder Fulfillment</TableCell>
                  <TableCell align="right">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goodsInItems &&
                  goodsInItems.map((goodsIn: GoodsInType, index: number) => (
                    <TableRow key={`goodsinitem${index}`}>
                      <TableCell component="th" scope="row">
                        {WAREHOUSE_MAP.get(goodsIn.warehouseCode)}
                      </TableCell>
                      <TableCell align="right">
                        {goodsIn.expectedStockQuantity}
                      </TableCell>
                      <TableCell align="right">
                        {goodsIn.maxNotifyQuantity}
                      </TableCell>
                      <TableCell align="right">
                        {goodsIn.confirmAddressAgeInDays}
                      </TableCell>
                      <TableCell align="right">
                        {goodsIn.bladeStockSku}
                      </TableCell>
                      <TableCell align="right">
                        {format(
                          new Date(goodsIn.expectedStockingDate),
                          'MM/dd/yyyy'
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {format(
                          new Date(goodsIn.created.formatted),
                          'MM/dd/yyyy'
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {goodsIn.fulfillmentTriggered ? (
                          <div>Fulfilled</div>
                        ) : (
                          <Button
                            onClick={() => handleBackorderTrigger(goodsIn)}
                          >
                            Trigger
                          </Button>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => handleDelete(goodsIn)}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </StyledTable>
          )}
        </TableContainer>
      </TableWrapper>
    </LocalizationProvider>
  );
};

export default GoodsIn;
