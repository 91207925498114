import type { FC } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Box, Card, CardContent, Typography } from '@mui/material';

const USERS_COUNT = gql`
  query UsersCount {
    UsersCount
  }
`;

const OverviewUsers: FC = (props) => {
  const { loading, data, error } = useQuery(USERS_COUNT);

  return (
    <Card {...props}>
      <CardContent
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1
          }}
        >
          <div>
            <Typography color="primary" variant="h4">
              {loading && `loading...`}
              {error && `something went wrong...`}
              {data && data.UsersCount}
            </Typography>
            <Typography color="textPrimary" sx={{ mt: 1 }} variant="subtitle2">
              Registered Users
            </Typography>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OverviewUsers;
