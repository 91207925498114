import React from 'react';
import { ChangeEvent, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Container,
  Grid,
  Box,
  Divider
} from '@mui/material';
import ArrowRightIcon from '../../../icons/ArrowRight';
import Refresh from '../../../icons/Refresh';
import HealthCheckItemDescription from './HealthCheckItemDescription';
import { useApiMutation } from 'src/hooks/useApiMutation';
import { format, formatDistance } from 'date-fns';

const HealthCheckItem: React.FC<{
  configItems: Array<{ key: string; value: string }>;
  description?: string;
  inputIsDateNowAction?: boolean;
  isLoading: boolean;
}> = ({ configItems, inputIsDateNowAction, description, isLoading }) => {
  const [inputValues, setInputValues] =
    useState<Array<{ key: string; value: string }>>(configItems);

  React.useEffect(() => {
    setInputValues(configItems);
  }, [configItems]);

  const [
    executeUpdate,
    { loading: loadingUpdate, data: dataUpdate, error: errorUpdate }
  ] = useApiMutation('healthcheck', 'HealthCheckUpdate', 'HealthCheck');

  const handleFormSubmit = async (values, actions?) => {
    try {
      const variables = {
        items: values
      };
      executeUpdate({ variables });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card sx={{ width: '100%', mb: 4 }}>
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          flexWrap: 'wrap',
          pl: 0,
          pr: 0
        }}
      >
        {description ? (
          <>
            <Box sx={{ textAlign: 'left', padding: '16px' }}>
              <Typography
                padding="10px 0 0 0"
                color="textPrimary"
                variant="body2"
              >
                {description}
              </Typography>
            </Box>
            <Divider />
          </>
        ) : (
          <></>
        )}
        <Formik
          initialValues={[]}
          onSubmit={(values, actions) => {
            values = inputValues?.map((item, index) => {
              return {
                key: item.key,
                value: item.value ? item.value.toString() : ''
              };
            });
            handleFormSubmit(values, actions);
          }}
        >
          <Form style={{ flexGrow: 1 }}>
            <Grid container sx={{ flexGrow: 1 }}>
              {isLoading ? (
                <Grid item md={6} xs={12} sx={{ flexGrow: 1 }}>
                  <Container>
                    <Typography
                      padding="10px 0 0 0"
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      Loading...
                    </Typography>
                  </Container>
                </Grid>
              ) : (
                inputValues?.map((item, index) => {
                  return [
                    <Grid
                      item
                      key={`item-${index}`}
                      md={6}
                      xs={12}
                      sx={{ flexGrow: 1 }}
                    >
                      <Container>
                        <Typography
                          padding="10px 0 0 0"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          <HealthCheckItemDescription itemKey={item.key} />
                        </Typography>
                        {!inputIsDateNowAction ? (
                          <TextField
                            margin="normal"
                            name="valueItem"
                            value={item.value}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              const newInputs = [...inputValues];
                              newInputs[index] = {
                                key: item.key,
                                value: e.target.value
                              };
                              setInputValues(newInputs);
                            }}
                            variant="outlined"
                            fullWidth
                            disabled={loadingUpdate}
                          />
                        ) : (
                          <Container>
                            <Typography
                              padding="10px 0 0 0"
                              color="textPrimary"
                              variant="body2"
                            >
                              last invalidation:{' '}
                              <Typography
                                padding="10px 0 0 0"
                                color="textSecondary"
                                variant="caption"
                              >
                                {item.value
                                  ? `${formatDistance(
                                      new Date(item.value),
                                      new Date()
                                    )} ago `
                                  : 'not set'}
                              </Typography>
                            </Typography>
                            <br />
                            <Container sx={{ pt: 2, pb: 0, pl: 0, pr: 0 }}>
                              <Button
                                color="primary"
                                endIcon={
                                  loadingUpdate ? (
                                    <Refresh fontSize="small" />
                                  ) : (
                                    <ArrowRightIcon fontSize="small" />
                                  )
                                }
                                onClick={() => {
                                  const newInputs = [...inputValues];
                                  newInputs[index] = {
                                    key: item.key,
                                    value: new Date().toISOString()
                                  };
                                  setInputValues(newInputs);
                                  handleFormSubmit([newInputs[index]]);
                                }}
                                variant="outlined"
                                type="button"
                                disabled={loadingUpdate || isLoading}
                              >
                                {loadingUpdate ? 'Invalidating' : 'Invalidate'}
                              </Button>
                            </Container>
                          </Container>
                        )}
                      </Container>
                    </Grid>
                  ];
                })
              )}
            </Grid>
            {!inputIsDateNowAction ? (
              <Container sx={{ pt: 2, pb: 0, pl: 0, pr: 0 }}>
                <Button
                  color="primary"
                  endIcon={
                    loadingUpdate ? (
                      <Refresh fontSize="small" />
                    ) : (
                      <ArrowRightIcon fontSize="small" />
                    )
                  }
                  variant="outlined"
                  type="submit"
                  disabled={loadingUpdate || isLoading}
                >
                  {loadingUpdate ? 'Updating' : 'Update Values'}
                </Button>
              </Container>
            ) : (
              <></>
            )}
          </Form>
        </Formik>
      </CardContent>
    </Card>
  );
};

export default HealthCheckItem;
