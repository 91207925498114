import { GridColDef } from '@mui/x-data-grid';
import { Countries } from 'src/utils/Countries';

export const audienceColumnConifg: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'First Name',
    minWidth: 200,
    valueGetter: (value, row, column, apiRef) => {
      if (row.hashedAudience) {
        return row.hashedAudience.firstName;
      } else {
        return '';
      }
    }
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    minWidth: 200,
    valueGetter: (value, row, column, apiRef) => {
      if (row.hashedAudience) {
        return row.hashedAudience.lastName;
      } else {
        return '';
      }
    }
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 200,
    valueGetter: (value, row, column, apiRef) => {
      if (row.hashedAudience) {
        return row.hashedAudience.email;
      } else {
        return '';
      }
    }
  },
  {
    field: 'phone',
    headerName: 'Phone',
    minWidth: 200,

    valueGetter: (value, row, column, apiRef): string => {
      if (row.hashedAudience) {
        return row.hashedAudience.phone;
      } else {
        return '';
      }
    }
  },
  {
    field: 'country',
    headerName: 'Country',
    minWidth: 200,
    valueGetter: (value, row, column, apiRef) => {
      if (row.hashedAudience) {
        //look up 2 letter country code
        return row.hashedAudience.country;
      } else {
        return '';
      }
    }
  },
  {
    field: 'zip',
    headerName: 'Zip',
    minWidth: 200,
    valueGetter: (value, row, column, apiRef) => {
      if (row.shippingAddress) {
        return row.shippingAddress.postal.trim();
      } else {
        return '';
      }
    }
  }
];
