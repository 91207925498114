import { useLazyQuery, gql } from '@apollo/client';
import { Box, Button, FormHelperText } from '@mui/material';
import Refresh from '../../../icons/Refresh';
import ArrowRightIcon from '../../../icons/ArrowRight';
import { useEffect } from 'react';

const RESET_TEST_USERS = gql`
  query ResetAppstoreTestUsers {
    ResetAppstoreTestUsers
  }
`;

const ResetTestUsers = () => {
  const [executeResetTestUsers, { loading, error, data }] =
    useLazyQuery(RESET_TEST_USERS);

  useEffect(() => {
    console.log('data', data);
  }, [data]);

  return (
    <>
      <Button
        color="primary"
        endIcon={
          loading ? (
            <Refresh fontSize="small" />
          ) : (
            <ArrowRightIcon fontSize="small" />
          )
        }
        variant="outlined"
        onClick={() => executeResetTestUsers()}
        disabled={loading}
      >
        {loading ? 'Resetting' : 'Reset Test Users'}
      </Button>
      {error && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{error.message}</FormHelperText>
        </Box>
      )}
      {data?.ResetAppstoreTestUsers.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText sx={{ color: '#fff' }}>
            Users {data?.ResetAppstoreTestUsers.join(', ')} successfully reset.
          </FormHelperText>
        </Box>
      )}
    </>
  );
};

export default ResetTestUsers;
