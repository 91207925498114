import { MutationHookOptions, useMutation } from '@apollo/client';
import API from 'src/server/serverInterface';

export const useApiMutation = (
  clientName: string,
  apiName: string,
  refetchQueries?: string
) => {
  const client = API.getClientDef(clientName);
  const queryDef = client.getDef(apiName);
  const options: MutationHookOptions = { client: client.client };

  if (refetchQueries) {
    options.refetchQueries = [client.getDef(refetchQueries).gql];
  }

  const [executeMutation, { loading, data, error }] = useMutation(
    queryDef.gql,
    options
  );

  return [executeMutation, { loading, data, error }] as const;
};
