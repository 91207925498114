import { useEffect, useState } from 'react';
import React from 'react';
import { Grid, Button, TextField, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/lab';

import useSettings from '../../../hooks/useSettings';
import {
  FulfilmentStatus,
  mapFulfillmentStatus,
  WAREHOUSE_MAP
} from 'src/utils/fulfillmentUtils';
import { Countries } from 'src/utils/Countries';

type DateRangeType =
  | 'ANY'
  | 'LAST_30_DAYS'
  | 'THIS_MONTH'
  | 'LAST_MONTH'
  | 'THIS_YEAR'
  | 'LAST_YEAR'
  | 'CUSTOM';

interface QueryFromUrl {
  dateRange: DateRangeType;
  dateRangeStart: string;
  dateRangeEnd: string;
  email: string;
  orderBy: string;
  staus: string;
}

const dateRanges = [
  'ANY',
  'LAST_30_DAYS',
  'THIS_MONTH',
  'LAST_MONTH',
  'THIS_YEAR',
  'LAST_YEAR',
  'CUSTOM'
];

const dateRangeToString = (dateRange) => {
  switch (dateRange) {
    case 'ANY':
      return 'Any';
    case 'LAST_30_DAYS':
      return 'Last 30 Days';
    case 'THIS_MONTH':
      return 'This Month';
    case 'LAST_MONTH':
      return 'Last Month';
    case 'THIS_YEAR':
      return 'This Year';
    case 'LAST_YEAR':
      return 'Last Year';
    case 'CUSTOM':
      return 'Custom';
    default:
      return '';
  }
};

export const getCountryItems = () => {
  const countryNames = [];
  for (const [key, value] of Countries) {
    countryNames.push(key);
  }
  countryNames.sort((a, b) => a.localeCompare(b));
  return countryNames.map((countryName) => (
    <MenuItem key={countryName} value={countryName}>
      {countryName}
    </MenuItem>
  ));
};

const OrdersListForm = ({
  queryOrders,
  inputValues,
  setInputValues,
  searchParams,
  isFormValid,
  setIsFormValid,
  resetForm
}) => {
  const [showCustomDateRange, setShowCustomDateRange] =
    useState<boolean>(false);

  const runOrderQuery = () => {
    const query = {} as QueryFromUrl;
    let validQuery = false;

    for (const [key, value] of searchParams.entries()) {
      // each 'entry' is a [key, value] tupple
      if (validQueryVariables.includes(key)) {
        query[key] = value;
        validQuery = true;
      }
    }

    if (validQuery) {
      if (query['dateRange'] === 'CUSTOM') {
        setShowCustomDateRange(true);
      }

      if (query['dateRange'] !== 'CUSTOM') {
        const dateRange = getDateRange(query['dateRange']);
        query.dateRangeStart = dateRange.dateRangeStart;
        query.dateRangeEnd = dateRange.dateRangeEnd;
      }

      setInputValues(query);
      if (
        !query['confirmationCode'] &&
        !query['email'] &&
        !(query['dateRangeStart'] || query['dateRangeEnd'])
      ) {
        setIsFormValid(false);
      } else {
        queryOrders(0, query);
      }
    }
  };

  useEffect(() => {
    runOrderQuery();
  }, []);

  const changeTimezone = (date: Date, tzCode?: string) => {
    if (!tzCode) {
      return date;
    }

    const invdate = new Date(
      date.toLocaleString('en-US', {
        timeZone: tzCode
      })
    );

    var diff = date.getTime() - invdate.getTime();

    return new Date(date.getTime() - diff);
  };

  const roundDateUp = (date: Date) => {
    let tomorrow: Date = new Date(date);
    tomorrow.setDate(date.getDate() + 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setTime(tomorrow.getTime() - 1); // one millisecond before midnight

    return tomorrow;
  };

  const roundDateDown = (date: Date) => {
    if (
      date.getHours() === 0 &&
      date.getMinutes() === 0 &&
      date.getSeconds() === 0
    ) {
      return date;
    }

    let today: Date = new Date(date);
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    return today;
  };

  const { settings } = useSettings();

  const validQueryVariables = [
    ...Object.keys(inputValues),
    'warehouse',
    'fulfilmentStatus',
    'status',
    'paymentProvider'
  ];

  const getDateRange = (
    type: DateRangeType
  ): { dateRangeStart: string; dateRangeEnd: string } => {
    const today = new Date();
    switch (type) {
      case 'ANY':
        return {
          dateRangeStart: undefined,
          dateRangeEnd: undefined
        };
      case 'LAST_30_DAYS':
        return {
          dateRangeStart: roundDateDown(
            changeTimezone(
              new Date(new Date().setDate(new Date().getDate() - 30)),
              settings.timeZone
            )
          ).toUTCString(),
          dateRangeEnd: roundDateUp(
            changeTimezone(today, settings.timeZone)
          ).toUTCString()
        };
      case 'THIS_MONTH':
        return {
          dateRangeStart: roundDateDown(
            changeTimezone(
              new Date(today.getFullYear(), today.getMonth(), 1),
              settings.timeZone
            )
          ).toUTCString(),
          dateRangeEnd: roundDateUp(
            changeTimezone(today, settings.timeZone)
          ).toUTCString()
        };

      case 'LAST_MONTH':
        return {
          dateRangeStart: roundDateDown(
            changeTimezone(
              new Date(new Date(new Date().setDate(0)).setDate(1)),
              settings.timeZone
            )
          ).toUTCString(),
          dateRangeEnd: roundDateUp(
            changeTimezone(new Date(new Date().setDate(0)), settings.timeZone)
          ).toUTCString()
        };
      case 'THIS_YEAR':
        return {
          dateRangeStart: roundDateDown(
            changeTimezone(
              new Date(today.getFullYear(), 0, 1),
              settings.timeZone
            )
          ).toUTCString(),
          dateRangeEnd: roundDateUp(
            changeTimezone(today, settings.timeZone)
          ).toUTCString()
        };
      case 'LAST_YEAR':
        return {
          dateRangeStart: roundDateDown(
            changeTimezone(
              new Date(today.getFullYear() - 1, 0, 1),
              settings.timeZone
            )
          ).toUTCString(),
          dateRangeEnd: roundDateUp(
            changeTimezone(
              new Date(today.getFullYear() - 1, 11, 31),
              settings.timeZone
            )
          ).toUTCString()
        };
    }
  };

  const setDateRangeValues = (type: DateRangeType): void => {
    if (type === 'CUSTOM') {
      setShowCustomDateRange(true);
    } else {
      setInputValues(getDateRange(type));
      setShowCustomDateRange(false);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'dateRange') {
      const rangeType = value as DateRangeType;
      setDateRangeValues(rangeType);
    }
    setInputValues({ [name]: name === 'email' ? value.toLowerCase() : value });
  };

  const searchOrders = () => {
    if (
      !inputValues.confirmationCode &&
      !inputValues.email &&
      !(inputValues.dateRangeStart || inputValues.dateRangeEnd)
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
      queryOrders(0);
    }
  };

  const getCustomStartDate = (dateFromDatePicker: Date): string => {
    const utcDate = roundDateDown(dateFromDatePicker);
    const localDate = new Date(
      utcDate.toLocaleString('en-US', {
        timeZone: settings.timeZone
      })
    );
    console.log('localDate', localDate);
    return localDate.toISOString();
  };

  const getCustomEndDate = (dateFromDatePicker: Date): string => {
    const utcDate = roundDateUp(dateFromDatePicker);
    const localDate = new Date(
      utcDate.toLocaleString('en-US', {
        timeZone: settings.timeZone
      })
    );
    console.log('localDate', localDate);
    return localDate.toISOString();
  };

  return (
    <>
      <Grid item md={2} xs={2}>
        <TextField
          required
          select
          id="dateRangeType"
          name="dateRangeType"
          fullWidth
          label="Date Range Type"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.dateRangeType}
          sx={{ mt: 1 }}
        >
          <MenuItem value={'ORDERED_DATE'}>Ordered Date</MenuItem>
          <MenuItem value={'SHIPPED_DATE'}>Shipped Date</MenuItem>
          <MenuItem value={'REFUNDED_DATE'}>Refunded Date</MenuItem>
        </TextField>
      </Grid>

      <Grid
        item
        md={showCustomDateRange ? 2 : 6}
        xs={showCustomDateRange ? 2 : 6}
      >
        <TextField
          select
          required
          id="dateRange"
          name="dateRange"
          fullWidth
          label="Date Range"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.dateRange}
          sx={{ mt: 1 }}
        >
          {dateRanges.map((s) => (
            <MenuItem key={s} value={s}>
              {dateRangeToString(s)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {showCustomDateRange ? (
        <>
          <Grid item md={2} xs={2}>
            <DatePicker
              label="Start Date"
              value={inputValues.dateRangeStart}
              onChange={(newValue) => {
                if (isNaN(newValue.getTime())) {
                  // invalid date
                  return;
                }
                setInputValues({
                  ...inputValues.dateRangeStart,
                  dateRangeStart: settings.timeZone
                    ? getCustomStartDate(newValue)
                    : newValue.toUTCString()
                });
              }}
              renderInput={(params) => (
                <TextField
                  value={inputValues.dateRangeStart}
                  {...params}
                  error={!isFormValid}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <DatePicker
              label="End Date"
              value={inputValues.dateRangeEnd}
              onChange={(newValue) => {
                if (isNaN(newValue.getTime())) {
                  // invalid date
                  return;
                }
                setInputValues({
                  ...inputValues.dateRangeEnd,
                  dateRangeEnd: settings.timeZone
                    ? getCustomEndDate(newValue)
                    : newValue.toUTCString()
                });
              }}
              renderInput={(params) => (
                <TextField
                  value={inputValues.endDate}
                  {...params}
                  error={!isFormValid}
                  fullWidth
                />
              )}
            />
          </Grid>
        </>
      ) : null}
      <Grid item md={3} xs={3}>
        <TextField
          label="Confirmation Code"
          fullWidth
          id="confirmCode"
          name="confirmationCode"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.confirmationCode}
          error={!isFormValid}
        />
      </Grid>
      <Grid item md={3} xs={3}>
        <TextField
          label="Email"
          fullWidth
          id="email"
          name="email"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.email}
          error={!isFormValid}
        />
      </Grid>

      <Grid item md={3} xs={3}>
        <TextField
          select
          id="status"
          name="status"
          fullWidth
          label="Payment Status"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.status}
          sx={{ mt: 1 }}
        >
          <MenuItem value={''}>Any</MenuItem>
          {[
            'created',
            'requires_payment_method',
            'requires_confirmation',
            'requires_action',
            'processing',
            'requires_capture',
            'canceled',
            'succeeded',
            'failed',
            'refunded',
            'partially_refunded',
            'disputed_refunded'
          ].map((s) => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} xs={3}>
        <TextField
          select
          id="paymentProvider"
          name="paymentProvider"
          fullWidth
          label="Payment Provider"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.paymentProvider}
          sx={{ mt: 1 }}
        >
          <MenuItem value={''}>All Providers</MenuItem>
          <MenuItem value={'stripe'}>Stripe</MenuItem>
          <MenuItem value={'indiegogo'}>Indiegogo</MenuItem>
          <MenuItem value={'internal'}>Internal</MenuItem>
          <MenuItem value={'affirm'}>Affirm</MenuItem>
        </TextField>
      </Grid>
      <Grid item md={3} xs={3}>
        <TextField
          label="First Name"
          fullWidth
          id="firstName"
          name="firstName"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.firstName}
        />
      </Grid>
      <Grid item md={3} xs={3}>
        <TextField
          label="Last Name"
          fullWidth
          id="lastName"
          name="lastName"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.lastName}
        />
      </Grid>
      <Grid item md={2} xs={2}>
        <TextField
          select
          id="fulfilmentStatus"
          name="fulfilmentStatus"
          fullWidth
          label="Fulfillment Status"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.fulfilmentStatus}
          sx={{ mt: 1 }}
        >
          <MenuItem value={''}>Any</MenuItem>
          {[
            'UNDEFINED',
            'QUEUED_BACKORDER',
            'awaiting_allocation',
            'awaiting_picking',
            'awaiting_despatch',
            'despatched',
            'backorder',
            'gift'
          ].map((s) => (
            <MenuItem key={s} value={s}>
              {mapFulfillmentStatus(s as any as FulfilmentStatus)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={2} xs={2}>
        <TextField
          select
          id="warehouse"
          name="warehouse"
          fullWidth
          label="Warehouse"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.warehouse}
          sx={{ mt: 1 }}
        >
          <MenuItem value={''}>All Warehouses</MenuItem>
          <MenuItem value={'VIR'}>
            {WAREHOUSE_MAP.get('VIR')} Warehouse
          </MenuItem>
          <MenuItem value={'BOC'}>
            {WAREHOUSE_MAP.get('BOC')} Warehouse
          </MenuItem>
          <MenuItem value={'CHR'}>
            {WAREHOUSE_MAP.get('CHR')} Warehouse
          </MenuItem>
        </TextField>
      </Grid>

      <Grid item md={2} xs={2}>
        <TextField
          label="Affiliate ID"
          fullWidth
          id="affiliateId"
          name="affiliateId"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.affiliateId}
          sx={{ mt: 1 }}
        />
      </Grid>

      <Grid item md={2} xs={2}>
        <TextField
          required
          select
          id="orderBy"
          name="orderBy"
          fullWidth
          label="Order"
          variant="outlined"
          // displayEmpty
          onChange={handleSearchChange}
          value={inputValues.orderBy}
          sx={{ mt: 1 }}
        >
          <MenuItem value={'newestFirst'}>Newest</MenuItem>
          <MenuItem value={'oldestFirst'}>Oldest</MenuItem>
        </TextField>
      </Grid>

      <Grid item md={2} xs={2}>
        <TextField
          select
          id="country"
          name="country"
          fullWidth
          label="Country"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.country}
          sx={{ mt: 1 }}
        >
          <MenuItem value="">Any</MenuItem>
          {getCountryItems()}
        </TextField>
      </Grid>

      <Grid item md={2} xs={2}>
        <TextField
          id="utmSource"
          name="utmSource"
          fullWidth
          label="UTM Source"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.utmSource}
          sx={{ mt: 1 }}
        />
      </Grid>

      <Grid item md={2} xs={2}>
        <TextField
          id="utmMedium"
          name="utmMedium"
          fullWidth
          label="UTM Medium"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.utmMedium}
          sx={{ mt: 1 }}
        />
      </Grid>

      <Grid item md={2} xs={2}>
        <TextField
          id="utmCampaign"
          name="utmCampaign"
          fullWidth
          label="UTM Campaign"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.utmCampaign}
          sx={{ mt: 1 }}
        />
      </Grid>

      <Grid item md={2} xs={2}>
        <TextField
          id="utmTerm"
          name="utmTerm"
          fullWidth
          label="UTM Term"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.utmTerm}
          sx={{ mt: 1 }}
        />
      </Grid>

      <Grid item md={2} xs={2}>
        <TextField
          id="utmContent"
          name="utmTutmContenterm"
          fullWidth
          label="UTM Content"
          variant="outlined"
          onChange={handleSearchChange}
          value={inputValues.utmContent}
          sx={{ mt: 1 }}
        />
      </Grid>

      <Grid item>
        <Button variant="contained" color="primary" onClick={searchOrders}>
          Find
        </Button>
        <Button
          variant="outlined"
          color="info"
          sx={{ marginLeft: '20px' }}
          onClick={resetForm}
        >
          Reset
        </Button>
      </Grid>
    </>
  );
};

export default OrdersListForm;
