import React, { useEffect } from 'react';
import { fft, util as fftUtil } from 'fft-js';
import { Chart as ChartComponent, getElementsAtEvent } from 'react-chartjs-2';
import { useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  Grid,
  Select,
  MenuItem,
  Switch,
  FormControlLabel
} from '@mui/material';

import LineChart, { SIGNAL_SHIFT } from './LineChart';

import { channelType, metricType } from './erp-annotation';

import CheckCircle from 'src/icons/CheckCircle';
import { XCircle } from 'react-feather';
import { useApiMutation } from 'src/hooks/useApiMutation';

const calcOverallSegment = (segments, isGo: boolean, chan: channelType) => {
  const filtredS = segments.filter((s) =>
    isGo ? !s.segmentId.includes('NOGO') : s.segmentId.includes('NOGO')
  );
  if (filtredS.length === 0) {
    return [];
  }
  const data: number[] = [...filtredS[0].data];

  // const f = new FFT(1024);
  // const out = f.createComplexArray();
  const realInput = [];

  filtredS[1].data.forEach((n, i) => {
    if (i < 1024) {
      realInput.push(data[i]);
    }
    data[i] += n;
    data[i] = data[i] / 2;
    // if (i < 1024) {
    //   realInput.push(data[i]);
    // }
  });

  var phasors = fft(realInput);
  var frequencies = fftUtil.fftFreq(phasors, 1000), // Sample rate and coef is just used for length, and frequency step
    magnitudes = fftUtil.fftMag(phasors);

  var spectral = frequencies.map(function (f, ix) {
    return { frequency: f, magnitude: magnitudes[ix] };
  });

  return {
    segmentId: `OVERALL-${isGo ? 'GO' : 'NOGO'}-${chan}`,
    data,
    spectral
  };
};

const getMetricUniqueId = (m) => {
  if (m.id) {
    return m.id;
  }
  return m.channel + m.type + m.segmentType;
};

const getMetricTypeSortValue = (
  metricT: metricType,
  segmentType: string
): number => {
  let val = 0;
  switch (metricT) {
    case 'N100':
      val = 100;
      break;
    case 'P200':
      val = 200;
      break;
    case 'N200':
      val = 300;
      break;
    case 'P3b':
      val = 400;
      break;
    case 'ERN':
    case 'N450':
      val = 500;
      break;
    case 'Pe':
      val = 600;
      break;
  }
  if (segmentType.includes('OVERALL')) {
    val += 10;
  } else if (segmentType.includes('UNDISTRACTED')) {
    val += 30;
  } else {
    val += 60;
  }

  return val;
};

const isMetricTypePositive = (metricT: metricType): boolean => {
  switch (metricT) {
    case 'P100':
      return true;
    case 'P200':
      return true;
    case 'N100':
    case 'N450':
    case 'N200':
      return false;
    case 'P3b':
      return true;
    case 'ERN':
      return false;
    case 'Pe':
      return true;
  }
};

const filterAndSortMetrics = (metrics, chan: channelType, isGo: boolean) => {
  const metricResults = [...metrics];
  if (chan === 'FZ' || (chan === 'PZ' && isGo)) {
    const segments = [
      `${isGo ? 'GO' : 'INHIBIT'}_DISTRACTED`,
      `${isGo ? 'GO' : 'INHIBIT'}_UNDISTRACTED`
      // `${isGo ? 'GO' : 'INHIBIT'}_OVERALL`
    ];
    //fill in missing metrics
    ['P100', 'N100', 'P200', 'N200', 'P3b', 'N450'].forEach((t) => {
      segments.forEach((s) => {
        const m = metrics.find(
          (m) => m.type === t && m.segmentType === s && m.channel === chan
        );

        if (!m) {
          //fill in missing metric
          metricResults.push({
            segmentType: s,
            channel: chan,
            type: t,
            peakTime: -1000,
            centerTime: -1000,
            notFound: true,
            lowNumTrials: false, //TODO
            numTrials: 0, //TODO
            fromResult: 'CORRECT'
          });
        }
      });
    });
  } else if (chan === 'CZ') {
    //fill in missing metrics
    ['ERN', 'Pe'].forEach((t) => {
      const m = metrics.find((m) => m.type === t && m.channel === chan);

      if (!m) {
        //fill in missing metric
        metricResults.push({
          segmentType: 'OVERALL',
          channel: chan,
          type: t,
          peakTime: -1000,
          centerTime: -1000,
          notFound: true,
          lowNumTrials: false, //TODO
          numTrials: 0, //TODO
          fromResult: 'ERROR_COMMISSION'
        });
      }
    });
  }

  return metricResults
    .filter(
      (m) =>
        m.channel === chan &&
        (chan === 'CZ' ||
          (isGo &&
            m.segmentType.startsWith('GO') &&
            !m.segmentType.includes('OVERALL')) ||
          (!isGo &&
            !m.segmentType.startsWith('GO') &&
            !m.segmentType.includes('OVERALL')))
      // &&
      // (chan === 'CZ' || m.segmentType.includes('_' + distracted))
    )
    .sort((a, b) => {
      return (
        getMetricTypeSortValue(a.type as metricType, a.segmentType) -
        getMetricTypeSortValue(b.type as metricType, b.segmentType)
      );
    });
};

export type PeakResult = {
  segmentId: string;
  peakTime: number;
  centerTime: number;
  amplitude: number;
  startTime: number;
  endTime: number;
};

export type PeakResults = PeakResult[];

const ErpItem: React.FC<{
  chan: channelType;
  data: any;
  isGo: boolean;
  getNextBatch: () => void;
}> = ({ chan, data, isGo, getNextBatch }) => {
  const [complete, setComplete] = useState<boolean>(false);
  const [inverted, setInverted] = useState<boolean>(false);
  const [confidenceLevel, setConfidenceLevel] = useState<number>(3);
  const [flagForReview, setFlagForReview] = useState<boolean>(false);

  const [peakResults, setPeakResults] = useState<PeakResults | null>(null);
  const [activePeakResult, setActivePeakResult] = useState<PeakResult | null>(
    null
  );
  const [metrics, setMetrics] = useState<any[] | null>(null);
  const [activeMetric, setActiveMetric] = useState<any | null>(null);

  const [readyMap, setReadyMap] = useState<Map<string, any>>(
    new Map<string, any>()
  );

  useEffect(() => {
    if (!peakResults || !activeMetric) {
      setActivePeakResult(null);
      return;
    }

    //map segement type to segmentId
    let segmentType = 'FLANKER-ERN_PE-CZ';
    switch (activeMetric.segmentType) {
      case 'GO_OVERALL':
        segmentType = `OVERALL-GO-${chan}`;
        break;
      case 'GO_UNDISTRACTED':
        segmentType = `FLANKER-CONGRUENT-${chan}`;
        break;
      case 'GO_DISTRACTED':
        segmentType = `FLANKER-INCONGRUENT-${chan}`;
        break;
      case 'INHIBIT_OVERALL':
        segmentType = `OVERALL-NOGO-${chan}`;
        break;
      case 'INHIBIT_UNDISTRACTED':
        segmentType = `FLANKER-NOGO-CONGRUENT-${chan}`;
        break;
      case 'INHIBIT_DISTRACTED':
        segmentType = `FLANKER-NOGO-INCONGRUENT-${chan}`;
        break;
    }
    // console.log(peakResults, activeMetric);
    setActivePeakResult(
      peakResults ? peakResults.find((r) => r.segmentId === segmentType) : null
    );
  }, [peakResults, activeMetric]);

  const assessmentRes = data.assessment;

  useEffect(() => {
    const m = filterAndSortMetrics(
      assessmentRes.phaseResults[0].metrics,
      chan,
      isGo
    );
    setMetrics(m);

    setPeakResults(null);
    // console.log(m);
    setActiveMetric(m ? m[0] : null);
    // console.log(assessmentRes.phaseResults[0]);
  }, [chan, isGo]);

  const rt = assessmentRes.phaseResults[0].reactionTimes.find(
    (r) => r.type === 'CORRECT'
  );

  const accuracy = assessmentRes.phaseResults[0].accuracies.find(
    (r) => r.type === 'OVERALL'
  );

  // const p3b = metrics.find(
  //   (m) => m.type === 'P3b' && m.segmentType === 'GO_OVERALL'
  // );

  const segments = assessmentRes.phaseResults[0].segments
    .filter((s) => s.segmentId.endsWith(chan))
    .sort((a, b) => {
      if (a.segmentId < b.segmentId) {
        return -1;
      }
      if (a.segmentId > b.segmentId) {
        return 1;
      }
    });

  let overallSeg;
  if (chan !== 'CZ' && segments.length > 0) {
    overallSeg = calcOverallSegment(segments, isGo, chan);
    segments.push(overallSeg);
  }

  const selectMetric = (m) => {
    if (m.type !== activeMetric?.type) {
      //changed types clear
      console.log('Clear peak');
      setPeakResults(null);
    }

    readyMap.delete(getMetricUniqueId(m));
    setActiveMetric(m);
  };

  const [executeAnnotateErp, { loading, data: annotateResult, error }] =
    useApiMutation('core', 'AnnotateErp');

  const submitPoorDataFlag = () => {
    const variables = {
      assessmentId: assessmentRes.id,
      annoatedResults: [],
      confidenceLevel: confidenceLevel,
      flagForReview: false,
      inverted: false,
      poorData: true
    };
    executeAnnotateErp({
      variables
    });
  };

  const submitResults = () => {
    console.log('Sumbit Results');

    const variables = {
      assessmentId: assessmentRes.id,
      annoatedResults: Array.from(readyMap.values()),
      confidenceLevel: confidenceLevel,
      flagForReview: flagForReview,
      inverted: inverted,
      poorData: false
    };

    executeAnnotateErp({
      variables
    });
  };

  useEffect(() => {
    if (error) {
      console.log('annotateResult Error: ', error);
    }
    if (annotateResult) {
      console.log('annotateResult: ', annotateResult);
      setComplete(true);
    }
  }, [annotateResult, error]);

  const getColorFromSegmentId = (segmentId: string) => {
    const transparancy =
      activePeakResult && activePeakResult.segmentId !== segmentId ? 0.25 : 1;
    const color = segmentId.includes('INCONGRUENT')
      ? `rgba(75,192,192,${transparancy})`
      : segmentId.includes('OVERALL')
      ? `rgba(50,92,192,${transparancy})`
      : `rgba(175,92,192,${transparancy})`;
    return color;
  };

  const lines: { x: number; color?: string; dashed?: boolean }[] = [
    { x: rt.rt }
  ];
  if (peakResults && activePeakResult) {
    peakResults.forEach((pr) => {
      const color = getColorFromSegmentId(pr.segmentId);
      if (
        pr.amplitude === activePeakResult.amplitude &&
        pr.centerTime === activePeakResult.centerTime
      ) {
        lines.push({
          x: activePeakResult?.peakTime + SIGNAL_SHIFT,
          color: color
        });
      }
      lines.push({
        x: pr?.centerTime + SIGNAL_SHIFT,
        color: color,
        dashed: true
      });
    });
  } else if (!peakResults && activeMetric) {
    lines.push({ x: activeMetric?.peakTime + SIGNAL_SHIFT });
    lines.push({
      x: activeMetric?.centerTime + SIGNAL_SHIFT,
      dashed: true
    });
  }

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      {!complete && (
        <Grid item sm={9}>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'row',
                flexWrap: 'wrap',
                pl: 0,
                pr: 0,
                // height: 700,
                width: '100%',
                border: assessmentRes.phaseResults[0].annotatedConfidenceLevel
                  ? '2px solid #1fc91f'
                  : '0px'
              }}
            >
              <p>
                # {assessmentRes.assessmentNumber} | {assessmentRes.appVersion}{' '}
                | {assessmentRes.deviceFirmwareVersion}
              </p>
              {segments.length > 0 && (
                <>
                  <div style={{ height: 700, width: '100%' }}>
                    <LineChart
                      offset={chan === 'CZ' ? 400 : 200}
                      chartData={segments.map((s) => {
                        const color = getColorFromSegmentId(s.segmentId);

                        const invert =
                          (inverted && chan !== 'FZ') ||
                          (!inverted && chan === 'FZ');

                        return {
                          label: s.segmentId,
                          type: 'line',
                          data: invert ? s.data.map((d) => d * -1) : s.data,
                          backgroundColor: '#FFFFFF',
                          borderColor: color,
                          borderWidth: 2,
                          pointRadius: 0,
                          hidden:
                            chan === 'CZ'
                              ? false
                              : isGo
                              ? s.segmentId.includes('NOGO')
                              : !s.segmentId.includes('NOGO'),
                          borderDash: s.segmentId.includes('OVERALL')
                            ? [10, 5]
                            : []
                        };
                      })}
                      lines={lines}
                      ticks={Array.from(readyMap.values()).map(
                        (d) => d.centerTime + SIGNAL_SHIFT
                      )}
                      positivePeaks={
                        activeMetric
                          ? isMetricTypePositive(activeMetric.type)
                          : true
                      }
                      hasActiveSelection={peakResults && activeMetric}
                      callback={(res) => setPeakResults(res)}
                      clickCallback={(p: number) => {
                        if (activePeakResult) {
                          setPeakResults([
                            ...peakResults.filter(
                              (pr) =>
                                pr.segmentId !== activePeakResult.segmentId
                            ),
                            { ...activePeakResult, centerTime: p }
                          ]);
                        }
                      }}
                    />
                  </div>
                  {/* {overallSeg && (
                    <div
                      style={{ height: 300, width: '100%', marginTop: '1rem' }}
                    >
                      <ChartComponent
                        id="chartContainerSpec"
                        type="line"
                        data={{
                          labels: overallSeg.spectral.map((d) => d.frequency),

                          datasets: [
                            {
                              label: 'FFT: ' + intervalFreqAvg.toFixed(2),
                              type: 'line',
                              data: overallSeg.spectral.map((d, i) =>
                                i < 32 ? d.magnitude : d.magnitude * 2
                              ),
                              backgroundColor: '#FFFFFF',
                              borderColor: '#000000',
                              borderWidth: 2,
                              pointRadius: 0
                            }
                          ]
                        }}
                        options={{
                          maintainAspectRatio: false,
                          scales: {
                            x: {
                              min: 3,
                              max: 50,

                              display: true,
                              beginAtZero: true
                            },
                            y: {
                              max: 500
                            }
                          },

                          plugins: {
                            legend: {
                              display: true
                            }
                          }
                        }}
                      />
                      {intervalFreqAvg > 0 && (
                        <p>
                          {intervalFreqs['P200'].map(
                            (n) => n.toFixed(1) + ', '
                          )}
                          {intervalFreqs['N200'].map(
                            (n) => n.toFixed(1) + ', '
                          )}
                          {intervalFreqs['P3b'].map((n) => n.toFixed(1) + ', ')}
                        </p>
                      )}
                    </div>
                  )} */}
                </>
              )}
            </CardContent>
          </Card>
          <Grid container spacing={2} sx={{ flexGrow: 1, marginTop: '1rem' }}>
            {data.qScores.map((n) => (
              <Grid key={n.key} item sm={1}>
                <p>
                  {n.score} {n.key.replace('MISSION_RECLAIM_', '')}
                </p>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {!complete && (
        <Grid item sm={3}>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={2}
            padding={1}
            item
            xs={12}
          >
            <Grid item sm={12}>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={2}
                padding={1}
                item
                xs={12}
              >
                <p>
                  <strong>Age: </strong>
                  {(assessmentRes.ageMonths / 12).toFixed(1)}
                </p>
                <p>
                  <strong>Accuracy: </strong>
                  {(accuracy?.percent * 100).toFixed(1)}
                </p>
                <p>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={inverted}
                        onChange={() => {
                          setInverted(!inverted);
                        }}
                      />
                    }
                    label="Inverted"
                  />
                </p>
              </Grid>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={2}
                padding={1}
                item
                xs={12}
              >
                <p></p>
                <p>Confidence Level: </p>

                <Select
                  label="Type"
                  variant="outlined"
                  onChange={(v) => {
                    setConfidenceLevel(v.target.value as any as number);
                  }}
                  value={confidenceLevel}
                >
                  {['low', 'medium', 'high', 'very high'].map((cl, i) => (
                    <MenuItem key={cl} selected={1 === i + 1} value={i + 1}>
                      {cl}
                    </MenuItem>
                  ))}
                </Select>
                <p></p>
                <p>Flag For Review: </p>

                <Select
                  label="Type"
                  variant="outlined"
                  onChange={(v) => {
                    setFlagForReview(v.target.value === 'true');
                  }}
                  value={flagForReview}
                >
                  {['true', 'false'].map((cl, i) => (
                    <MenuItem
                      key={i}
                      selected={(cl === 'true') === flagForReview}
                      value={cl}
                    >
                      {cl}
                    </MenuItem>
                  ))}
                </Select>
                <p>
                  <Button
                    type="submit"
                    disabled={loading}
                    color="primary"
                    variant="outlined"
                    onClick={submitResults}
                  >
                    Save
                  </Button>
                </p>
              </Grid>
            </Grid>
            {loading && <h4>Saving</h4>}
            {!loading &&
              metrics &&
              metrics.map((m) => (
                <Grid
                  key={m.id + m.segmentType + m.channel + m.type}
                  item
                  sm={6}
                >
                  <Card
                    sx={{
                      border:
                        readyMap.has(getMetricUniqueId(m)) &&
                        getMetricUniqueId(m) !== getMetricUniqueId(activeMetric)
                          ? '1px green solid'
                          : getMetricUniqueId(m) ===
                            getMetricUniqueId(activeMetric)
                          ? '1px blue solid'
                          : '1px #ddd solid',
                      cursor: 'pointer',
                      ':hover': {
                        border:
                          getMetricUniqueId(m) ===
                            getMetricUniqueId(activeMetric) &&
                          getMetricUniqueId(m) !==
                            getMetricUniqueId(activeMetric)
                            ? '1px green solid'
                            : '1px purple solid'
                      }
                    }}
                    onClick={() => {
                      selectMetric(m);
                    }}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        fontSize: '12px',
                        paddingBottom: '0px !important'
                      }}
                    >
                      <span>
                        {readyMap.has(getMetricUniqueId(m)) &&
                        getMetricUniqueId(m) !==
                          getMetricUniqueId(activeMetric) ? (
                          <CheckCircle />
                        ) : (
                          <XCircle />
                        )}
                      </span>

                      <span>
                        {' '}
                        {m.type} {m.segmentType}
                      </span>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            <Grid item sm={12}>
              <Button
                type="submit"
                disabled={!(activeMetric && activePeakResult)}
                color="primary"
                variant="outlined"
                onClick={() => {
                  const annotatedMetric = {
                    ...activePeakResult,
                    notFound: false,
                    lowNumTrials: activeMetric.lowNumTrials,
                    fromResult: activeMetric.fromResult,
                    channel: activeMetric.channel,
                    numTrials: activeMetric.numTrials,
                    segmentType: activeMetric.segmentType,
                    type: activeMetric.type
                  };
                  delete annotatedMetric.segmentId;
                  console.log(
                    'Mark Metric as Good',
                    annotatedMetric,
                    activeMetric
                  );

                  readyMap.set(
                    getMetricUniqueId(activeMetric),
                    annotatedMetric
                  );
                  setReadyMap(readyMap);
                  let i = metrics.indexOf(activeMetric) + 1;
                  if (i >= metrics.length) {
                    //next batch
                    if (chan !== 'CZ') {
                      getNextBatch();
                      return;
                    } else {
                      console.log('ALL DONE');
                      return;
                    }
                  }

                  const nextM = metrics[i];
                  selectMetric(nextM);
                  return;
                }}
              >
                Set Metric {activeMetric ? '' : '-----'}
              </Button>
              <Button
                type="submit"
                disabled={!activeMetric}
                color="primary"
                variant="outlined"
                style={{ margin: '1rem' }}
                onClick={() => {
                  const annotatedMetric = {
                    peakTime: -1000,
                    centerTime: -1000,
                    amplitude: -1000,
                    notFound: true,
                    lowNumTrials: activeMetric.lowNumTrials,
                    fromResult: activeMetric.fromResult,
                    channel: activeMetric.channel,
                    numTrials: activeMetric.numTrials,
                    segmentType: activeMetric.segmentType,
                    type: activeMetric.type
                  };

                  console.log('Mark Metric as Not Found', annotatedMetric);
                  readyMap.set(
                    getMetricUniqueId(activeMetric),
                    annotatedMetric
                  );
                  setReadyMap(readyMap);
                  let i = metrics.indexOf(activeMetric) + 1;
                  if (i >= metrics.length) {
                    //next batch
                    if (chan !== 'CZ') {
                      getNextBatch();
                      return;
                    } else {
                      console.log('ALL DONE');
                      return;
                    }
                  }

                  const nextM = metrics[i];
                  selectMetric(nextM);
                  return;
                }}
              >
                Set Not Found {activeMetric ? '' : '-----'}
              </Button>
              <br />
              <hr />
              <br />
              <p>
                <Button
                  type="submit"
                  disabled={loading}
                  color="primary"
                  variant="outlined"
                  onClick={submitPoorDataFlag}
                >
                  Flag No Results Poor Data
                </Button>
              </p>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ErpItem;
