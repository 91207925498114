import React, { useEffect, useReducer, createContext, useContext } from 'react';
import { getLs } from '../utils/localStorage';

export interface AuthStateType {
  token: string | null | undefined;
  exp: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email: string | null | undefined;
  customerAccount?: string | null | undefined;
  isLoggedIn: Function;
  minAuthLevelMet: (role: ROLES) => boolean;
  hasAuthRole: (roles: ROLES[]) => boolean;
  isRole: (role: ROLES) => boolean;
  updated?: string | null | undefined;
  localStorageLoaded: boolean;
  roles?: string[];
}

interface Payload {
  token?: string;
  exp?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  customerAccount?: string;
  updated?: string;
  localStorageLoaded?: boolean;
  roles?: string[];
}

export enum ROLES {
  ADMIN,
  DEV_ADMIN,
  CSA_ADMIN,
  CSA_MANAGER,
  CSA,
  FINANCIAL_ANALYST
}

const AUTHLEVELS = {
  ADMIN: 4,
  CSA_ADMIN: 3,
  CSA_MANAGER: 2,
  CSA: 1,
  FINANCIAL_ANALYST: 0
};

type AuthActionType =
  | { type: 'LOGIN'; payload: Payload; redirectUrl?: string }
  | { type: 'LOCAL_STORAGE_LOADED'; localStorageLoaded?: boolean }
  | { type: 'LOGOUT'; redirectUrl?: string };

type Dispatch = (action: AuthActionType, payload?: Payload) => void;
type AuthProviderProps = { children: React.ReactNode };

const AuthStateContext = createContext<AuthStateType | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined);

const initialState = {
  token: null,
  exp: null,
  firstName: null,
  lastName: null,
  email: null,
  isLoggedIn: () => false,
  minAuthLevelMet: () => false,
  hasAuthRole: () => false,
  isRole: () => false,
  localStorageLoaded: false,
  updated: null
};

const reducer = (
  state: AuthStateType,
  action: AuthActionType
): AuthStateType => {
  switch (action.type) {
    case 'LOGIN':
      const {
        token,
        exp,
        firstName,
        lastName,
        email,
        localStorageLoaded,
        updated,
        roles
      } = action.payload;
      localStorage.setItem('userData', JSON.stringify(action.payload));

      return {
        ...state,
        token,
        exp,
        firstName,
        lastName,
        email,
        updated,
        localStorageLoaded:
          state.localStorageLoaded ||
          (localStorageLoaded ? localStorageLoaded : false),
        roles
      };
    case 'LOCAL_STORAGE_LOADED':
      return {
        ...state,
        localStorageLoaded:
          state.localStorageLoaded ||
          (action.localStorageLoaded ? action.localStorageLoaded : false)
      };
    case 'LOGOUT':
      localStorage.removeItem('userData');

      return {
        ...state,
        token: null,
        exp: null,
        firstName: null,
        lastName: null,
        email: null,
        roles: null
      };
    default:
      throw new Error('Bad action ');
  }
};

let storedUserData: Payload;

const checkSetUserData = () => {
  if (!getLs('userData')) return false;

  storedUserData = getLs('userData');

  const expiryDate = Date.parse(
    storedUserData && storedUserData.exp ? storedUserData.exp : ''
  );
  const currentDate = Date.now();
  const isExpired = expiryDate < currentDate || storedUserData == null;

  return !isExpired;
};

const AuthContextProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (checkSetUserData()) {
      // Token exists and isn't expired, log in
      if (!state.localStorageLoaded) {
        dispatch({
          type: 'LOGIN',
          payload: { ...storedUserData, localStorageLoaded: true }
        });
      }
    } else {
      localStorage.removeItem('userData');
      dispatch({
        type: 'LOCAL_STORAGE_LOADED',
        localStorageLoaded: true
      });
    }
  }, []);
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuthState = () => {
  const context = useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  context.isLoggedIn = () => {
    if (!context.token) return false;

    const expiryDate = Date.parse(context.exp ? context.exp : '');
    const currentDate = Date.now();
    const isExpired = expiryDate < currentDate || context.exp == null;

    return !isExpired && context.localStorageLoaded;
  };

  context.minAuthLevelMet = (minRole: ROLES): boolean => {
    //

    const level = AUTHLEVELS[ROLES[minRole]];
    if (!level) {
      return false;
    }
    let highestLevel = -1;
    context.roles?.forEach((r) => {
      const l = AUTHLEVELS[r];
      if (l && l > highestLevel) {
        highestLevel = l;
      }
    });

    return highestLevel >= level;
  };

  context.hasAuthRole = (requiredRoles: ROLES[]): boolean => {
    return context.roles?.some((r) =>
      requiredRoles.some((rr) => ROLES[rr].toString() === r)
    );
  };

  context.isRole = (role: ROLES): boolean => {
    return context.roles?.includes(ROLES[role]) || false;
  };

  return context;
};

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext) as Dispatch;

  return context;
}

export { AuthContextProvider, useAuthState, useAuthDispatch };
