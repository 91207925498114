import { FC } from 'react';
import { Modal, Box, Card, Typography, Divider } from '@mui/material';

import ConfirmModalInput from './ConfirmModalInput';

type Props = {
  handleConfirm: Function;
  closeConfirmModal: Function;
  handleCloseConfirm: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void;
  confirmModalOpen: boolean;
  heading: string;
  subheading: string;
  confirmText: string;
  bodyText?: string;
};

const ConfirmModal: FC<Props> = ({
  handleConfirm,
  closeConfirmModal,
  confirmModalOpen,
  handleCloseConfirm,
  heading,
  subheading,
  confirmText,
  bodyText,
  children
}) => {
  return (
    <Modal
      open={confirmModalOpen}
      onClose={handleCloseConfirm}
      aria-labelledby="modal-confirm"
      aria-describedby="modal-confirm"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: 400,
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Card sx={{ p: 4 }}>
          <Typography
            id="modal-confirm-heading"
            variant="h4"
            component="h2"
            sx={{ pb: 1 }}
          >
            {heading}
          </Typography>
          <Typography variant="h6" component="h4">
            {subheading}
          </Typography>
          <Divider />
          {children}
          <Divider />
          <Box sx={{ m: 2 }} />
          {bodyText && <Typography>{bodyText}</Typography>}
          <ConfirmModalInput
            handleConfirm={handleConfirm}
            closeConfirmModal={closeConfirmModal}
            confirmText={confirmText}
          />
        </Card>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
