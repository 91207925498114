import { Form } from 'formik';
import {
  Box,
  CardContent,
  Grid,
  TextField,
  FormHelperText,
  Divider,
  CardActions,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowRightIcon from '../../../icons/ArrowRight';
import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_STRIPE_PRODUCTS } from 'src/graphql/queries';

const CouponForm = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  error,
  data,
  loading,
  editMode,
  formErrors,
  touched,
  setModalOpen
}) => {
  useEffect(() => {
    const gotCreateData = data && data.CreateCoupon && !editMode;
    const gotUpdateData = data && data.UpdateCoupon && editMode;

    if (gotCreateData || gotUpdateData) {
      const timer = setTimeout(() => {
        setModalOpen(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [data]);

  const {
    loading: stripeProductsLoading,
    error: stripeProductsError,
    data: stripeProductsData
  } = useQuery(GET_STRIPE_PRODUCTS);

  useEffect(() => {
    if (stripeProductsError) {
      console.log(
        'Error retrieving Stripe Products',
        stripeProductsError.message
      );
    }
  }, [stripeProductsError]);

  return (
    <Form>
      <CardContent
        sx={{
          alignItems: 'center'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2}>
            <Grid item>
              <FormControl>
                <InputLabel id="coupon-type-label">Type*</InputLabel>
                <Select
                  autoWidth
                  name="discountType"
                  id="discountType"
                  labelId="coupon-type-label"
                  label="Type"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.discountType}
                  disabled={editMode}
                >
                  <MenuItem value="numberOfDays">
                    Subscription Trial Days
                  </MenuItem>
                  <MenuItem value="fixedAmount">Fixed Amount</MenuItem>
                  <MenuItem value="percentage">Percentage</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <DatePicker
                label="Valid Start Date*"
                value={values.validStartDate}
                onChange={(value) =>
                  setFieldValue('validStartDate', value, true)
                }
              />
            </Grid>
            <Grid item>
              <DatePicker
                label="Valid End Date*"
                value={values.validEndDate}
                onChange={(value) => setFieldValue('validEndDate', value, true)}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Code*"
                name="code"
                variant="outlined"
                onChange={handleChange}
                value={values.code}
                disabled={editMode}
                error={Boolean(formErrors.code && touched.code)}
                helperText={formErrors.code}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Description*"
                name="description"
                variant="outlined"
                onChange={handleChange}
                value={values.description}
                error={Boolean(formErrors.description && touched.description)}
                helperText={formErrors.description}
                onBlur={handleBlur}
              />
            </Grid>
            {values.discountType === 'numberOfDays' && (
              <Grid item>
                <Tooltip title="Enter the number of days the user will receive free from the date they start a subscription">
                  <TextField
                    label="Number of Days*"
                    name="discountDays"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.discountDays}
                    type="number"
                    error={Boolean(
                      formErrors.discountDays && touched.discountDays
                    )}
                    helperText={formErrors.discountDays}
                    onBlur={handleBlur}
                  />
                </Tooltip>
              </Grid>
            )}
            {values.discountType === 'fixedAmount' && (
              <>
                <Grid item>
                  <TextField
                    label="Amount CAD*"
                    name="discountAmountCad"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.discountAmountCad}
                    type="number"
                    helperText={formErrors.discountAmountCad}
                    error={Boolean(
                      formErrors.discountAmountCad && touched.discountAmountCad
                    )}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Amount USD*"
                    name="discountAmountUsd"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.discountAmountUsd}
                    type="number"
                    helperText={formErrors.discountAmountUsd}
                    error={Boolean(
                      formErrors.discountAmountUsd && touched.discountAmountUsd
                    )}
                  />
                </Grid>
              </>
            )}
            {values.discountType === 'percentage' && (
              <Grid item>
                <TextField
                  label="Percentage*"
                  name="discountPercentage"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.discountPercentage}
                  type="number"
                  helperText={formErrors.discountPercentage}
                  error={Boolean(
                    formErrors.discountPercentage && touched.discountPercentage
                  )}
                />
              </Grid>
            )}
            <Grid item>
              <Tooltip title="If blank, redemptions are unlimited">
                <TextField
                  label="Max Redemptions"
                  name="maxRedemptions"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.maxRedemptions}
                  type="number"
                  error={Boolean(
                    formErrors.maxRedemptions && touched.maxRedemptions
                  )}
                  helperText={
                    formErrors.maxRedemptions ||
                    'If blank, redemptions are unlimited'
                  }
                  onBlur={handleBlur}
                />
              </Tooltip>
            </Grid>
            {values.discountType !== 'numberOfDays' && (
              <>
                <Grid item>
                  <FormControl>
                    <InputLabel id="product-id-label">Product*</InputLabel>
                    <Select
                      name="productIds"
                      labelId="product-id-label"
                      label="Product*"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.productIds}
                      style={{ minWidth: '250px' }}
                    >
                      {!stripeProductsLoading &&
                        stripeProductsData?.GetStripeProducts?.map(
                          (product) => (
                            <MenuItem value={product.id}>
                              {`${product.name} - ${product.id}`}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    label="Affiliate ID"
                    name="affiliateId"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.affiliateId}
                    error={Boolean(
                      formErrors.affiliateId && touched.affiliateId
                    )}
                    onBlur={handleBlur}
                  />
                </Grid>
              </>
            )}
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.disabled}
                    onChange={handleChange}
                    name="disabled"
                    color="primary"
                  />
                }
                label="Disabled"
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
        {error && (
          <Box sx={{ mt: 3 }}>
            <FormHelperText error>{error.message}</FormHelperText>
          </Box>
        )}
        {loading && (
          <Box sx={{ mt: 3 }}>
            <CircularProgress />
          </Box>
        )}
        {data && (data.CreateCoupon || data.UpdateCoupon) && (
          <Box sx={{ mt: 3 }}>
            <FormHelperText>
              Coupon successfully {editMode ? 'updated' : 'created'}!
            </FormHelperText>
          </Box>
        )}
      </CardContent>
      <Divider />
      <CardActions>
        {!(data && (data.CreateCoupon || data.UpdateCoupon)) && (
          <Button
            color="primary"
            endIcon={<ArrowRightIcon fontSize="small" />}
            variant="text"
            type="submit"
            disabled={loading}
          >
            {editMode ? 'Update ' : 'Create '}Coupon
          </Button>
        )}
      </CardActions>
    </Form>
  );
};

export default CouponForm;
