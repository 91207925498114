import React from 'react';
import { ChangeEvent, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Container,
  Grid,
  FormHelperText
} from '@mui/material';
import { AppConfigType } from '../../../pages/dashboard/Config';
import ArrowRightIcon from '../../../icons/ArrowRight';
import Refresh from '../../../icons/Refresh';

const ConfigItem: React.FC<{
  type: AppConfigType;
  configData: any;
  isLoading: boolean;
  updateAppConfig: Function;
  updateAppConfigData: boolean;
}> = ({
  type,
  configData,
  isLoading,
  updateAppConfig,
  updateAppConfigData
}) => {
  enum Status {
    NONE,
    OK,
    FAILED,
    INVALID
  }

  const [inputValue, setInputValue] = useState<String>('');
  const [status, setStatus] = useState<Status>(Status.NONE);

  React.useEffect(() => {
    if (configData) {
      const jsonString = JSON.stringify(JSON.parse(configData), null, 2);
      setInputValue(jsonString);
    }
  }, [configData]);

  const tryParseJSONObject = (jsonString: String): any => {
    try {
      const o: any = JSON.parse(jsonString.toString());
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {}

    return null;
  };

  const handleFormSubmit = async (values, actions) => {
    try {
      const compressedJSON: any = tryParseJSONObject(inputValue);
      if (compressedJSON) {
        await updateAppConfig(type, JSON.stringify(compressedJSON));
        setStatus(Status.OK);
      } else {
        setStatus(Status.INVALID);
      }
    } catch (error) {
      console.log(error);
      setStatus(Status.FAILED);
    }
  };

  return (
    <Card sx={{ width: '100%', mb: 4 }}>
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          flexWrap: 'wrap',
          pl: 0,
          pr: 0
        }}
      >
        <Formik
          initialValues={[]}
          onSubmit={(values, actions) => {
            handleFormSubmit(values, actions);
          }}
        >
          <Form style={{ flexGrow: 1 }}>
            <Grid container sx={{ flexGrow: 1 }}>
              {isLoading ? (
                <Grid item md={6} xs={12} sx={{ flexGrow: 1 }}>
                  <Container>
                    <Typography
                      padding="10px 0 0 0"
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      Loading...
                    </Typography>
                  </Container>
                </Grid>
              ) : (
                <Grid item key={`item-`} md={12} xs={12} sx={{ flexGrow: 1 }}>
                  <Container>
                    <TextField
                      margin="normal"
                      name="valueItem"
                      value={inputValue ?? ''}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={3}
                      disabled={isLoading}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setInputValue(e.target.value);
                      }}
                    />
                  </Container>
                </Grid>
              )}
              {!isLoading && status === Status.OK && updateAppConfigData && (
                <Grid item xs={12}>
                  <Box sx={{ mx: 4 }}>
                    <FormHelperText>Success!</FormHelperText>
                  </Box>
                </Grid>
              )}
              {!isLoading && status === Status.FAILED && (
                <Grid item xs={12}>
                  <Box sx={{ mx: 4 }}>
                    <FormHelperText error>
                      Failed to update config!
                    </FormHelperText>
                  </Box>
                </Grid>
              )}
              {!isLoading && status === Status.INVALID && (
                <Grid item xs={12}>
                  <Box sx={{ mx: 4 }}>
                    <FormHelperText error>
                      Invalid JSON for config!
                    </FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Container sx={{ pt: 2, pb: 0, pl: 0, pr: 0 }}>
              <Button
                color="primary"
                endIcon={
                  isLoading ? (
                    <Refresh fontSize="small" />
                  ) : (
                    <ArrowRightIcon fontSize="small" />
                  )
                }
                variant="outlined"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? 'Updating' : 'Update Values'}
              </Button>
            </Container>
          </Form>
        </Formik>
      </CardContent>
    </Card>
  );
};

export default ConfigItem;
