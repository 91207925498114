import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AuthContextProvider } from './contexts/authContext';
import { SettingsProvider } from './contexts/SettingsContext';
import App from './App';

let uri = process.env.REACT_APP_SENSAI_SERVER_BASE_URL;
if (!uri || uri === 'localhost' || uri === 'undefined') {
  uri = 'http://localhost:2011/local';
}

const httpLink = createHttpLink({
  uri: `${uri}/admin`
});

const authLink = setContext((_, { headers }) => {
  const userDataRaw = localStorage.getItem('userData');
  const userData = userDataRaw && JSON.parse(userDataRaw);
  const token = userData && userData.token;
  const hasToken = token !== undefined && token !== '' && token !== null;
  const expiryDate = userData && userData.exp && Date.parse(userData.exp);
  const currentDate = Date.now();
  const isExpired = !userData || !expiryDate || expiryDate < currentDate;

  return {
    headers: {
      ...headers,
      authorization: hasToken && !isExpired ? `Bearer ${token}` : ''
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <AuthContextProvider>
      <SettingsProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SettingsProvider>
    </AuthContextProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
