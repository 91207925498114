import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import GlobalStyles from './components/GlobalStyles';
import routes from './routes';
import { useAuthState } from './contexts/authContext';
import { createCustomTheme } from './theme';
import useSettings from './hooks/useSettings';
import SettingsDrawer from './components/SettingsDrawer';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const authState = useAuthState();
  const routing = useRoutes(routes);
  const { settings } = useSettings();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
        <SettingsDrawer />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
