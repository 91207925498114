import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  FormHelperText,
  styled,
  Tabs,
  Tab
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_COUPONS, GET_STRIPE_PRODUCTS } from '../../../graphql/queries';
import { useEffect, useState } from 'react';
import { DiscountType } from '../../../pages/dashboard/Coupons';
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridMoreVertIcon,
  GridToolbar
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { getDollars } from 'src/utils/currency';
import { format, parse } from 'date-fns';
import { TabPanel } from 'src/components/shared/TabPanel';

// TODO - to discuss
// The DataGrid can only filter the rows according to one criterion at the time.
// To use multi-filters, you need to upgrade to the Pro plan or above.

interface CouponData {
  id: string;
  created: {
    formatted: string;
  };
  updated: {
    formatted: string;
  };
  validStartDate: {
    formatted: string;
  };
  validEndDate: {
    formatted: string;
  };
  code: string;
  description: string;
  discountType: DiscountType;
  discountDays?: number;
  discountAmountCad?: number;
  discountAmountUsd?: number;
  discountPercentage?: number;
  maxRedemptions?: number;
  redemptionsToDate: number;
  disabled: boolean;
  productIds?: string[];
  affiliateId: string;
}
interface Data {
  Coupon: CouponData[];
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '.MuiDataGrid-row.warn': {
    backgroundColor: theme.palette.warning.main
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    whiteSpace: 'break-spaces',
    lineHeight: 1.2
  },
  '& .MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer':
    {
      pl: 1
    }
}));

const parseDiscountType = (discountType: DiscountType) => {
  switch (discountType) {
    case 'fixedAmount':
      return '$';
    case 'percentage':
      return '%';
    case 'numberOfDays':
      return 'Days';
    default:
      return '';
  }
};

const parseMaxRedemptions = (maxRedemptions: number) => {
  if (maxRedemptions === -1) {
    return 'unlimited';
  } else {
    return maxRedemptions;
  }
};

const CouponListDataGrid = ({
  handleOpenEdit,
  setCurrentCoupon,
  setIsEditMode
}) => {
  const { loading, error, data } = useQuery<Data>(GET_COUPONS);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    loading: stripeProductsLoading,
    error: stripeProductsError,
    data: stripeProductsData
  } = useQuery(GET_STRIPE_PRODUCTS);

  const handleClickIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    const menuElement = event.currentTarget;
    const currentCouponElement = menuElement.parentElement.parentElement;
    setAnchorEl(menuElement);
    const coupon: CouponData = data.Coupon.find(
      (c) => c.id === currentCouponElement.getAttribute('data-id')
    );
    setCurrentCoupon({
      ...coupon,
      validStartDate: dayjs(coupon.validStartDate.formatted),
      validEndDate: dayjs(coupon.validEndDate.formatted)
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEdit = (e) => {
    setIsEditMode(true);
    handleOpenEdit();
    setAnchorEl(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'validStartDate',
      headerName: 'Valid From',
      minWidth: 140,
      valueGetter: (value, row, column, apiRef) => {
        const startDate = new Date(row.validStartDate);
        const time = startDate.getTime();
        return time;
      },
      valueFormatter: (value, row, column, apiRef) => {
        if (value) {
          const date = new Date(value as number);
          return format(new Date(date), 'MMM dd, yyyy');
        } else {
          return '';
        }
      }
    },
    {
      field: 'validEndDate',
      headerName: 'Valid Until',
      minWidth: 140,
      valueGetter: (value, row, column, apiRef) => {
        if (row.validEndDate) {
          const date = new Date(row?.validEndDate);
          return format(new Date(date), 'MMM dd, yyyy');
        } else {
          return '';
        }
      }
    },
    { field: 'code', headerName: 'Code', minWidth: 140 },
    { field: 'description', headerName: 'Description', minWidth: 300 },
    {
      field: 'discountType',
      headerName: 'Discount Type',
      minWidth: 100,
      valueGetter: (value, row, column, apiRef) =>
        parseDiscountType(row?.discountType)
    },
    { field: 'discountDays', headerName: 'Discount Days', minWidth: 100 },
    {
      field: 'discountAmountCad',
      headerName: 'Discount $CAD',
      minWidth: 100,
      valueFormatter: (value, row, column, apiRef) =>
        value ? getDollars(value as number) : 'n/a'
    },
    {
      field: 'discountAmountUsd',
      headerName: 'Discount $USD',
      minWidth: 100,
      valueFormatter: (value, row, column, apiRef) =>
        value ? getDollars(value as number) : 'n/a'
    },
    {
      field: 'discountPercentage',
      headerName: 'Discount %',
      minWidth: 100,
      valueFormatter: (value, row, column, apiRef) =>
        value ? `${value}%` : 'n/a'
    },
    {
      field: 'maxRedemptions',
      headerName: 'Max Redemptions',
      minWidth: 120,
      valueGetter: (value, row, column, apiRef) =>
        parseMaxRedemptions(row?.maxRedemptions)
    },
    {
      field: 'redemptionsToDate',
      headerName: 'Redeemed to Date',
      minWidth: 120
    },
    {
      field: 'disabled',
      headerName: 'Disabled',
      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => String(row.disabled)
    },
    { field: 'productIds', headerName: 'Product ID', minWidth: 200 },
    { field: 'affiliateId', headerName: 'Affiliate ID', minWidth: 200 },
    {
      field: 'Actions',
      width: 70,
      headerName: '',
      renderCell: () => {
        return (
          <>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-haspopup="true"
              onClick={handleClickIcon}
            >
              <GridMoreVertIcon />
            </IconButton>
            <Menu
              id="coupon-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleClose}
            >
              <MenuItem key="edit-coupon" onClick={handleClickEdit}>
                Edit
              </MenuItem>
            </Menu>
          </>
        );
      }
    }
  ];

  const [tabValue, setTabValue] = useState<number>(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: 'disabled',
        operator: 'contains',
        value: 'false'
      }
    ]
  });

  const isStripeProductShippable = (coupon: CouponData) => {
    const stripeProduct = stripeProductsData?.GetStripeProducts.find(
      (p) => p.id === coupon.productIds[0]
    );
    return stripeProduct?.metadata?.shippingRequired === 'true';
  };
  const getSubscriptionRows = () =>
    data.Coupon.filter((c) => !isStripeProductShippable(c)).map((coupon) => ({
      ...coupon,
      validStartDate: coupon.validStartDate.formatted,
      validEndDate: coupon.validEndDate.formatted
    }));
  const getItemRows = () =>
    data.Coupon.filter((c) => isStripeProductShippable(c)).map((coupon) => ({
      ...coupon,
      validStartDate: coupon.validStartDate.formatted,
      validEndDate: coupon.validEndDate.formatted
    }));
  const getSubscriptionColumns = () =>
    columns.filter((c) => c.field !== 'affiliateId');
  const getItemColumns = () =>
    columns.filter((c) => c.field !== 'discountDays');

  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" variant="overline">
          Coupon List
        </Typography>

        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Subscription Coupons" />
              <Tab label="Headset Coupons" />
            </Tabs>
          </Box>

          <TabPanel value={tabValue} index={0}>
            <Box sx={{ width: '100%' }}>
              <StyledDataGrid
                slots={{ toolbar: GridToolbar }}
                slotProps={{ toolbar: { showQuickFilter: true } }}
                scrollbarSize={10}
                autoHeight
                rows={data?.Coupon ? getSubscriptionRows() : []}
                loading={loading}
                columns={getSubscriptionColumns()}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) =>
                  setFilterModel(newFilterModel)
                }
              />
            </Box>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Box sx={{ width: '100%' }}>
              <StyledDataGrid
                slots={{ toolbar: GridToolbar }}
                slotProps={{ toolbar: { showQuickFilter: true } }}
                scrollbarSize={10}
                autoHeight
                rows={data?.Coupon ? getItemRows() : []}
                loading={loading}
                columns={getItemColumns()}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) =>
                  setFilterModel(newFilterModel)
                }
              />
            </Box>
          </TabPanel>
        </Card>

        {error && (
          <Box sx={{ mt: 3 }}>
            <FormHelperText error>{error.message}</FormHelperText>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CouponListDataGrid;
