import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  FormHelperText,
  Divider,
  CardActions,
  Button
} from '@mui/material';
import ArrowRightIcon from '../../../icons/ArrowRight';
import { ApolloError } from '@apollo/client';
import { UserSearchValues } from 'src/hooks/useSearchUser';

interface Props {
  handleFormSubmit: (
    values: UserSearchValues,
    actions: FormikHelpers<{
      email: string;
    }>
  ) => void;
  userData: any;
  userLoading: boolean;
  userError: ApolloError;
  usersData:
    | undefined
    | { Users: { email: string; firstName: string; lastName: string }[] };
  usersLoading: boolean;
  usersError: ApolloError;
}

const formTest = (values: UserSearchValues) =>
  !values.email && !values.firstName && !values.lastName;
const formTest2 = (values: UserSearchValues) =>
  values.email && (values.firstName || values.lastName);
const formTestMessage = 'Either email or name must be provided, but not both';

const UserSearch = ({
  handleFormSubmit,
  userData,
  userLoading,
  userError,
  usersData,
  usersLoading,
  usersError
}: Props) => {
  return (
    <>
      <Typography fontSize="" color="textPrimary" variant="h5" mb={2}>
        Get User Details
      </Typography>
      <Card>
        <Formik
          initialValues={{
            email: userData?.User?.email || '',
            firstName: '',
            lastName: ''
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255)
          })}
          onSubmit={(values, actions) => {
            handleFormSubmit(
              { ...values, email: values.email.toLowerCase() },
              actions
            );
          }}
          validate={(values) => {
            const errors: Partial<UserSearchValues> = {};
            if (formTest(values) || formTest2(values)) {
              errors.email = formTestMessage;
              errors.firstName = formTestMessage;
              errors.lastName = formTestMessage;
            }
            return errors;
          }}
        >
          {({ errors, handleBlur, handleChange, touched, values }) => {
            return (
              <Form>
                <CardContent
                  sx={{
                    alignItems: 'center'
                  }}
                >
                  <TextField
                    error={Boolean(touched?.email && errors?.email)}
                    helperText={touched?.email && errors?.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values?.email ?? ''}
                    variant="outlined"
                    sx={{ mr: 2, minWidth: 300 }}
                  />
                  <TextField
                    error={Boolean(touched?.firstName && errors?.firstName)}
                    helperText={touched?.firstName && errors?.firstName}
                    label="First Name"
                    margin="normal"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values?.firstName ?? ''}
                    variant="outlined"
                    sx={{ mr: 2 }}
                  />
                  <TextField
                    error={Boolean(touched?.lastName && errors?.lastName)}
                    helperText={touched?.lastName && errors?.lastName}
                    label="Last Name"
                    margin="normal"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values?.lastName ?? ''}
                    variant="outlined"
                  />

                  {userError && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{userError.message}</FormHelperText>
                    </Box>
                  )}

                  {userData && userData.User === null && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>No user found</FormHelperText>
                    </Box>
                  )}

                  {usersData?.Users && usersData.Users.length === 0 && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>No users found</FormHelperText>
                    </Box>
                  )}
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    color="primary"
                    endIcon={<ArrowRightIcon fontSize="small" />}
                    variant="text"
                    type="submit"
                    disabled={userLoading}
                  >
                    Get user details
                  </Button>
                </CardActions>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </>
  );
};

export default UserSearch;
