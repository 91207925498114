import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Lock as LockIcon } from 'react-feather';
import { useMutation, gql } from '@apollo/client';
import { Button, ListItem } from '@mui/material';
import { useAuthDispatch } from '../contexts/authContext';
import { LoadingButton } from '@mui/lab';

const LOGOUT = gql`
  mutation Logout {
    Logout
  }
`;

const LogoutButton = () => {
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();
  const [executeLogout, { loading, data, error }] = useMutation(LOGOUT);

  const handleClick = () => {
    executeLogout();
  };

  useEffect(() => {
    console.log(data);
    if (data) {
      dispatch({
        type: 'LOGOUT'
      });
      navigate('/login', { replace: true });
    }
  }, [data]);

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
    >
      <LoadingButton
        sx={{
          color: 'text.secondary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          width: '100%',
          '& svg': {
            mr: 1
          }
        }}
        onClick={handleClick}
        loading={loading}
        loadingPosition="start"
        startIcon={<LockIcon size="20" />}
      >
        <span>{loading ? 'Logging out...' : 'Logout'}</span>
      </LoadingButton>
    </ListItem>
  );
};

export default LogoutButton;
