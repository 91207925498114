import React, { useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';
import { Box, TextField, Button } from '@mui/material';
import { numStringToCents } from '../../../utils/currency';
import ConfirmModal from '../../shared/ConfirmModal';
import { LineItem } from './UserOrders';
import { RefundReason } from './UserOrderRefundDetails';

type Props = {
  pid: string;
  lineNumber: number;
  executePartialRefund: Function;
  setErrorMessage: Function;
  amountRefundable: number;
  quantity: number;
  lineType: string;
  item: LineItem;
  fulfilmentStatus: string;
  formRefundQuantity: number;
  setFormRefundQuantity: React.Dispatch<React.SetStateAction<number>>;
  formReturnQuantity: number;
  setFormReturnQuantity: React.Dispatch<React.SetStateAction<number>>;
  forceManuallRefund: boolean;
  refundReason: RefundReason;
  refundNotes: string;
};

const UserOrderRefundinput: React.FC<Props> = ({
  pid,
  lineNumber,
  executePartialRefund,
  setErrorMessage,
  amountRefundable,
  quantity,
  lineType,
  item,
  fulfilmentStatus,
  formRefundQuantity,
  setFormRefundQuantity,
  formReturnQuantity,
  setFormReturnQuantity,
  forceManuallRefund,
  refundReason,
  refundNotes
}) => {
  type Values = {
    refundAmount: string;
    refundQuantity: number;
    returnQuantity: number;
  };

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirm = () => setConfirmModalOpen(true);
  const handleCloseConfirm = () => setConfirmModalOpen(false);
  const [formRefundAmount, setFormRefundAmount] = useState('0');

  const isNotReturnable = [
    'QUEUED_BACKORDER',
    'awaiting_allocation',
    'awaiting_picking'
  ].includes(fulfilmentStatus);

  const handleFormSubmit = (
    values: Values,
    actions: FormikHelpers<{ refundAmount: string }>
  ) => {
    const { refundAmount, refundQuantity, returnQuantity } = values;
    setErrorMessage('');

    const isShippableItem = quantity > 0 && lineType === 'product_shippable';

    if (isShippableItem) {
      const preRefundNetQuantity =
        item.quantity - item.returnQuantity - item.refundQuantity;

      if (refundQuantity + returnQuantity > preRefundNetQuantity) {
        setErrorMessage('Refund quantity cannot exceed net purchased quantity');
        actions.setSubmitting(false);
        return;
      }

      if (parseInt(refundAmount) > 0) {
        setFormRefundAmount(refundAmount);
        setFormRefundQuantity(refundQuantity);
        setFormReturnQuantity(returnQuantity);
        handleOpenConfirm();
      }
    } else {
      setFormRefundAmount(refundAmount);
      handleOpenConfirm();
    }
  };

  const handleConfirmPartialRefund = async () => {
    setErrorMessage('');
    await executePartialRefund({
      variables: {
        paymentIntentId: pid,
        lineNumber,
        refundAmount: numStringToCents(formRefundAmount),
        refundQuantity: formRefundQuantity,
        returnQuantity: formReturnQuantity,
        paymentManualRefund: forceManuallRefund,
        refundReason,
        refundNotes
      }
    });
  };

  const handleChangeRefundQuantity = (event, handleChange) => {
    setFormRefundQuantity(parseInt(event.target.value, 10));
    handleChange(event);
  };

  const handleChangeReturnQuantity = (event, handleChange) => {
    setFormReturnQuantity(parseInt(event.target.value, 10));
    handleChange(event);
  };

  return (
    <>
      <ConfirmModal
        handleConfirm={handleConfirmPartialRefund}
        closeConfirmModal={handleCloseConfirm}
        confirmModalOpen={confirmModalOpen}
        handleCloseConfirm={handleCloseConfirm}
        heading="Confirm Issue Refund"
        subheading="Type 'REFUND' to issue refund"
        confirmText="REFUND"
      />
      <Formik
        initialValues={{
          refundAmount: '0',
          refundQuantity: 0,
          returnQuantity: 0
        }}
        validationSchema={Yup.object().shape({
          refundAmount: Yup.number()
            .required('Amount is required')
            .min(1, 'Amount must be greater than 0')
            .max(amountRefundable / 100, 'Amount cannot exceed total')
        })}
        onSubmit={(values, actions) => {
          handleFormSubmit(values, actions);
        }}
      >
        {({ errors, handleBlur, handleChange, touched, values }) => (
          <Form>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {quantity > 0 && lineType === 'product_shippable' && (
                <Box sx={{ ml: 2 }}>
                  <TextField
                    error={Boolean(
                      touched.refundQuantity && errors.refundQuantity
                    )}
                    helperText={touched.refundQuantity && errors.refundQuantity}
                    label="Refund quantity"
                    margin="normal"
                    name="refundQuantity"
                    onBlur={handleBlur}
                    onChange={(event) =>
                      handleChangeRefundQuantity(event, handleChange)
                    }
                    type="number"
                    value={values.refundQuantity}
                    variant="outlined"
                    style={{ color: 'black' }}
                    disabled={!isNotReturnable}
                  />
                </Box>
              )}
              {quantity > 0 && lineType === 'product_shippable' && (
                <Box sx={{ ml: 2 }}>
                  <TextField
                    error={Boolean(
                      touched.returnQuantity && errors.returnQuantity
                    )}
                    helperText={touched.returnQuantity && errors.returnQuantity}
                    label="Return quantity"
                    margin="normal"
                    name="returnQuantity"
                    onBlur={handleBlur}
                    onChange={(event) =>
                      handleChangeReturnQuantity(event, handleChange)
                    }
                    type="number"
                    value={values.returnQuantity}
                    variant="outlined"
                    style={{ color: 'black' }}
                    disabled={isNotReturnable}
                  />
                </Box>
              )}
              <Box sx={{ ml: 2 }}>
                <TextField
                  error={Boolean(touched.refundAmount && errors.refundAmount)}
                  helperText={touched.refundAmount && errors.refundAmount}
                  label="Refund amount"
                  margin="normal"
                  name="refundAmount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.refundAmount}
                  variant="outlined"
                  style={{ color: 'black' }}
                />
              </Box>
              <Box sx={{ ml: 1 }}>
                <Button size="small" variant="contained" type="submit">
                  refund
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserOrderRefundinput;
