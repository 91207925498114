import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, Divider } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import type { AppBarProps } from '@mui/material';
import MenuIcon from '../../icons/Menu';
import Logo from '../Logo';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="primary"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
          size="large">
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Box
            sx={{
              display: {
                lg: 'inline',
                xs: 'none'
              }
            }}
          >
            <Logo />
          </Box>
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        {/* <LanguagePopover /> */}
        <Box sx={{ ml: 1 }}>{/* <ContentSearch /> */}</Box>
        <Box sx={{ ml: 1 }}>{/* <ContactsPopover /> */}</Box>
        <Box sx={{ ml: 1 }}>{/* <NotificationsPopover /> */}</Box>
        <Box sx={{ ml: 2 }}>{/* <AccountPopover /> */}</Box>
      </Toolbar>
      <Divider />
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
