import { useLazyQuery } from '@apollo/client';
import {
  GET_USER,
  GET_USERS
} from '../components/dashboard/users/UserInspector';
import { FormikHelpers } from 'formik';

export type UserSearchValues = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

const useSearchUser = () => {
  const [
    executeGetUser,
    { loading: userLoading, data: userData, error: userError }
  ] = useLazyQuery(GET_USER, {
    fetchPolicy: 'no-cache'
  });
  const [
    executeGetUsers,
    { loading: usersLoading, data: usersData, error: usersError }
  ] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'no-cache'
  });

  const handleSearchUser = (
    values: UserSearchValues,
    actions: FormikHelpers<{ email: string }>
  ) => {
    const { email, firstName, lastName } = values;

    if (email) {
      executeGetUser({ variables: { email, firstName, lastName } });
    } else {
      executeGetUsers({ variables: { input: { firstName, lastName } } });
    }
  };

  return [
    handleSearchUser,
    executeGetUser,
    userLoading,
    userData,
    userError,
    usersLoading,
    usersData,
    usersError
  ] as const;
};

export default useSearchUser;
