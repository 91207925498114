import { FC, useState, useEffect } from 'react';
import React from 'react';
import { format } from 'date-fns';

import {
  DataGrid,
  GridColDef,
  GridApi,
  GridRowSelectionModel,
  GridColumnVisibilityModel
} from '@mui/x-data-grid';
import OrdersActionList from './OrdersActions';
import {
  FulfilmentStatus,
  mapFulfillmentStatus,
  WAREHOUSE_MAP
} from 'src/utils/fulfillmentUtils';
import OrdersListToolbar from 'src/components/dashboard/orders/OrdersListToolbar';
import { OrderData } from 'src/components/dashboard/orders/UserOrders';
import UserOrderDetails from './UserOrderDetails';
import { getDollars } from 'src/utils/currency';
import { useSearchParams } from 'react-router-dom';
import UserOrderRefundModal from './UserOrderRefundModal';
import { formControlClasses, styled } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import ConfirmModal from '../../shared/ConfirmModal';
import { Countries } from 'src/utils/Countries';
import { audienceColumnConifg } from './audienceExport';

const REQUEUE_ORDER = gql`
  mutation UnCancelOrder($id: String!) {
    UnCancelOrder(id: $id)
  }
`;

type Props = {
  data: {
    orders: OrderData[];
    skuMap: Map<string, string>;
  };
  mergeOrderData: (orders: OrderData[]) => void;
  handleOnPageChange: (page: any) => void;
  rowCount?: number;
  loading: boolean;
  pageSize: number;
  queryOrders?: (page: number, query?: any, getAllPages?: boolean) => void;
  currentPage?: number;
};

let columnVisibility: GridColumnVisibilityModel = {
  id: false,
  created: false,
  updated: false,
  paymentIntentId: false,
  fulfilmentId: false,
  shippedDate: false,
  refundReason: false,
  refundNotes: false,
  country: false,
  province: false,
  utmSource: false,
  utmMedium: false,
  utmCampaign: false,
  utmTerm: false,
  utmContent: false,
  refundDate: false,
  SEN_HEADSET_V1: false,
  'SEN_HEADSET_V1.5': false,
  SEN_HEADSET_OPENV1: false,
  'SKU 3': false,
  'SKU 4': false,
  'SKU 5': false,
  affiliateId: false,
  lineAmout: false,
  shippingAmount: false,
  tax1: false,
  tax2: false,
  tax3: false,
  tax4: false,
  tax5: false,
  orderToRefundMinutes: false,
  couponCode: false
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '.MuiDataGrid-row.warn': {
    backgroundColor: theme.palette.warning.main
  }
}));

const OrdersListDataGrid: FC<Props> = (props: Props) => {
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const [orderData, setOrderData] = useState<OrderData>(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderForActions, setOrderForActions] = useState(null);
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [requeuedModalOpen, setRequeuModalOpen] = useState(false);

  const [googleAudienceExport, setGoogleAudienceExport] = useState(false);
  const [visibleColumns, setVisibleColumns] =
    useState<Record<string, boolean>>(columnVisibility);

  const handleCloseModal = () => {
    setRefundModalOpen(false);
    setRequeuModalOpen(false);
  };

  const [executeReQueueOrder, { loading, data, error }] = useMutation(
    REQUEUE_ORDER,
    {
      errorPolicy: 'all'
    }
  );

  useEffect(() => {
    if (error) {
      alert(error.message);
    }
  }, [error]);

  const handleOpenRefundModal = (type: 'REFUND' | 'QUEUE_FULFILLMENT') => {
    switch (type) {
      case 'REFUND':
        setRefundModalOpen(true);
        break;
      case 'QUEUE_FULFILLMENT':
        setRequeuModalOpen(true);
        break;
    }
  };

  let columnConfig: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 320
    },
    {
      field: 'created',
      headerName: 'Created',
      minWidth: 200,

      valueGetter: (value, row, column, apiRef) => {
        if (row.created) {
          const created = new Date(row.created?.formatted);
          return format(new Date(created), 'MM/dd/yyyy HH:mm:ss');
        } else {
          return '';
        }
      }
    },

    {
      field: 'updated',
      headerName: 'Updated',
      minWidth: 200,

      valueGetter: (value, row, column, apiRef) => {
        if (row.updated) {
          const updated = new Date(row.updated?.formatted);
          return format(new Date(updated), 'MM/dd/yyyy HH:mm:ss');
        } else {
          return '';
        }
      }
    },
    {
      field: 'confirmationCode',
      headerName: 'Confirmation Code',
      width: 180,
      valueGetter: (value, row, column, apiRef) => row.confirmationCode ?? ''
    },
    { field: 'email', headerName: 'Email', minWidth: 200 },
    {
      field: 'amountTotal',
      headerName: 'Total Amount',
      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        if (row.amount) {
          return getDollars(row.amount);
        } else {
          return 'N/A';
        }
      }
    },
    { field: 'status', headerName: 'Payment Status', width: 150 },
    { field: 'paymentProvider', headerName: 'Provider', width: 90 },
    {
      field: 'paymentIntentId',
      headerName: 'Payment ID',
      width: 250
    },
    {
      field: 'fulfilmentStatus',
      headerName: 'Fulfillment Status',
      width: 150,
      valueGetter: (value, row, column, apiRef) => {
        return mapFulfillmentStatus(value as any as FulfilmentStatus);
      }
    },

    {
      field: 'fulfilmentId',
      headerName: 'Fulfilment ID',
      width: 200
    },
    {
      field: 'fulfilmentWarehouseCode',
      headerName: 'Warehouse',
      width: 120,
      valueGetter: (value, row, column, apiRef) => {
        if (!value) {
          return 'n/a';
        }
        return WAREHOUSE_MAP.get(value as any as string);
      }
    },

    {
      field: 'shippedDate',
      headerName: 'Shipped Date',
      minWidth: 200,

      valueGetter: (value, row, column, apiRef) => {
        if (row.shippedDate && row.fulfilmentStatus === 'despatched') {
          const shippedDate = new Date(row.shippedDate?.formatted);
          return format(new Date(shippedDate), 'MM/dd/yyyy HH:mm:ss');
        } else {
          return 'N/A';
        }
      }
    },
    {
      field: 'addressAgeMinutes',
      headerName: 'Address Age',
      minWidth: 150,
      valueGetter: (value, row, column, apiRef) => {
        if (row.addressAgeMinutes) {
          return row.addressAgeMinutes <= 24
            ? `${Math.floor(row.addressAgeMinutes / 60).toLocaleString()} Hours`
            : `${Math.floor(
                row.addressAgeMinutes / (60 * 24)
              ).toLocaleString()} Days`;
        } else {
          return 'N/A';
        }
      }
    },
    {
      field: 'refundReason',
      headerName: 'Refund Reason',

      minWidth: 200
    },
    {
      field: 'refundNotes',
      headerName: 'Refund Notes',

      minWidth: 200
    },
    {
      field: 'country',
      headerName: 'Country',

      minWidth: 200,

      valueGetter: (value, row, column, apiRef) => {
        if (row.country) {
          return row.country;
        } else {
          return '';
        }
      }
    },
    {
      field: 'province',
      headerName: 'Province',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        if (row.shippingAddress?.province || row.billingAddress?.province) {
          return row.shippingAddress?.province || row.billingAddress?.province;
        } else {
          return '';
        }
      }
    },
    {
      field: 'utmSource',
      headerName: 'UTM Source',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        if (row.utmSource) {
          return row.utmSource;
        } else {
          return '';
        }
      }
    },
    {
      field: 'utmMedium',
      headerName: 'UTM Medium',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        if (row.utmMedium) {
          return row.utmMedium;
        } else {
          return '';
        }
      }
    },
    {
      field: 'utmCampaign',
      headerName: 'UTM Campaign',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        if (row.utmCampaign) {
          return row.utmCampaign;
        } else {
          return '';
        }
      }
    },
    {
      field: 'utmTerm',
      headerName: 'UTM Term',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        if (row.utmTerm) {
          return row.utmTerm;
        } else {
          return '';
        }
      }
    },
    {
      field: 'utmContent',
      headerName: 'UTM Content',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        if (row.utmContent) {
          return row.utmContent;
        } else {
          return '';
        }
      }
    },
    {
      field: 'refundDate',
      headerName: 'Refund Date',
      minWidth: 200,

      valueGetter: (value, row, column, apiRef) => {
        if (
          row.refundDate &&
          (row.status === 'refunded' || row.status === 'partially_refunded')
        ) {
          const refundDate = new Date(row.refundDate?.formatted);
          return format(new Date(refundDate), 'MM/dd/yyyy HH:mm:ss');
        } else {
          return 'N/A';
        }
      }
    },
    {
      field: 'SEN_HEADSET_V1',
      headerName: 'SEN_HEADSET_V1',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        return row.SEN_HEADSET_V1;
      }
    },
    {
      field: 'SEN_HEADSET_V1.5',
      headerName: 'SEN_HEADSET_V1.5',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        return row['SEN_HEADSET_V1.5'];
      }
    },

    {
      field: 'SEN_HEADSET_OPENV1',
      headerName: 'SEN_HEADSET_OPENV1',

      minWidth: 200,

      valueGetter: (value, row, column, apiRef) => {
        return row.SEN_HEADSET_OPENV1;
      }
    },
    {
      field: 'SKU 3',
      headerName: 'SKU 3',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        return row.sku3Quantity;
      }
    },
    {
      field: 'SKU 4',
      headerName: 'SKU 4',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        return row.sku4Quantity;
      }
    },
    {
      field: 'SKU 5',
      headerName: 'SKU 5',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        return row.sku5Quantity;
      }
    },
    {
      field: 'affiliateId',
      headerName: 'Affiliate',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        return row.affiliateId;
      }
    },
    {
      field: 'lineAmout',
      headerName: 'Amount',

      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        return getDollars(row.lineAmount);
      }
    },
    {
      field: 'shippingAmount',
      headerName: 'Shipping',

      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        return getDollars(row.shippingAmount);
      }
    },
    {
      field: 'couponCode',
      headerName: 'Coupon',

      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        return row.couponCode || 'n/a';
      }
    },
    {
      field: 'tax1',
      headerName: 'Tax 1',

      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        return getDollars(row.tax1);
      }
    },
    {
      field: 'tax2',
      headerName: 'Tax 2',

      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        return getDollars(row.tax2);
      }
    },
    {
      field: 'tax3',
      headerName: 'Tax 3',

      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        return getDollars(row.tax3);
      }
    },
    {
      field: 'tax4',
      headerName: 'Tax 4',

      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        return getDollars(row.tax4);
      }
    },
    {
      field: 'tax5',
      headerName: 'Tax 5',

      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        return getDollars(row.tax5);
      }
    },

    {
      field: 'orderToRefundMinutes',
      headerName: 'Time to Refund',

      minWidth: 200,
      valueGetter: (value, row, column, apiRef) => {
        if (row.orderToRefundMinutes) {
          return row.orderToRefundMinutes <= 0
            ? 'N/A'
            : row.orderToRefundMinutes <= 60
            ? `${Math.floor(row.orderToRefundMinutes).toLocaleString()} Minutes`
            : row.orderToRefundMinutes <= 24 * 60
            ? `${Math.floor(
                row.orderToRefundMinutes / 60
              ).toLocaleString()} Hours`
            : `${Math.floor(
                row.orderToRefundMinutes / (60 * 24)
              ).toLocaleString()} Days`;
        } else {
          return 'N/A';
        }
      }
    },
    {
      field: 'Actions',
      width: 70,
      headerName: '',
      renderCell: (params) => {
        const api: GridApi = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== '__check__' && !!c);
        const thisRow = {} as {
          confirmationCode: string;
          fulfilmentStatus: string;
        };
        fields.forEach((f) => {
          thisRow[f] = params.row[f];
        });
        const order = props.data.orders.find(
          (o) => o.confirmationCode === thisRow.confirmationCode
        );

        return (
          <OrdersActionList
            handleOpenModal={handleOpenRefundModal}
            allowQueuOrder={thisRow.fulfilmentStatus === 'cancelled'}
          />
        );
      }
    }
  ];

  const [columns, setColumns] = useState<GridColDef[]>(columnConfig);

  const handleCellClick = (params) => {
    if (params.field !== '' && params.field !== 'Actions') {
      const order = props.data.orders.find(
        (o) => o.confirmationCode === params.row.confirmationCode
      );

      setOrderData(order);
    }
    if (params.field === 'Actions') {
      const order = props.data.orders.find(
        (o) => o.confirmationCode === params.row.confirmationCode
      );

      setOrderForActions(order);
    }
  };

  useEffect(() => {
    if (props.rowCount || props.rowCount === 0) {
      setRowCount(props.rowCount);
    }

    // Fetch all rows when we select all
    if (selectionModel.length === props.pageSize && props.rowCount > 0) {
      setAllSelected(false);
      props.queryOrders(0, null, true);
    }
  }, [props.data.orders, props.rowCount]);

  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    if (
      props.data.orders.length === props.rowCount &&
      props.rowCount > props.pageSize &&
      !allSelected
    ) {
      setSelectionModel(props.data.orders.map((o) => o.id));
      setAllSelected(true);
    }
  }, [props.data.orders]);

  // Set visible columns based on URL params
  useEffect(() => {
    const colParams = [];
    for (const [key, value] of searchParams.entries()) {
      if (key.startsWith('col_')) {
        colParams.push({ field: key.replace('col_', ''), isVisible: value });
      }
    }

    if (googleAudienceExport) {
      setColumns((columns) => {
        columnConfig = columns;
        return audienceColumnConifg;
      });
      setVisibleColumns({
        country: true,
        firstName: true,
        lastName: true,
        phone: true,
        zip: true,
        email: true
      });
      return;
    }
    setVisibleColumns(columnVisibility);
    setColumns((columns) => {
      columnConfig = columnConfig.map((column) => {
        if (colParams.some((c) => c.field === column.field)) {
          return { ...column, hide: false };
        } else {
          return column;
        }
      });
      return columnConfig;
    });
  }, [googleAudienceExport]);

  return (
    <>
      <div>
        <UserOrderRefundModal
          modalOpen={refundModalOpen}
          currentOrder={orderForActions}
          handleCloseModal={handleCloseModal}
        />
        <ConfirmModal
          handleConfirm={async () => {
            executeReQueueOrder({
              variables: {
                id: orderForActions.paymentIntentId
              }
            });
          }}
          closeConfirmModal={handleCloseModal}
          confirmModalOpen={requeuedModalOpen}
          handleCloseConfirm={handleCloseModal}
          heading="Queue Order for Fulfillment"
          subheading="Type 'CONFIRM' to queue this order for fulfillment"
          confirmText="CONFIRM"
        />
      </div>
      {orderData !== null && (
        <div>
          <UserOrderDetails
            currentOrder={orderData}
            handleCloseConfirm={() => {
              setOrderData(null);
            }}
          />
        </div>
      )}
      <StyledDataGrid
        columnVisibilityModel={visibleColumns}
        onColumnVisibilityModelChange={(newModel) => {
          // Add to URL params to fetch on reload
          console.log('e', newModel);

          if (newModel.isVisible) {
            setSearchParams((params) => {
              params.set(
                `col_${newModel.field}`,
                (!!newModel.isVisible).toString()
              );
              return params;
            });
          } else {
            setSearchParams((params) => {
              params.delete(`col_${newModel.field}`);
              return params;
            });
          }
          setVisibleColumns(newModel);
          if (!googleAudienceExport) {
            columnVisibility = newModel;
          }
        }}
        scrollbarSize={10}
        autoHeight
        rows={props.data.orders}
        loading={props.loading}
        columns={columns}
        paginationModel={{
          pageSize: props.pageSize,
          page: props.currentPage || 0
        }}
        pageSizeOptions={[props.pageSize]}
        onCellClick={handleCellClick}
        paginationMode="server"
        disableColumnFilter
        pagination={true}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{ toolbar: OrdersListToolbar }}
        slotProps={{
          toolbar: {
            csvOptions: {},
            selectedOrders,
            mergeOrderData: props.mergeOrderData,
            queryOrders: props.queryOrders,
            rowCount,
            setGoogleAudienceExport: setGoogleAudienceExport,
            googleAudienceExport: googleAudienceExport
          }
        }}
        getRowClassName={(params) => {
          return params.row.shippingAddressValidationFlag ? 'warn' : 'clear';
        }}
        rowCount={rowCount}
        onPaginationModelChange={props.handleOnPageChange}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(ids) => {
          setSelectionModel(ids);

          const selectedIDs = new Set(ids);
          const selectedRowData = props.data.orders.filter((row) =>
            selectedIDs.has(row.id)
          );
          setSelectedOrders(selectedRowData);
        }}
      />
    </>
  );
};

export default OrdersListDataGrid;
