import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useAuthState } from 'src/contexts/authContext';

const NotLoggedIn = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const authState = useAuthState();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!authState.isLoggedIn()) {
        navigate('/login');
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px'
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? 'h4' : 'h1'}
          >
            You must be logged in to access this resource
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 6
            }}
          >
            <Box sx={{ mb: 3 }}>
              <CircularProgress />
            </Box>
            <Button
              color="primary"
              component={RouterLink}
              to="/login"
              variant="outlined"
            >
              Login Here
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NotLoggedIn;
