import { useState } from 'react';
import { Modal } from '@mui/material';

import { ROLES, useAuthState } from 'src/contexts/authContext';
import UserOrderRefundDetails from 'src/components/dashboard/orders/UserOrderRefundDetails';

interface Props {
  modalOpen: boolean;
  handleCloseModal: () => void;
  currentOrder: any;
}

const UserOrderRefundModal = ({
  modalOpen,
  handleCloseModal,
  currentOrder
}: Props) => {
  const authState = useAuthState();
  const [errorMessage, setErrorMessage] = useState('');

  return authState.minAuthLevelMet(ROLES.CSA_MANAGER) ? (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-user-order-refund"
        aria-describedby="modal-user-order-refund"
      >
        <div>
          <UserOrderRefundDetails
            modalOpen={modalOpen}
            currentOrder={currentOrder}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            email={''}
            closeDetailsModal={handleCloseModal}
          />
        </div>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default UserOrderRefundModal;
