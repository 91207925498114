import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import useSearchUser from 'src/hooks/useSearchUser';
import UserSearch from 'src/components/dashboard/users/UserSearch';
import UserDetails from '../../components/dashboard/users/UserDetails';
import { useMutation } from '@apollo/client';
import { REFUND_INVOICE } from 'src/graphql/queries';
import { OrderData } from 'src/components/dashboard/users/memberships/UserMemberships';
import { RefundReason } from 'src/components/dashboard/orders/UserOrderRefundDetails';
import UserInvoiceRefundModal from 'src/components/dashboard/users/UserInvoiceRefundModal';
import { Formik } from 'formik';
import UserExpiredMembership from 'src/components/dashboard/users/memberships/UserExpiredMembership';

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: {
    formatted: string;
  };
};

interface InvoiceRefundProperties {
  id: string;
  provider: string;
  reason: RefundReason;
  notes: string;
}

const UsersDetails = () => {
  const { settings } = useSettings();
  const navigate = useNavigate();
  const [
    handleSearchUser,
    executeGetUser,
    userLoading,
    userData,
    userError,
    usersLoading,
    usersData,
    usersError
  ] = useSearchUser();
  const [modalOpen, setModalOpen] = useState(false);
  const [noUsersFound, setNoUsersFound] = useState(false);
  const [invoiceRefundModalOpen, setInvoiceRefundModalOpen] = useState(false);
  const [invoiceRefundProperties, setInvoiceRefundProperties] =
    useState<InvoiceRefundProperties>({
      id: '',
      provider: '',
      reason: 'none',
      notes: ''
    });
  const [expiredMembershipData, setExpiredMembershipData] =
    useState<OrderData>(null);

  const [expiredMembershipModalOpen, setExpiredMembershipModalOpen] =
    useState(false);

  const [
    executeRefundInvoice,
    {
      loading: refundInvoiceLoading,
      data: refundInvoiceData,
      error: refundInvoiceError
    }
  ] = useMutation(REFUND_INVOICE);

  useEffect(() => {
    if (userData && userData.User) {
      navigate(`/dashboard/users/${userData.User.email}`);
    }
  }, [userData]);

  useEffect(() => {
    setNoUsersFound(false);

    if (usersData?.Users && usersData?.Users.length > 0) {
      setModalOpen(true);
    }
  }, [usersData]);

  const handleCloseModal = () => setModalOpen(false);

  const tableRowStyles = {
    '&.MuiTableRow-root:hover': {
      cursor: 'pointer',
      backgroundColor: 'gray'
    }
  };
  const modalStyles = {
    position: 'absolute',
    top: '10%',
    left: '10%',
    right: '10%',
    bottom: '10%',
    overflow: 'scroll',
    display: 'block',
    '& .MuiPaper-root': {
      outline: 'none'
    }
  };

  const membershipModalStyles = {
    ...modalStyles,
    overflow: 'hidden'
  };

  const handleClickUserRow = (user: User) => {
    executeGetUser({
      variables: {
        email: user.email,
        fetchPolicy: 'no-cache'
      }
    });
    handleCloseModal();
  };

  const handleClickInvoiceRefund = (
    id: string,
    provider: 'google' | 'stripe'
  ) => {
    setInvoiceRefundModalOpen(true);
    setInvoiceRefundProperties({ id, provider, reason: 'none', notes: '' }); // TODO: UPDATE
  };

  const handleConfirmInvoiceRefund = async (values: {
    refundReason: string;
    refundNotes: string;
    revoke: Boolean;
  }) => {
    executeRefundInvoice({
      variables: {
        providerInvoiceId: invoiceRefundProperties.id,
        provider: invoiceRefundProperties.provider,
        revoke: values.revoke,
        refundReason: values.refundReason,
        refundNotes: values.refundNotes
      }
    });
  };

  const handleClickExpiredMembership = (membership: OrderData) => {
    setExpiredMembershipModalOpen(true);
    setExpiredMembershipData(membership);
  };

  // Refetch user data after refunding invoice to display updated status
  useEffect(() => {
    if (refundInvoiceData) {
      executeGetUser({
        variables: {
          email: userData?.User?.email,
          fetchPolicy: 'no-cache'
        }
      });
    }
  }, [refundInvoiceData]);

  // TODO: Add Formik to UserInvoiceRefundModal

  return (
    <>
      <Helmet>
        <title>Dashboard: User Details | Sens.ai</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Formik
          initialValues={{
            refundReason: 'other',
            refundNotes: '',
            revoke: false
          }}
          onSubmit={() => {}}
        >
          {({ errors, handleBlur, handleChange, touched, values }) => {
            return (
              <UserInvoiceRefundModal
                handleConfirm={() => handleConfirmInvoiceRefund(values)}
                closeConfirmModal={() => setInvoiceRefundModalOpen(false)}
                confirmModalOpen={invoiceRefundModalOpen}
                handleCloseConfirm={() => setInvoiceRefundModalOpen(false)}
                heading={`Confirm Issue Refund`}
                subheading="Type 'REFUND' to issue refund"
                confirmText="REFUND"
                handleBlur={handleBlur}
                handleChange={handleChange}
                reason={values.refundReason}
                notes={values.refundNotes}
                revoke={values.revoke}
              />
            );
          }}
        </Formik>

        {expiredMembershipData && (
          <Modal
            open={expiredMembershipModalOpen}
            onClose={() => setExpiredMembershipModalOpen(false)}
            sx={membershipModalStyles}
          >
            <Card>
              <CardContent>
                <UserExpiredMembership
                  expiredMembership={expiredMembershipData}
                />
              </CardContent>
            </Card>
          </Modal>
        )}
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-users-list"
          aria-describedby="modal-users-list"
          sx={modalStyles}
        >
          <Card>
            <CardContent>
              <Typography color="textSecondary" variant="overline">
                Choose a User
              </Typography>

              <TableContainer component={Paper} sx={{ mt: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">ID</TableCell>
                      <TableCell align="left">First Name</TableCell>
                      <TableCell align="left">Last Name</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Date of Birth</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersData?.Users &&
                      usersData.Users.map((user: User) => (
                        <TableRow
                          key={user.email}
                          sx={tableRowStyles}
                          onClick={() => handleClickUserRow(user)}
                        >
                          <TableCell align="left">{user.id}</TableCell>
                          <TableCell align="left">{user.firstName}</TableCell>
                          <TableCell align="left">{user.lastName}</TableCell>
                          <TableCell align="left">{user.email}</TableCell>
                          <TableCell align="left">
                            {user.dateOfBirth.formatted ===
                            '1700-01-01T00:00:00.000Z'
                              ? 'Not set'
                              : user.dateOfBirth.formatted}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Modal>

        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserSearch
                handleFormSubmit={handleSearchUser}
                userData={userData}
                userLoading={userLoading}
                userError={userError}
                usersData={usersData}
                usersLoading={usersLoading}
                usersError={usersError}
              />
            </Grid>
            <Grid item xs={12}>
              <UserDetails
                executeGetUser={executeGetUser}
                userData={userData}
                userLoading={userLoading}
                userError={userError}
                usersLoading={usersLoading}
                usersData={usersData}
                handleClickInvoiceRefund={handleClickInvoiceRefund}
                refundInvoiceLoading={refundInvoiceLoading}
                handleClickExpiredMembership={handleClickExpiredMembership}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default UsersDetails;
