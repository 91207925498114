import { useState } from 'react';
import React from 'react';
import { Button, Menu, MenuItem, IconButton } from '@mui/material';
import { ROLES, useAuthState } from 'src/contexts/authContext';
import { gql, useMutation } from '@apollo/client';
import { MoreVertical } from 'react-feather';

const SIM_WEBHOOK = gql`
  mutation TriggerWebhook(
    $hook: Webhook!
    $path: String!
    $paymentIntentId: String
    $stripeStatus: String
    $fulfilmentId: Int
    $fulfilmentStatus: String
    $orderId: String
  ) {
    TriggerWebhook(
      input: {
        webhook: $hook
        path: $path
        paymentIntentId: $paymentIntentId
        stripeStatus: $stripeStatus
        fulfilmentId: $fulfilmentId
        fulfilmentStatus: $fulfilmentStatus
        orderId: $orderId
      }
    )
  }
`;

interface Props {
  handleOpenModal: (type: 'REFUND' | 'QUEUE_FULFILLMENT') => void;
  allowQueuOrder: boolean;
}

const OrdersActionList = ({ handleOpenModal, allowQueuOrder }: Props) => {
  const authState = useAuthState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return authState.minAuthLevelMet(ROLES.CSA_MANAGER) ? (
    <>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertical />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              width: '20ch'
            }
          }}
        >
          <MenuItem key="refund" onClick={handleCloseMenu}>
            <Button
              onClick={() => {
                handleOpenModal('REFUND');
              }}
            >
              Refund
            </Button>
          </MenuItem>
          {allowQueuOrder && (
            <MenuItem key="queueFulfillment" onClick={handleCloseMenu}>
              <Button
                onClick={() => {
                  handleOpenModal('QUEUE_FULFILLMENT');
                }}
              >
                Requeue Order
              </Button>
            </MenuItem>
          )}
        </Menu>
      </div>
    </>
  ) : (
    <></>
  );
};

export default OrdersActionList;
