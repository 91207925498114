import { FC, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CircularProgress,
  TextField,
  CardContent,
  CardActions,
  MenuItem,
  FormHelperText
} from '@mui/material';
import Button from '@mui/material/Button';
import useSettings from '../../hooks/useSettings';
import { useMutation, gql } from '@apollo/client';
import { Formik, Form } from 'formik';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import { getCountryItems } from 'src/components/dashboard/orders/OrdersListForm';

const IMPORT_ORDER = gql`
  mutation ImportOrder($input: ImportOrderInput!) {
    ImportOrder(input: $input) {
      id
      confirmationCode
      status
    }
  }
`;

const SKUs = [
  { sku: 'SEN_HEADSET_V1', name: 'Sens.ai Headset' },
  { sku: 'SEN_HEADSET_OPENV1', name: 'Sens.ai Headset (Open Box)' },
  { sku: 'SEN_HEADSET_REFURBV1', name: 'Sens.ai Headset (Refurbished)' },
  { sku: 'SEN_SNSRPEN_PK3', name: 'Water Pen 3pk' },
  { sku: 'SNSR_KIT1', name: 'Sensor Kit (4pc)' },
  { sku: 'SEN_PARTS_PSU', name: 'Adapter PSU set' }
];

const VAT_REQUIRED_COUNTRIES = [
  'Switzerland',
  'Sweden',
  'Norway',
  'Germany',
  'United Kingdom'
];

const OrderImports: FC = () => {
  const [importOrder, { loading, data, error }] = useMutation(IMPORT_ORDER, {
    errorPolicy: 'all'
  });
  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>Dashboard: Order Imports | Sens.ai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Typography color="textSecondary" variant="overline">
                    Order Imports
                  </Typography>
                </Grid>
              </Grid>

              <Formik
                initialValues={{
                  id: '',
                  firstName: '',
                  lastName: '',
                  email: '',
                  shippingPhone: '',
                  shippingAddress: '',
                  shippingUnit: '',
                  shippingCity: '',
                  shippingProv: '',
                  shippingPostal: '',
                  shippingCountry: '',
                  shippingVat: '',
                  itemName: SKUs[0].name,
                  itemSku: SKUs[0].sku,
                  itemQuantity: 1,
                  itemPrice: 0.0
                }}
                validationSchema={Yup.object().shape({
                  id: Yup.string().min(1).required('ID is required'),
                  firstName: Yup.string()
                    .min(1)
                    .required('First Name is required'),
                  lastName: Yup.string()
                    .min(1)
                    .required('Last Name is required'),
                  email: Yup.string()
                    .email('Invalid email')
                    .required('Email is required'),
                  shippingPhone: Yup.number()
                    .positive()
                    .integer()
                    .min(10, 'Phone number must be at least 10 digits')
                    .required('Phone number is required'),
                  shippingAddress: Yup.string()
                    .min(1)
                    .required('Street Address is required'),
                  shippingCity: Yup.string()
                    .min(1)
                    .required('City is required'),
                  shippingProv: Yup.string()
                    .min(1)
                    .required('Province is required'),
                  shippingPostal: Yup.string()
                    .min(1)
                    .required('Postal Code is required'),
                  shippingCountry: Yup.string()
                    .min(1)
                    .required('Country is required'),
                  // itemName: Yup.string()
                  //   .min(1)
                  //   .required('Item Name is required'),
                  itemSku: Yup.string().min(1).required('SKU is required'),
                  itemQuantity: Yup.number()
                    .positive()
                    .min(1)
                    .required('Quantity is required'),
                  itemPrice: Yup.number().min(0).required('Price is required')
                })}
                onSubmit={(values, actions) => {
                  importOrder({
                    variables: {
                      input: {
                        id: values.id,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        shippingPhone: values.shippingPhone.toString(),
                        shippingAddress: values.shippingAddress,
                        shippingUnit: values.shippingUnit,
                        shippingCity: values.shippingCity,
                        shippingProv: values.shippingProv,
                        shippingPostal: values.shippingPostal,
                        shippingCountry: values.shippingCountry,
                        items: [
                          {
                            // name: values.itemName,
                            sku: values.itemSku,
                            quantity: parseInt(
                              values.itemQuantity.toString(),
                              10
                            ),
                            price: parseFloat(values.itemPrice.toString())
                          }
                        ]
                      }
                    }
                  });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                  values,
                  setFieldValue
                }) => {
                  return (
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item>
                          <TextField
                            required
                            label="Transaction ID"
                            name="id"
                            variant="outlined"
                            disabled={false}
                            value={values.id}
                            error={Boolean(errors.id && touched.id)}
                            helperText={errors.id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            label="First Name"
                            name="firstName"
                            variant="outlined"
                            disabled={false}
                            value={values.firstName}
                            error={Boolean(
                              errors.firstName && touched.firstName
                            )}
                            helperText={errors.firstName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            label="Last Name"
                            name="lastName"
                            variant="outlined"
                            disabled={false}
                            value={values.lastName}
                            error={Boolean(errors.lastName && touched.lastName)}
                            helperText={errors.lastName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            type='"email'
                            required
                            label="Email"
                            name="email"
                            variant="outlined"
                            disabled={false}
                            value={values.email}
                            error={Boolean(errors.email && touched.email)}
                            helperText={errors.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            label="Street Address"
                            name="shippingAddress"
                            variant="outlined"
                            disabled={false}
                            value={values.shippingAddress}
                            error={Boolean(
                              errors.shippingAddress && touched.shippingAddress
                            )}
                            helperText={errors.shippingAddress}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label="Unit"
                            name="shippingUnit"
                            variant="outlined"
                            disabled={false}
                            value={values.shippingUnit}
                            error={Boolean(
                              errors.shippingUnit && touched.shippingUnit
                            )}
                            helperText={errors.shippingUnit}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            label="City"
                            name="shippingCity"
                            variant="outlined"
                            disabled={false}
                            value={values.shippingCity}
                            error={Boolean(
                              errors.shippingCity && touched.shippingCity
                            )}
                            helperText={errors.shippingCity}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            label="Province / State"
                            name="shippingProv"
                            variant="outlined"
                            disabled={false}
                            value={values.shippingProv}
                            error={Boolean(
                              errors.shippingProv && touched.shippingProv
                            )}
                            helperText={errors.shippingProv}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            select
                            label="Country"
                            name="shippingCountry"
                            variant="outlined"
                            disabled={false}
                            value={values.shippingCountry}
                            error={Boolean(
                              errors.shippingCountry && touched.shippingCountry
                            )}
                            helperText={errors.shippingCountry}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            sx={{ minWidth: '12.8rem' }}
                          >
                            {getCountryItems()}
                          </TextField>
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            label="Postal / Zip Code"
                            name="shippingPostal"
                            variant="outlined"
                            disabled={false}
                            value={values.shippingPostal}
                            error={Boolean(
                              errors.shippingPostal && touched.shippingPostal
                            )}
                            helperText={errors.shippingPostal}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            label="Phone Number"
                            name="shippingPhone"
                            variant="outlined"
                            disabled={false}
                            value={values.shippingPhone}
                            error={Boolean(
                              errors.shippingPhone && touched.shippingPhone
                            )}
                            helperText={errors.shippingPhone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item>
                          <TextField
                            required
                            select
                            defaultValue="SEN_HEADSET_V1"
                            label="Item SKU"
                            name="itemSku"
                            variant="outlined"
                            disabled={false}
                            value={values.itemSku}
                            error={Boolean(errors.itemSku && touched.itemSku)}
                            helperText={errors.itemSku}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            sx={{ minWidth: '12.8rem' }}
                          >
                            {SKUs.map((item) => (
                              <MenuItem key={item.sku} value={item.sku}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            label="Item Quantity"
                            name="itemQuantity"
                            variant="outlined"
                            disabled={false}
                            value={values.itemQuantity}
                            error={Boolean(
                              errors.itemQuantity && touched.itemQuantity
                            )}
                            helperText={errors.itemQuantity}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            label="Item Price (USD)"
                            name="itemPrice"
                            variant="outlined"
                            disabled={false}
                            value={values.itemPrice}
                            error={Boolean(
                              errors.itemPrice && touched.itemPrice
                            )}
                            helperText={errors.itemPrice}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        {VAT_REQUIRED_COUNTRIES.includes(
                          values.shippingCountry
                        ) && (
                          <Grid item>
                            <TextField
                              required
                              label="VAT Number"
                              name="shippingVat"
                              variant="outlined"
                              value={values.shippingVat}
                              error={Boolean(
                                errors.shippingVat && touched.shippingVat
                              )}
                              helperText={errors.shippingVat}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </Grid>
                        )}
                      </Grid>

                      <CardActions>
                        <Grid container>
                          <Grid item xs={12}>
                            {!loading && (
                              <Button
                                color="primary"
                                endIcon={<ArrowRightIcon fontSize="small" />}
                                variant="text"
                                type="submit"
                                disabled={loading}
                              >
                                Import Order
                              </Button>
                            )}

                            {loading && (
                              <Box sx={{ mt: 3 }}>
                                <CircularProgress />
                              </Box>
                            )}
                          </Grid>

                          {data?.ImportOrder && (
                            <Grid item xs={12}>
                              <Box sx={{ mt: 1, ml: 1 }}>
                                <FormHelperText>
                                  {`Order successfully imported! Confirmation code: ${data?.ImportOrder?.confirmationCode}}`}
                                </FormHelperText>
                              </Box>
                            </Grid>
                          )}
                          {error && (
                            <Grid item xs={12}>
                              <Box sx={{ mt: 3 }}>
                                <FormHelperText error>
                                  {error.message}
                                </FormHelperText>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </CardActions>
                    </Form>
                  );
                }}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default OrderImports;
