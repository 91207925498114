import { FC, ReactNode, useEffect, useState } from 'react';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { Button, Tooltip } from '@mui/material';
import ConfirmModal from 'src/components/shared/ConfirmModal';
import { ROLES, useAuthState } from 'src/contexts/authContext';
import { useMutation, gql } from '@apollo/client';
import { OrderData } from './UserOrders';
import UpdateWarehouseModal from './UpdateWarehouseModal';

export const ROW_EXPORT_LIMIT = 1500;
const EXPORT_LIMIT_MESSAGE = `You cannot export more than ${ROW_EXPORT_LIMIT} rows. Please filter your results and try again.`;

type Props = {
  children?: ReactNode;
  selectedOrders?: any[];
  mergeOrderData: (orders: OrderData[]) => void;
  queryOrders: (page: number, query?: any, getAllPages?: boolean) => void;
  rowCount: number;
  setGoogleAudienceExport: (vallue: boolean) => void;
  googleAudienceExport: boolean;
};

const FULFILL_ORDER = gql`
  mutation FulfillQueuedOrder($input: OrderIdInput!) {
    FulfillQueuedOrder(input: $input) {
      id
      fulfilmentStatus
      fulfilmentId
    }
  }
`;

const SEND_ADDRESS_CONFIRM_REQ = gql`
  mutation SendAddressConfirmationRequestForOrder($input: OrderIdInput!) {
    SendAddressConfirmationRequestForOrder(input: $input)
  }
`;

const OrdersListToolbar: FC = (props: Props) => {
  const authState = useAuthState();

  const [fulfillOrder, { loading, data, error }] = useMutation(FULFILL_ORDER, {
    errorPolicy: 'all'
  });

  const [
    sendConfirmAddressReq,
    { loading: loadingAdd, data: dataAdd, error: errorAdd }
  ] = useMutation(SEND_ADDRESS_CONFIRM_REQ, {
    errorPolicy: 'all'
  });

  const [action, setAction] = useState<
    'CONFIRM_ADDRESS_REQ' | 'FULFILL' | 'UPDATE_WAREHOUSE'
  >('CONFIRM_ADDRESS_REQ');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [oldestOrderAddress, setOldestOrderAddress] = useState(0);
  const [numberOfOrdersToFulfill, setNumberOfOrdersToFulfill] = useState(0);
  const [numberOfOrdersToSelected, setNumberOfOrdersToSelected] = useState(0);

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setOrders(
      props.selectedOrders?.filter(
        (o) =>
          o.fulfilmentStatus === 'QUEUED_BACKORDER' &&
          (o.status === 'succeeded' || o.status === 'partially_refunded')
      ) ?? []
    );
  }, [props.selectedOrders]);

  const handleCloseConfirm = () => setConfirmModalOpen(false);

  const handleConfirmAddressReq = async () => {
    console.log('SENDING');
    for (let i = 0; i < props.selectedOrders.length; i++) {
      await sendConfirmAddressReq({
        variables: {
          input: {
            orderId: props.selectedOrders[i].id
          }
        }
      });
      //let's put a little gap between each one
      await new Promise((r) => setTimeout(r, 200));
    }
  };

  const handleConfirmFulfill = async () => {
    console.log('SUBMITTING');
    for (let i = 0; i < orders.length; i++) {
      await fulfillOrder({
        variables: {
          input: {
            orderId: orders[i].id
          }
        }
      });
      //let's put a little gap between each one
      await new Promise((r) => setTimeout(r, 200));
    }
  };

  useEffect(() => {
    if (data) {
      const res = data.FulfillQueuedOrder;

      const update = orders.find((x) => x.id === res.id);
      if (update) {
        const updateList = [
          {
            ...update,
            fulfilmentStatus: res.fulfilmentStatus,
            fulfilmentId: res.fulfilmentId
          },
          ...orders.filter((x) => x.id !== res.id)
        ];
        setOrders(updateList);
      }
    }
    if (error) {
      console.log('error', error);
      alert(error.message);
      // TODO: need a way to get the row (so pass back "paymentIntentId") then find and update "import_status"
    }
  }, [data, error]);

  useEffect(() => {
    //call the parent to merge the orders
    props.mergeOrderData(orders);
  }, [orders]);

  const updateWarehouse = () => {
    setAction('UPDATE_WAREHOUSE');
    setConfirmModalOpen(true);
  };

  const fulfillSelected = () => {
    let oldestAddress: Date;
    orders.forEach((o) => {
      const addressUpdated = new Date(o.shippingAddress?.updated.formatted);
      if (
        !oldestAddress ||
        addressUpdated.getTime() < oldestAddress.getTime()
      ) {
        oldestAddress = addressUpdated;
      }
    });

    const today = new Date();
    const difference = today.getTime() - oldestAddress.getTime();
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    setAction('FULFILL');
    setOldestOrderAddress(TotalDays);
    setNumberOfOrdersToSelected(props.selectedOrders?.length ?? 0);
    setNumberOfOrdersToFulfill(orders.length);

    setConfirmModalOpen(true);

    // console.log(TotalDays, orders);
  };

  const confirmAddressReqSelected = () => {
    setAction('CONFIRM_ADDRESS_REQ');

    setConfirmModalOpen(true);
  };

  return (
    <>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Tooltip
          title={props.rowCount > ROW_EXPORT_LIMIT ? EXPORT_LIMIT_MESSAGE : ''}
        >
          <span>
            <GridToolbarExport
              csvOptions={{ allColumns: true }}
              disabled={props.rowCount > ROW_EXPORT_LIMIT}
            />
          </span>
        </Tooltip>
        <p>&emsp;</p>
        <p>&emsp;</p>

        <Button
          size="small"
          onClick={() =>
            props.setGoogleAudienceExport(!props.googleAudienceExport)
          }
        >
          {props.googleAudienceExport
            ? 'Audience Export Enabled'
            : 'Audience Export Disabled'}
        </Button>

        <p>&emsp;</p>
        <p>&emsp;</p>
        {authState.minAuthLevelMet(ROLES.CSA_ADMIN) && (
          <Button
            size="small"
            disabled={
              !props.selectedOrders ||
              props.selectedOrders.length === 0 ||
              loadingAdd
            }
            onClick={confirmAddressReqSelected}
          >
            Request Address Confirmation
          </Button>
        )}
        <p>&emsp;</p>
        <p>&emsp;</p>
        {authState.minAuthLevelMet(ROLES.CSA_MANAGER) && (
          <Button
            size="small"
            disabled={orders.length === 0 || loading}
            onClick={fulfillSelected}
          >
            Fulfill Selected
          </Button>
        )}
        <p>&emsp;</p>
        <p>&emsp;</p>
        {authState.minAuthLevelMet(ROLES.CSA_ADMIN) && (
          <Button
            size="small"
            disabled={orders.length === 0 || loading}
            onClick={updateWarehouse}
          >
            Update Warehouse
          </Button>
        )}
      </GridToolbarContainer>
      <ConfirmModal
        handleConfirm={
          action === 'FULFILL' ? handleConfirmFulfill : handleConfirmAddressReq
        }
        closeConfirmModal={handleCloseConfirm}
        confirmModalOpen={confirmModalOpen && action !== 'UPDATE_WAREHOUSE'}
        handleCloseConfirm={handleCloseConfirm}
        heading={
          action === 'FULFILL'
            ? `Fulfill Orders`
            : `Send Address Confirmation Request`
        }
        subheading={
          action === 'FULFILL'
            ? `${numberOfOrdersToFulfill} of ${numberOfOrdersToSelected} selected orders`
            : ''
        }
        bodyText={
          action === 'FULFILL'
            ? `Please note that the oldest shipping address is ${oldestOrderAddress} days old. Type 'FULFILL' below to release these orders.`
            : `Type 'CONFIRM' below to send the requests.`
        }
        confirmText={action === 'FULFILL' ? 'FULFILL' : 'CONFIRM'}
      />
      <UpdateWarehouseModal
        orders={orders}
        handleCloseConfirm={handleCloseConfirm}
        closeConfirmModal={handleCloseConfirm}
        confirmModalOpen={confirmModalOpen && action === 'UPDATE_WAREHOUSE'}
      />
    </>
  );
};

export default OrdersListToolbar;
