import { FC, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Card,
  CircularProgress,
  LinearProgress,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  experimentalStyled,
  Modal,
  Paper,
  Tooltip,
  Tab,
  Tabs
} from '@mui/material';
import { useLazyQuery, gql } from '@apollo/client';
import { TabPanel } from '../../shared/TabPanel';

export type UnlockData = {
  expPoints: number;
  features: [FeatureData];
  unlockProgress: [
    {
      numComplete: number;
      complete: boolean;
      minSecureProgressMet: boolean;
      requiredProtocolIds: [string];
      key: {
        numRequired: number;
        minSecureProgress: number;
        maxGapHours: number;
        requiredProtocolIds: [string];
        lock: {
          id: string;
          name: string;
          type: string;
          restrictedProtocolIds: [string];
          restrictedMissionIds: [string];
          restrictedFeatureIds: [string];
        };
      };
    }
  ];
};

type RequiredId = {
  id: string;
  name: string;
};

type UnlockDataItem = {
  id: string;
  name: string;
  type: string;
  complete: boolean;
  minSecureProgressMet: boolean;
  requiredProtocolIds: {
    ids: [RequiredId];
    numComplete: number;
    numRequired: number;
  }[];
  restrictedProtocolIds: [string];
  restrictedMissionIds: [string];
  restrictedFeatureIds: [string];
};

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export type FeatureData = {
  id: string;
  name: string;
  numExpRequired: number;
  restrictedByLock: boolean;
};

export type UnlockInfoData = {
  Feature: [FeatureData];
  Mission: [
    {
      id: string;
      name: string;
    }
  ];
  Protocol: [
    {
      id: string;
      name: string;
    }
  ];
};

export const GET_UNLOCK_INFO = gql`
  query {
    Feature {
      id
      name
      numExpRequired
      restrictedByLock
    }
    Mission {
      id
      name
    }
    Protocol {
      id
      name
    }
  }
`;

export const GET_USER_UNLOCKS = gql`
  query GetUser($email: String!) {
    User(email: $email) {
      expPoints
      features(filter: { restrictedByLock: false }) {
        id
        name
        numExpRequired
        restrictedByLock
      }
      unlockProgress {
        numComplete
        complete
        minSecureProgressMet
        requiredProtocolIds
        key {
          numRequired
          minSecureProgress
          maxGapHours
          requiredProtocolIds
          lock {
            id
            name
            type
            restrictedProtocolIds
            restrictedMissionIds
            restrictedFeatureIds
          }
        }
      }
    }
  }
`;

const StyledTable = experimentalStyled(Table)({
  minWidth: 650
});

const enum FeatureType {
  TRAINING = 0,
  BOOST = 1,
  MISSION = 2,
  MEDITATION = 3,
  TITLE = 4,
  METRIC = 5,
  FEATURE = 6
}

type Props = {
  loading: boolean;
  user: any;
};

const idToFeatureType = (id: string): FeatureType => {
  if (id.startsWith('FBK-')) {
    return FeatureType.TRAINING;
  } else if (id.startsWith('PBM-')) {
    return FeatureType.BOOST;
  } else if (id.startsWith('MISSION_')) {
    return FeatureType.MISSION;
  } else if (id.startsWith('AG-')) {
    return FeatureType.MEDITATION;
  } else if (!id.startsWith('AG-') && id.endsWith('_TITLE')) {
    return FeatureType.TITLE;
  } else if (!id.startsWith('AG-') && id.endsWith('_DATA')) {
    return FeatureType.METRIC;
  } else {
    return FeatureType.FEATURE;
  }
};

const getUnlockFeatures = (
  type: FeatureType,
  loadingUnlocks: boolean,
  loadingUnlockInfo: boolean,
  dataUnlocks: any,
  dataUnlockInfo: any
) => {
  let featuresXP: FeatureData[];

  const unlockIdMap: {
    [key: string]: string;
  } = {};

  const featureMap: {
    [key: string]: {
      name: string;
      numExpRequired: number;
      restrictedByLock: boolean;
    };
  } = {};
  const unlockMap: {
    [key: string]: UnlockDataItem;
  } = {};

  if (!loadingUnlocks && !loadingUnlockInfo && dataUnlocks && dataUnlockInfo) {
    if (dataUnlockInfo.Feature) {
      dataUnlockInfo.Feature?.forEach((feature) => {
        featureMap[feature.id] = {
          name: feature.name,
          numExpRequired: feature.numExpRequired,
          restrictedByLock: feature.restrictedByLock
        };
        unlockIdMap[feature.id] = feature.name;
      });
    }
    if (dataUnlockInfo.Mission) {
      dataUnlockInfo.Mission?.forEach((mission) => {
        unlockIdMap[mission.id] = mission.name;
      });
    }
    if (dataUnlockInfo.Protocol) {
      dataUnlockInfo.Protocol?.forEach((protocol) => {
        unlockIdMap[protocol.id] = protocol.name;
      });
    }

    if (dataUnlocks?.unlockProgress) {
      dataUnlocks?.unlockProgress.forEach((unlock) => {
        if (unlockMap[unlock?.key?.lock.id] === undefined) {
          unlockMap[unlock?.key?.lock.id] = {
            id: unlock?.key?.lock.id,
            name: unlock?.key?.lock.name,
            type: unlock?.key?.lock.type,
            complete: unlock?.complete,
            minSecureProgressMet: unlock?.minSecureProgressMet,
            requiredProtocolIds: [
              {
                ids: unlock?.key?.requiredProtocolIds.map(
                  (requiredProtocolId) => {
                    return {
                      id: requiredProtocolId,
                      name:
                        unlockIdMap[requiredProtocolId] ?? requiredProtocolId
                    };
                  }
                ),
                numComplete: unlock?.numComplete,
                numRequired: unlock?.key?.numRequired
              }
            ],
            restrictedProtocolIds: unlock?.key?.lock.restrictedProtocolIds,
            restrictedMissionIds: unlock?.key?.lock.restrictedMissionIds,
            restrictedFeatureIds: unlock?.key?.lock.restrictedFeatureIds
          };
        } else {
          unlockMap[unlock?.key?.lock.id].requiredProtocolIds = [
            ...unlockMap[unlock?.key?.lock.id].requiredProtocolIds,
            {
              ids: unlock?.key?.requiredProtocolIds.map(
                (requiredProtocolId) => {
                  return {
                    id: requiredProtocolId,
                    name: unlockIdMap[requiredProtocolId] ?? requiredProtocolId
                  };
                }
              ),
              numComplete: unlock?.numComplete,
              numRequired: unlock?.key?.numRequired
            }
          ];
        }
      });
    }

    if (dataUnlocks?.features && dataUnlockInfo && dataUnlockInfo.Feature) {
      featuresXP = dataUnlockInfo.Feature.filter(
        (feature) =>
          dataUnlocks?.features?.every(
            (feature2) => feature2.id !== feature.id
          ) && !feature.restrictedByLock
      );
    } else {
      featuresXP = [];
    }
  }

  const buildModalTabContent = (unlock: UnlockDataItem) => (
    <>
      <TableCell align="left">
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {unlock.requiredProtocolIds.map((requiredProtocolId, i) => {
            return (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    pr: 1,
                    position: 'relative'
                  }}
                >
                  <Box sx={{ display: 'relative' }} component="span">
                    <LinearProgress
                      sx={{
                        height: '20px',
                        minWidth: '150px',
                        borderRadius: '10px'
                      }}
                      variant="determinate"
                      value={clamp(
                        (requiredProtocolId.numComplete /
                          requiredProtocolId.numRequired) *
                          100.0,
                        0,
                        100
                      )}
                    />
                  </Box>
                  <Box
                    sx={{ position: 'absolute', top: 0, alignSelf: 'center' }}
                    component="span"
                  >{`${requiredProtocolId.numComplete} of ${requiredProtocolId.numRequired}`}</Box>
                  <Box sx={{ pt: '4px', alignSelf: 'center' }} component="span">
                    {[...requiredProtocolId.ids]
                      .sort((a, b) =>
                        a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                      )
                      .map((id, i) => {
                        return (
                          <>
                            {i !== 0 && <span>, </span>}
                            <Tooltip title={id.id} placement="top-start">
                              <span style={{ cursor: 'pointer' }}>
                                {id.name}
                              </span>
                            </Tooltip>
                          </>
                        );
                      })}
                  </Box>
                </Box>
                {i < unlock.requiredProtocolIds.length - 1 ? (
                  unlock.type === 'OR' ? (
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      sx={{ display: 'inline', fontWeight: 700, px: 3 }}
                    >
                      or
                    </Typography>
                  ) : (
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      sx={{ display: 'inline', fontWeight: 700, px: 3 }}
                    >
                      +
                    </Typography>
                  )
                ) : (
                  <Box></Box>
                )}
              </>
            );
          })}
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          {unlock.requiredProtocolIds.length > 1 && (
            <>
              {unlock.type === 'OR' ? (
                <>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{
                      display: 'inline',
                      fontWeight: 700,
                      fontStyle: 'italic'
                    }}
                  >
                    any
                  </Typography>
                  <span> conditions need to be met</span>
                </>
              ) : (
                <>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{
                      display: 'inline',
                      fontWeight: 700,
                      fontStyle: 'italic'
                    }}
                  >
                    all
                  </Typography>
                  <span> conditions need to be met</span>
                </>
              )}
            </>
          )}
        </Box>
      </TableCell>
    </>
  );

  return (
    <>
      {(loadingUnlocks || loadingUnlockInfo) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {(() => {
        if (!loadingUnlocks && !loadingUnlockInfo && dataUnlocks) {
          const xpItems = featuresXP
            ? Object.values(featuresXP).filter(
                (feature) => idToFeatureType(feature.id) === type
              )
            : [];
          const unlockItems = unlockMap
            ? Object.values(unlockMap).filter((unlock) =>
                unlock.requiredProtocolIds.some(
                  (requiredProtocolId) =>
                    requiredProtocolId.numComplete <
                    requiredProtocolId.numRequired
                )
              )
            : [];

          const hasUnlockItems: boolean =
            unlockItems.some((i) =>
              i.restrictedFeatureIds.some((id) => idToFeatureType(id) === type)
            ) ||
            unlockItems.some((i) =>
              i.restrictedMissionIds.some((id) => idToFeatureType(id) === type)
            ) ||
            unlockItems.some((i) =>
              i.restrictedProtocolIds.some((id) => idToFeatureType(id) === type)
            );

          if (xpItems.length > 0 || hasUnlockItems) {
            return (
              <TableContainer component={Paper}>
                <StyledTable>
                  <TableHead></TableHead>
                  <TableBody>
                    {(() => {
                      const items: JSX.Element[] = [];
                      if (xpItems.length > 0) {
                        items.push(
                          ...xpItems
                            .sort((a: FeatureData, b: FeatureData) =>
                              type === FeatureType.TITLE ||
                              type === FeatureType.FEATURE
                                ? a.numExpRequired < b.numExpRequired
                                  ? -1
                                  : a.numExpRequired > b.numExpRequired
                                  ? 1
                                  : 0
                                : a.name < b.name
                                ? -1
                                : a.name > b.name
                                ? 1
                                : 0
                            )
                            .map((featureXP: FeatureData) => {
                              return (
                                <TableRow>
                                  <TableCell align="left">
                                    <Tooltip
                                      title={featureXP.id}
                                      placement="top-start"
                                    >
                                      <span style={{ cursor: 'pointer' }}>
                                        {featureXP.name}
                                      </span>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        pr: 1,
                                        position: 'relative'
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <Box
                                          sx={{ display: 'relative' }}
                                          component="span"
                                        >
                                          <LinearProgress
                                            sx={{
                                              height: '20px',
                                              width: '200px',
                                              borderRadius: '10px'
                                            }}
                                            variant="determinate"
                                            value={clamp(
                                              (dataUnlocks.expPoints /
                                                featureXP.numExpRequired) *
                                                100.0,
                                              0,
                                              100
                                            )}
                                          />
                                        </Box>
                                        <Box
                                          sx={{
                                            position: 'absolute',
                                            top: 0
                                          }}
                                          component="span"
                                        >{`${dataUnlocks.expPoints} / ${featureXP.numExpRequired} XP`}</Box>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              );
                            })
                        );
                      }
                      if (unlockItems.length > 0) {
                        items.push(
                          ...unlockItems
                            .sort((a: UnlockDataItem, b: UnlockDataItem) =>
                              a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                            )
                            .map((unlock: UnlockDataItem) => {
                              return (
                                <>
                                  {unlock.restrictedFeatureIds
                                    .filter(
                                      (id) => idToFeatureType(id) === type
                                    )
                                    .map((restrictedFeatureId) => {
                                      return (
                                        <TableRow>
                                          <TableCell align="left">
                                            <Tooltip
                                              title={restrictedFeatureId}
                                              placement="top-start"
                                            >
                                              <span
                                                style={{ cursor: 'pointer' }}
                                              >{`${
                                                unlockIdMap[
                                                  restrictedFeatureId
                                                ] !== undefined
                                                  ? unlockIdMap[
                                                      restrictedFeatureId
                                                    ]
                                                  : restrictedFeatureId
                                              }`}</span>
                                            </Tooltip>
                                          </TableCell>
                                          {buildModalTabContent(unlock)}
                                        </TableRow>
                                      );
                                    })}

                                  {unlock.restrictedMissionIds
                                    .filter(
                                      (id) => idToFeatureType(id) === type
                                    )
                                    .map((restrictedMissionId) => {
                                      return (
                                        <TableRow>
                                          <TableCell align="left">
                                            <Tooltip
                                              title={restrictedMissionId}
                                              placement="top-start"
                                            >
                                              <span
                                                style={{ cursor: 'pointer' }}
                                              >{`${
                                                unlockIdMap[
                                                  restrictedMissionId
                                                ] !== undefined
                                                  ? unlockIdMap[
                                                      restrictedMissionId
                                                    ]
                                                  : restrictedMissionId
                                              }`}</span>
                                            </Tooltip>
                                          </TableCell>
                                          {buildModalTabContent(unlock)}
                                        </TableRow>
                                      );
                                    })}
                                  {unlock.restrictedProtocolIds
                                    .filter(
                                      (id) => idToFeatureType(id) === type
                                    )
                                    .map((restrictedProtocolId) => {
                                      return (
                                        <TableRow>
                                          <TableCell align="left">
                                            <Tooltip
                                              title={restrictedProtocolId}
                                              placement="top-start"
                                            >
                                              <span
                                                style={{ cursor: 'pointer' }}
                                              >{`${
                                                unlockIdMap[
                                                  restrictedProtocolId
                                                ] !== undefined
                                                  ? unlockIdMap[
                                                      restrictedProtocolId
                                                    ]
                                                  : restrictedProtocolId
                                              }`}</span>
                                            </Tooltip>
                                          </TableCell>
                                          {buildModalTabContent(unlock)}
                                        </TableRow>
                                      );
                                    })}
                                </>
                              );
                            })
                        );
                      }
                      return items;
                    })()}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            );
          } else {
            return (
              <Typography color="textSecondary" variant="body2">
                All items have been unlocked
              </Typography>
            );
          }
        }
      })()}
    </>
  );
};

const UserUnlocks: FC<Props> = ({ loading, user }) => {
  const [
    executeGetUserUnlocks,
    { loading: loadingUnlocks, data: dataUnlocks, error: errorUnlocks }
  ] = useLazyQuery(GET_USER_UNLOCKS, {
    fetchPolicy: 'no-cache'
  });

  const [
    executeGetUnlockInfo,
    { loading: loadingUnlockInfo, data: dataUnlockInfo, error: errorUnlockInfo }
  ] = useLazyQuery(GET_UNLOCK_INFO, {
    fetchPolicy: 'no-cache'
  });

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const unlockProgressModal = () => (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          maxHeight: '100%',
          overflow: 'scroll'
        }}
      >
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Box sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={featureValue} onChange={handleFeatureValue}>
                <Tab label="Training Programs" />
                <Tab label="Boost Programs" />
                <Tab label="Missions" />
                <Tab label="Meditations" />
                <Tab label="Titles" />
                <Tab label="Metrics" />
                <Tab label="App Feature" />
              </Tabs>
            </Box>
            <TabPanel value={featureValue} index={FeatureType.TRAINING}>
              {getUnlockFeatures(
                FeatureType.TRAINING,
                loadingUnlocks,
                loadingUnlockInfo,
                dataUnlocks?.User,
                dataUnlockInfo
              )}
            </TabPanel>
            <TabPanel value={featureValue} index={FeatureType.BOOST}>
              {getUnlockFeatures(
                FeatureType.BOOST,
                loadingUnlocks,
                loadingUnlockInfo,
                dataUnlocks?.User,
                dataUnlockInfo
              )}
            </TabPanel>
            <TabPanel value={featureValue} index={FeatureType.MISSION}>
              {getUnlockFeatures(
                FeatureType.MISSION,
                loadingUnlocks,
                loadingUnlockInfo,
                dataUnlocks?.User,
                dataUnlockInfo
              )}
            </TabPanel>
            <TabPanel value={featureValue} index={FeatureType.MEDITATION}>
              {getUnlockFeatures(
                FeatureType.MEDITATION,
                loadingUnlocks,
                loadingUnlockInfo,
                dataUnlocks?.User,
                dataUnlockInfo
              )}
            </TabPanel>
            <TabPanel value={featureValue} index={FeatureType.TITLE}>
              {getUnlockFeatures(
                FeatureType.TITLE,
                loadingUnlocks,
                loadingUnlockInfo,
                dataUnlocks?.User,
                dataUnlockInfo
              )}
            </TabPanel>
            <TabPanel value={featureValue} index={FeatureType.METRIC}>
              {getUnlockFeatures(
                FeatureType.METRIC,
                loadingUnlocks,
                loadingUnlockInfo,
                dataUnlocks?.User,
                dataUnlockInfo
              )}
            </TabPanel>
            <TabPanel value={featureValue} index={FeatureType.FEATURE}>
              {getUnlockFeatures(
                FeatureType.FEATURE,
                loadingUnlocks,
                loadingUnlockInfo,
                dataUnlocks?.User,
                dataUnlockInfo
              )}
            </TabPanel>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );

  const [featureValue, setFeatureValue] = useState<FeatureType>(
    FeatureType.TRAINING
  );
  const handleFeatureValue = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setFeatureValue(newValue);
  };

  const buildTabPanelItem = (
    type: FeatureType,
    items: { id: string; name: string }[],
    emptyMessage: string
  ) => (
    <TabPanel value={featureValue} index={type}>
      <Typography color="textSecondary" variant="body2">
        {user.protocols && items.length > 0
          ? items.map((r, i, row) =>
              i + 1 === row.length ? (
                <Tooltip title={r.id} placement="top-start">
                  <span style={{ cursor: 'pointer' }}>{r.name}</span>
                </Tooltip>
              ) : (
                <Tooltip title={r.id} placement="top-start">
                  <span style={{ cursor: 'pointer' }}>{r.name}, </span>
                </Tooltip>
              )
            )
          : emptyMessage}
      </Typography>
      {showUnlockProgressButton(type)}
    </TabPanel>
  );

  if (loading || !user) {
    return;
  }

  const trainingPrograms = user.protocols
    .filter((protocol) => idToFeatureType(protocol.id) === FeatureType.TRAINING)
    .toSorted((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  const boostPrograms = user.protocols
    .filter((protocol) => idToFeatureType(protocol.id) === FeatureType.BOOST)
    .toSorted((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  const missions = user.missions
    .filter((mission) => idToFeatureType(mission.id) === FeatureType.MISSION)
    .toSorted((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  const meditations = user.features
    .filter((feature) => idToFeatureType(feature.id) === FeatureType.MEDITATION)
    .toSorted((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  const titles = user.features
    .filter((feature) => idToFeatureType(feature.id) === FeatureType.TITLE)
    .toSorted((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  const metrics = user.features
    .filter((feature) => idToFeatureType(feature.id) === FeatureType.METRIC)
    .toSorted((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  const features = user.features
    .filter((feature) => idToFeatureType(feature.id) === FeatureType.FEATURE)
    .toSorted((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

  const showUnlockProgressButton = (type: FeatureType) => (
    <Button
      color="primary"
      variant="contained"
      type="button"
      sx={{ mt: 2 }}
      onClick={() => {
        executeGetUnlockInfo();
        executeGetUserUnlocks({ variables: { email: user.email } });
        setFeatureValue(type);
        handleOpenModal();
      }}
    >
      Show Unlock Progress
    </Button>
  );

  return (
    <>
      {unlockProgressModal()}
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <Box sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={featureValue} onChange={handleFeatureValue}>
              <Tab label="Training Programs" />
              <Tab label="Boost Programs" />
              <Tab label="Missions" />
              <Tab label="Meditations" />
              <Tab label="Titles" />
              <Tab label="Metrics" />
              <Tab label="App Feature" />
            </Tabs>
          </Box>
          {buildTabPanelItem(
            FeatureType.TRAINING,
            trainingPrograms,
            'No training programs unlocked'
          )}
          {buildTabPanelItem(
            FeatureType.BOOST,
            boostPrograms,
            'No boost programs unlocked'
          )}
          {buildTabPanelItem(
            FeatureType.MISSION,
            missions,
            'No missions unlocked'
          )}
          {buildTabPanelItem(
            FeatureType.MEDITATION,
            meditations,
            'No meditations unlocked'
          )}
          {buildTabPanelItem(FeatureType.TITLE, titles, 'No titles unlocked')}
          {buildTabPanelItem(
            FeatureType.METRIC,
            metrics,
            'No metrics unlocked'
          )}
          {buildTabPanelItem(
            FeatureType.FEATURE,
            features,
            'No other features unlocked'
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default UserUnlocks;
