import React, { useEffect, useState } from 'react';
import { Formik, FormikErrors, Form, FormikHelpers } from 'formik';
import { useMutation, gql } from '@apollo/client';
import {
  Box,
  Button,
  CardActions,
  Container,
  Grid,
  Typography,
  FormHelperText,
  TextField
} from '@mui/material';
import ArrowRightIcon from '../../../icons/ArrowRight';
import UnlockUserProtocolsExecuter from './UnlockUserProtocolsExecuter';

type UnlockUserProtocolsInput = {
  userEmail: string;
  protocolId: string;
  numSessions: string;
};

const UnlockUserProtocols = ({ email }: { email: string }) => {
  const [requirements, setRequirements] = useState<
    { protocolId: string; numberOfSessions: number }[]
  >([]);

  const handleFormSubmit = (
    values: UnlockUserProtocolsInput,
    actions: FormikHelpers<{
      protocolId: string;
      numSessions: string;
    }>
  ) => {
    const { protocolId, numSessions } = values;
    setRequirements([]);
    setRequirements([
      { protocolId, numberOfSessions: parseInt(numSessions, 10) }
    ]);
  };

  return (
    <CardActions>
      <>
        <Grid>
          <Grid item lg={12}>
            <Typography color="textPrimary" variant="subtitle2">
              Execute Sessions
            </Typography>
            <Formik
              initialValues={{
                userEmail: '',
                protocolId: '',
                numSessions: '1'
              }}
              onSubmit={(values, actions) => {
                handleFormSubmit(values, actions);
              }}
            >
              {({ errors, handleBlur, handleChange, touched, values }) => (
                <Form>
                  <TextField
                    error={Boolean(touched.protocolId && errors.protocolId)}
                    helperText={touched.protocolId && errors.protocolId}
                    label="Protocol Id"
                    margin="normal"
                    name="protocolId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.protocolId}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.numSessions && errors.numSessions)}
                    helperText={touched.numSessions && errors.numSessions}
                    label="Number of Sessions"
                    margin="normal"
                    name="numSessions"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.numSessions}
                    variant="outlined"
                  />
                  {requirements && requirements.length > 0 && (
                    <UnlockUserProtocolsExecuter
                      email={email}
                      requirements={requirements}
                    />
                  )}

                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      endIcon={<ArrowRightIcon fontSize="small" />}
                      variant="text"
                      type="submit"
                    >
                      Execute Sessions
                    </Button>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </>
    </CardActions>
  );
};

export default UnlockUserProtocols;
