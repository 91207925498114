import { Formik } from 'formik';
import * as Yup from 'yup';
import { Card, CardContent, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { GET_COUPONS, UPDATE_COUPON } from '../../../graphql/queries';
import CouponForm from './CouponForm';
import { validationShape } from './CreateCoupon';

const EditCoupon = ({ currentCoupon, setModalOpen }) => {
  const [
    executeUpdateCoupon,
    { loading: updateLoading, data: updateData, error: updateError }
  ] = useMutation(UPDATE_COUPON);

  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" variant="overline">
          Update Coupon
        </Typography>
      </CardContent>
      <Formik
        initialValues={{
          ...currentCoupon,
          discountAmountCad: currentCoupon.discountAmountCad / 100,
          discountAmountUsd: currentCoupon.discountAmountUsd / 100,
          maxRedemptions:
            currentCoupon.maxRedemptions === -1
              ? ''
              : currentCoupon.maxRedemptions
        }}
        validationSchema={Yup.object().shape(validationShape)}
        onSubmit={(values, actions) => {
          const {
            description,
            discountDays,
            productIds,
            disabled,
            discountAmountCad,
            discountAmountUsd
          } = values;
          const variables = {
            ...values,
            validStartDate: values.validStartDate.toISOString(),
            validEndDate: values.validEndDate.toISOString(),
            maxRedemptions: !values.maxRedemptions ? -1 : values.maxRedemptions,
            description,
            discountDays,
            discountAmountCad,
            discountAmountUsd,
            productIds,
            disabled
          };

          executeUpdateCoupon({ variables, refetchQueries: [GET_COUPONS] });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
          setFieldValue
        }) => {
          return (
            <CouponForm
              values={values}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={updateError}
              data={updateData}
              loading={updateLoading}
              editMode={true}
              formErrors={errors}
              touched={touched}
              setModalOpen={setModalOpen}
            />
          );
        }}
      </Formik>
    </Card>
  );
};

export default EditCoupon;
