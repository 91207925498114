import {
  Card,
  CardContent,
  List,
  ListItem,
  Grid,
  Typography,
  CardHeader,
  IconButton,
  Avatar
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export interface ShippingTierListProps {
  shippingTiers: any[];
  onEdit: (tier: any) => void;
}

const ShippingTierList = ({ shippingTiers, onEdit }: ShippingTierListProps) => {
  return (
    <>
      <Card>
        <CardHeader title="Shipping Tiers" />
        <CardContent
          sx={{
            alignItems: 'center'
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <List
                dense
                component="div"
                role="list"
                style={{
                  width: '100%',
                  overflow: 'auto'
                }}
              >
                {shippingTiers.map((tier: any) => {
                  const warehouseDesc =
                    tier.warehouse === 'VIR'
                      ? 'US'
                      : tier.warehouse === 'BOC'
                      ? 'Germany'
                      : 'UK';

                  const countries = [...tier.countries].sort((a, b) => {
                    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  });
                  const sortedTier = {
                    ...tier,
                    countries
                  };

                  return (
                    <ListItem key={tier.id} role="listitem">
                      <Card style={{ padding: 20 }}>
                        <CardHeader
                          avatar={
                            <Avatar aria-label="shipping tier">ST</Avatar>
                          }
                          action={
                            <IconButton
                              aria-label="edit"
                              onClick={() => onEdit(sortedTier)}
                              size="large"
                            >
                              <EditIcon />
                            </IconButton>
                          }
                          title={tier.name}
                          subheader=""
                        />
                        <CardContent>
                          <Grid container>
                            <Grid container spacing={2}>
                              <Grid item md={6} xs={6}>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  Name: {tier.name}
                                </Typography>
                              </Grid>

                              <Grid item md={3} xs={3}>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  Warehouse: {warehouseDesc}
                                </Typography>
                              </Grid>
                              <Grid item md={3} xs={3}>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  Queue Order Fulfillment:{' '}
                                  {tier.queueOrderFulfillment ? 'Yes' : 'No'}
                                </Typography>
                              </Grid>
                              <Grid item md={3} xs={3}>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  Duty Paid: {tier.shipDutyPaid ? 'Yes' : 'No'}
                                </Typography>
                              </Grid>
                              <Grid item md={6} xs={6}>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  Carrier Code: {tier.carrierCode}
                                </Typography>
                              </Grid>
                              <Grid item md={3} xs={3}>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  Cost (CAD): {tier.costCad}
                                </Typography>
                              </Grid>
                              <Grid item md={3} xs={3}>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  Cost (USD): {tier.costUsd}
                                </Typography>
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  Notes: {tier.notes}
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  Countries: <br />
                                  {sortedTier.countries.map((x, i) =>
                                    i === 0 ? x.name : `, ${x.name}`
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ShippingTierList;
