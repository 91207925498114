import { FC, useState } from 'react';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Button,
  Card,
  Divider,
  CardContent,
  Typography,
  CircularProgress,
  styled,
  Box,
  Grid
} from '@mui/material';
import { AuthStateType, ROLES } from '../../../contexts/authContext';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '.MuiDataGrid-row.warn': {
    backgroundColor: theme.palette.warning.main
  }
}));

const GET_SESSIONS = gql`
  query GetUserSessions($email: String!, $page: Int!, $limit: Int!) {
    GetUserSessions(input: { email: $email, page: $page, limit: $limit }) {
      sessions {
        id
        created {
          formatted
        }
        updated {
          formatted
        }
        start {
          formatted
        }
        finish {
          formatted
        }
        chapterNumber
        sequenceNumber
        protocolId
        protocolDateKey
        state
        duration
        primerOptions
        primaryStageEyesOpen
        test
      }
      total
    }
  }
`;

type Props = {
  email: String;
  authState: AuthStateType;
  numRecentGuestSessions: any;
  onClickResetGuestSessions: any;
  resetGuestSessionsLoading: any;
};

const UserSessions: FC<Props> = (props: Props) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Session ID',
      minWidth: 150
    },
    {
      field: 'protocolId',
      headerName: 'Protocol ID',
      minWidth: 150
    },
    {
      field: 'state',
      headerName: 'Status',
      minWidth: 150
    },
    {
      field: 'start',
      headerName: 'Time Started',
      minWidth: 200,

      valueGetter: (value, row, column, apiRef) => {
        if (row.start) {
          const start = new Date(row.start?.formatted);
          return format(new Date(start), 'MM/dd/yyyy HH:mm:ss');
        } else {
          return '';
        }
      }
    },
    {
      field: 'finish',
      headerName: 'Time Ended',
      minWidth: 200,

      valueGetter: (value, row, column, apiRef) => {
        if (row.finish) {
          const finish = new Date(row.finish?.formatted);
          return format(new Date(finish), 'MM/dd/yyyy HH:mm:ss');
        } else {
          return '';
        }
      }
    },
    {
      field: 'duration',
      headerName: 'Duration',
      minWidth: 100,

      valueGetter: (value, row, column, apiRef) => {
        if (row.duration) {
          return Math.round(row.duration * 100.0) / 100.0;
        }
        return 0;
      }
    },
    {
      field: 'primaryStageEyesOpen',
      headerName: 'Eye Type',
      minWidth: 75,

      valueGetter: (value, row, column, apiRef) => {
        if (row.primaryStageEyesOpen != null) {
          return row.primaryStageEyesOpen ? 'Eyes Open' : 'Eyes Closed';
        }
        return '';
      }
    },
    {
      field: 'primerOptions',
      headerName: 'Primer Options',
      minWidth: 150,

      valueGetter: (value, row, column, apiRef) => {
        if (row.primerOptions && row.primerOptions.length > 0) {
          return row.primerOptions.join(', ');
        }
        return 'None';
      }
    },
    {
      field: 'test',
      headerName: 'Simulated Session',
      minWidth: 320,

      valueGetter: (value, row, column, apiRef) => {
        if (row.test) {
          return row.test;
        }
        return '-';
      }
    }
  ];

  const pageSizeOptions = [25, 50, 100];

  type PageState = {
    isLoading: boolean;
    data: [];
    total: number;
    page: number;
    pageSize: number;
  };

  const initPageState: PageState = {
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: pageSizeOptions[0]
  };

  const [pageState, setPageState] = useState<PageState>(initPageState);
  const [hasLoadedSessions, setHasLoadedSessions] = useState<boolean>(false);

  const [
    getSessions,
    {
      loading: sessionsLoading,
      data: sessionData,
      error: sessionError,
      client: sessionClient
    }
  ] = useLazyQuery(GET_SESSIONS);

  const reloadSession = async () => {
    await sessionClient.clearStore();
    setPageState(initPageState);
    await loadSessions();
  };

  const loadSessions = async () => {
    setHasLoadedSessions(true);
    try {
      await getSessions({
        variables: {
          email: props.email,
          limit: pageState.pageSize,
          page: pageState.page - 1
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setPageState({
      isLoading: sessionsLoading,
      data: sessionData?.GetUserSessions?.sessions
        ? sessionData.GetUserSessions.sessions
        : [],
      total: sessionData?.GetUserSessions?.total
        ? sessionData?.GetUserSessions?.total
        : 0,
      page: pageState.page,
      pageSize: pageState.pageSize
    });
  }, [sessionsLoading, sessionData]);

  useEffect(() => {
    if (hasLoadedSessions) {
      loadSessions();
    }
  }, [pageState.page, pageState.pageSize]);

  const showSessionList = () => {
    return props.authState.roles.indexOf('ADMIN') >= 0 ? (
      <>
        {pageState.isLoading ? (
          <CircularProgress />
        ) : hasLoadedSessions && pageState.data ? (
          <>
            <StyledDataGrid
              rows={pageState.data}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              pageSizeOptions={pageSizeOptions}
              pagination={true}
              paginationModel={{
                page: pageState.page - 1,
                pageSize: pageState.pageSize
              }}
              onPaginationModelChange={(newModel) =>
                setPageState((old) => ({
                  ...old,
                  page: newModel.page + 1,
                  pageSize: newModel.pageSize
                }))
              }
              paginationMode="server"
              columns={columns}
              scrollbarSize={10}
              autoHeight
              onCellClick={() => {}}
              disableColumnFilter
              checkboxSelection
              disableRowSelectionOnClick
            />
            <Button
              color="primary"
              variant="contained"
              type="button"
              disabled={pageState.isLoading}
              sx={{ mt: 2 }}
              onClick={reloadSession}
            >
              Reload Sessions
            </Button>
          </>
        ) : (
          <Button
            color="primary"
            variant="contained"
            type="button"
            sx={{ mt: 2 }}
            onClick={loadSessions}
          >
            Load Sessions
          </Button>
        )}
      </>
    ) : null;
  };

  const buildGuestSessionReset = () => {
    return (
      <>
        <Divider sx={{ mt: 2 }} />
        <Grid item md={3} xs={12} sx={{ mt: 1 }}>
          <Box
            sx={{
              display: 'flex'
            }}
            flexDirection="column"
          >
            <Typography
              sx={{
                display: 'flex'
              }}
              color="textPrimary"
              variant="subtitle2"
            >
              Guest Sessions Used
            </Typography>
            <Typography
              sx={{
                display: 'flex',
                mb: 1
              }}
              color="textSecondary"
              variant="body2"
            >
              {props.numRecentGuestSessions}
            </Typography>
            {props.authState.minAuthLevelMet(ROLES.CSA_ADMIN) && (
              <Button
                sx={{
                  display: 'flex'
                }}
                color="primary"
                variant="contained"
                onClick={props.onClickResetGuestSessions}
                disabled={props.resetGuestSessionsLoading}
              >
                Reset
              </Button>
            )}
          </Box>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Card>
        <Typography
          sx={{ mt: 1, mb: 1, ml: 2 }}
          color="textSecondary"
          variant="overline"
        >
          Sessions
        </Typography>
        <Divider />
        <CardContent
          sx={{
            alignItems: 'center',
            flex: '1 0 auto'
          }}
        >
          {showSessionList()}
          {buildGuestSessionReset()}
        </CardContent>
      </Card>
    </>
  );
};

export default UserSessions;
