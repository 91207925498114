import { useState, useEffect, FC } from 'react';
import { useMutation, gql } from '@apollo/client';
import {
  Box,
  Card,
  Grid,
  Typography,
  Button,
  Divider,
  FormHelperText,
  CircularProgress,
  FormControlLabel,
  Switch
} from '@mui/material';
import { Formik } from 'formik';

import UserOrderRefundinput from './UserOrderRefundinput';
import { OrderData } from './UserOrders';
import ConfirmModal from '../../shared/ConfirmModal';
import { getDollars } from '../../../utils/currency';
import { GET_USER } from '../users/UserInspector';
import RefundReasonNotes from './RefundReasonNotes';

export type RefundReason =
  | 'none'
  | 'other'
  | 'price_adjustment'
  | 'cancelled_order'
  | 'changed_mind'
  | 'negative_results'
  | 'wants_different_features'
  | 'too_difficult_to_use'
  | 'technical_challenges'
  | 'hardware_quality'
  | 'did_not_get_expected_results';

type RefundReasonValues = {
  refundReason: RefundReason;
  refundNotes: string;
};

const REFUND_FULL_ORDER = gql`
  mutation RefundFullOrder(
    $paymentIntentId: String!
    $refundQuantity: Int!
    $returnQuantity: Int!
    $paymentManualRefund: Boolean
    $refundReason: RefundReason!
    $refundNotes: String!
  ) {
    RefundFullOrder(
      paymentIntentId: $paymentIntentId
      refundQuantity: $refundQuantity
      returnQuantity: $returnQuantity
      paymentManualRefund: $paymentManualRefund
      refundReason: $refundReason
      refundNotes: $refundNotes
    )
  }
`;
const REFUND_PARTIAL_ORDER = gql`
  mutation RefundOrder(
    $paymentIntentId: String!
    $lineNumber: Int!
    $refundAmount: Int!
    $refundQuantity: Int
    $returnQuantity: Int
    $refundReason: RefundReason!
    $refundNotes: String!
    $paymentManualRefund: Boolean
  ) {
    RefundOrder(
      input: {
        paymentIntentId: $paymentIntentId
        lineNumber: $lineNumber
        refundAmount: $refundAmount
        refundQuantity: $refundQuantity
        returnQuantity: $returnQuantity
        refundReason: $refundReason
        refundNotes: $refundNotes
        paymentManualRefund: $paymentManualRefund
      }
    )
  }
`;

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  minHeight: '30px',
  marginBottom: '10px'
};

type Props = {
  currentOrder: OrderData;
  errorMessage: string;
  setErrorMessage: Function;

  email: string;
  modalOpen: boolean;
  closeDetailsModal: Function;
};

const UserOrderRefundDetails: FC<Props> = ({
  currentOrder,
  email,
  modalOpen,
  closeDetailsModal
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirm = () => setConfirmModalOpen(true);
  const handleCloseConfirm = () => setConfirmModalOpen(false);
  const [formRefundQuantity, setFormRefundQuantity] = useState(0);
  const [formReturnQuantity, setFormReturnQuantity] = useState(0);
  const [forceManuallRefund, setForceManualRefund] = useState(false);

  const [
    executeFullRefund,
    { loading: fullRefundLoading, data: fullRefundData, error: fullRefundError }
  ] = useMutation(REFUND_FULL_ORDER, {
    errorPolicy: 'all',
    refetchQueries: [{ query: GET_USER, variables: { email } }]
  });
  const [
    executePartialRefund,
    {
      loading: partialRefundLoading,
      data: partialRefundData,
      error: partialRefundError
    }
  ] = useMutation(REFUND_PARTIAL_ORDER, {
    errorPolicy: 'all',
    refetchQueries: [{ query: GET_USER, variables: { email } }]
  });

  useEffect(() => {
    setErrorMessage('');
    if (fullRefundError?.message) {
      setErrorMessage(fullRefundError.message);
    }
  }, [fullRefundError]);
  useEffect(() => {
    setErrorMessage('');
    if (partialRefundError?.message) {
      setErrorMessage(partialRefundError.message);
    }
  }, [partialRefundError]);
  useEffect(() => {
    if (fullRefundData?.RefundFullOrder || partialRefundData?.RefundOrder) {
      setTimeout(() => {
        closeDetailsModal();
      }, 3000);
    }
  }, [fullRefundData, partialRefundData]);

  const handleClickFullRefund = () => {
    setErrorMessage('');
    setConfirmModalOpen(true);
  };

  const handleConfirmFullRefund = async (values: {
    refundReason: RefundReason;
    refundNotes: string;
  }) => {
    setErrorMessage('');
    const { refundReason, refundNotes } = values;
    await executeFullRefund({
      variables: {
        paymentIntentId: currentOrder.paymentIntentId,
        refundQuantity: formRefundQuantity,
        returnQuantity: formReturnQuantity,
        paymentManualRefund: forceManuallRefund,
        refundReason,
        refundNotes
      }
    });
  };

  const fullyRefunded = currentOrder.amount === currentOrder.refundAmount;
  const partiallyRefunded = currentOrder.refundAmount > 0;
  const isStripeOrder = currentOrder.paymentProvider === 'stripe';
  const isIggOrder = currentOrder.paymentProvider === 'indiegogo';
  const isAffirmOrder = currentOrder.paymentProvider === 'affirm';
  const allowRefund = isStripeOrder || isIggOrder || isAffirmOrder;
  const [errorMessage, setErrorMessage] = useState('');

  const RefundReasonValues: RefundReasonValues = {
    refundReason: 'other',
    refundNotes: ''
  };

  return (
    <Formik initialValues={RefundReasonValues} onSubmit={() => {}}>
      {({ errors, handleBlur, handleChange, touched, values }) => (
        <>
          <ConfirmModal
            handleConfirm={() => handleConfirmFullRefund(values)}
            closeConfirmModal={handleCloseConfirm}
            confirmModalOpen={confirmModalOpen}
            handleCloseConfirm={handleCloseConfirm}
            heading="Confirm Issue Refund"
            subheading="Type 'REFUND' to issue refund"
            confirmText="REFUND"
          />

          <Box
            sx={{
              position: 'absolute',
              top: '5%',
              bottom: '5%',
              left: '5%',
              right: '5%',
              overflow: 'auto'
            }}
          >
            <Card sx={{ p: 4 }}>
              <Typography
                id="modal-refund"
                variant="h4"
                component="h2"
                sx={{ pb: 2 }}
              >
                Order Details
              </Typography>
              <Box sx={{ pb: 2 }}>
                {!fullyRefunded && allowRefund && (
                  <>
                    <Button
                      size="large"
                      variant="contained"
                      disabled={partiallyRefunded}
                      onClick={() => handleClickFullRefund()}
                    >
                      ISSUE FULL REFUND
                    </Button>
                  </>
                )}
                {fullyRefunded && (
                  <Typography sx={{ color: 'red' }}>
                    Order has been fully refunded.
                  </Typography>
                )}
                {!allowRefund && (
                  <Typography sx={{ color: 'red' }}>
                    Refunds can only be processed on Stripe or Indiegogo orders.
                  </Typography>
                )}
                {isIggOrder && (
                  <Typography sx={{ color: 'red' }}>
                    Recording manual refund for Indiegogo order.
                  </Typography>
                )}
                {!fullyRefunded && partiallyRefunded && (
                  <Typography sx={{ color: 'red' }}>
                    Full refunds cannot be processed on orders that have been
                    partially refunded.
                  </Typography>
                )}
              </Box>
              <Divider />
              <Grid container sx={{ py: 1, fontWeight: 700 }}>
                <Grid item xs={fullyRefunded || !allowRefund ? 4 : 2}>
                  <Typography>Description</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography sx={{ width: '100%', textAlign: 'right' }}>
                    Amount
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography sx={{ width: '100%', textAlign: 'right' }}>
                    Refunded
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography sx={{ width: '100%', textAlign: 'right' }}>
                    Net
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography sx={{ width: '100%', textAlign: 'right' }}>
                    Quantity
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography sx={{ width: '100%', textAlign: 'right' }}>
                    Quantity <br /> refunded
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography sx={{ width: '100%', textAlign: 'right' }}>
                    Quantity <br /> returned
                  </Typography>
                </Grid>
                {!fullyRefunded && allowRefund && (
                  <Grid item xs={4}>
                    <Typography sx={{ width: '100%', textAlign: 'right' }}>
                      Issue Partial Refund
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Divider />
              {modalOpen &&
                currentOrder &&
                currentOrder.lineItems
                  .filter(
                    (li) =>
                      li.lineType !== 'subtotal' && li.lineType !== 'total'
                  )
                  .sort((a, b) => (a.lineType > b.lineType ? 1 : -1))
                  .map((item) => {
                    const itemTotal =
                      item.lineType === 'sales_tax' ||
                      item.lineType === 'shipping'
                        ? item.unitAmount
                        : item.quantity * item.unitAmount;
                    return (
                      <Grid key={item.id} container spacing={2}>
                        <Grid item xs={fullyRefunded || !allowRefund ? 4 : 2}>
                          <Box sx={boxStyle}>
                            <Typography>{item.description}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box sx={boxStyle}>
                            <Typography
                              sx={{ width: '100%', textAlign: 'right' }}
                            >
                              {getDollars(itemTotal)}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box sx={boxStyle}>
                            <Typography
                              sx={{ width: '100%', textAlign: 'right' }}
                            >
                              {getDollars(item.refundAmount || 0)}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box sx={boxStyle}>
                            <Typography
                              sx={{ width: '100%', textAlign: 'right' }}
                            >
                              {getDollars(itemTotal - item.refundAmount)}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box sx={boxStyle}>
                            <Typography
                              sx={{ width: '100%', textAlign: 'right' }}
                            >
                              {item.quantity}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box sx={boxStyle}>
                            <Typography
                              sx={{ width: '100%', textAlign: 'right' }}
                            >
                              {item.refundQuantity || 0}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1}>
                          <Box sx={boxStyle}>
                            <Typography
                              sx={{ width: '100%', textAlign: 'right' }}
                            >
                              {item.returnQuantity || 0}
                            </Typography>
                          </Box>
                        </Grid>
                        {!fullyRefunded &&
                          allowRefund &&
                          item.lineType !== 'sales_tax' && (
                            <Grid item xs={4}>
                              <Box
                                sx={{
                                  ...boxStyle,
                                  justifyContent: 'flex-end'
                                }}
                              >
                                <UserOrderRefundinput
                                  quantity={item.quantity}
                                  lineType={item.lineType}
                                  pid={currentOrder.paymentIntentId}
                                  lineNumber={item.lineNumber}
                                  executePartialRefund={executePartialRefund}
                                  setErrorMessage={setErrorMessage}
                                  amountRefundable={
                                    itemTotal - item.refundAmount
                                  }
                                  item={item}
                                  fulfilmentStatus={
                                    currentOrder.fulfilmentStatus
                                  }
                                  formRefundQuantity={formRefundQuantity}
                                  setFormRefundQuantity={setFormRefundQuantity}
                                  formReturnQuantity={formReturnQuantity}
                                  setFormReturnQuantity={setFormReturnQuantity}
                                  forceManuallRefund={forceManuallRefund}
                                  refundReason={values.refundReason}
                                  refundNotes={values.refundNotes}
                                />
                              </Box>
                            </Grid>
                          )}
                      </Grid>
                    );
                  })}
              <Divider />

              <RefundReasonNotes
                handleBlur={handleBlur}
                handleChange={handleChange}
                notes={values.refundNotes}
                reason={values.refundReason}
              />

              <Divider />
              {allowRefund && !fullyRefunded && (
                <FormControlLabel
                  disabled={currentOrder.paymentProvider !== 'stripe'}
                  control={<Switch checked={forceManuallRefund} />}
                  onChange={() => {
                    setForceManualRefund(!forceManuallRefund);
                  }}
                  label="Refund Provided Manually (Stripe payment return will be skipped)"
                />
              )}
              {(fullRefundLoading || partialRefundLoading) && (
                <CircularProgress />
              )}
              {errorMessage && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errorMessage}</FormHelperText>
                </Box>
              )}
              {(fullRefundData?.RefundFullOrder ||
                partialRefundData?.RefundOrder) && (
                <Box sx={{ mt: 3 }}>
                  <Typography color="primary" variant="h5">
                    Refund sent for processing
                  </Typography>
                </Box>
              )}
            </Card>
          </Box>
        </>
      )}
    </Formik>
  );
};

export default UserOrderRefundDetails;
