import React, { useEffect, useState } from 'react';
import { Formik, FormikErrors, Form, FormikHelpers } from 'formik';
import { useMutation, gql } from '@apollo/client';
import {
  Box,
  Button,
  CardActions,
  Container,
  Grid,
  Typography,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Card
} from '@mui/material';
import MultiListSelector from '../MultiListSelector';
import ConfirmModal from '../shared/ConfirmModal';

const SAVE_TIER = gql`
  mutation SaveShippingTier(
    $id: String
    $name: String!
    $carrierCode: String
    $costCad: Float!
    $costUsd: Float!
    $countries: [String]
    $warehouse: Warehouse!
    $shipDutyPaid: Boolean!
    $notes: String!
    $queueOrderFulfillment: Boolean!
  ) {
    SaveShippingTier(
      input: {
        id: $id
        name: $name
        carrierCode: $carrierCode
        costCad: $costCad
        costUsd: $costUsd
        countries: $countries
        warehouse: $warehouse
        shipDutyPaid: $shipDutyPaid
        notes: $notes
        queueOrderFulfillment: $queueOrderFulfillment
      }
    )
  }
`;

const DELETE_TIER = gql`
  mutation DeleteShippingTier($id: String!) {
    DeleteShippingTier(id: $id)
  }
`;

type TierValues = {
  name: string;
  warehouse: string;
  shipDutyPaid: boolean;
  costCad: number;
  costUsd: number;
  carrierCode: string;
  notes: string;
  queueOrderFulfillment: boolean;
};

const AddShippingTier = ({
  tier,
  remainingCountries,
  isDefault,
  onSave
}: {
  tier?: any;
  remainingCountries: string[];
  isDefault?: boolean;
  onSave: () => void;
}) => {
  const allCountries = tier
    ? [...tier.countries.map((c) => c.name), ...remainingCountries]
    : remainingCountries;

  const [checked, setChecked] = useState<string[]>(
    tier ? tier.countries.map((x) => x.name) : []
  );
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirm = () => setConfirmModalOpen(true);
  const handleCloseConfirm = () => setConfirmModalOpen(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [
    saveShippingTier,
    { loading: loadingTier, data: dataTier, error: errorTier }
  ] = useMutation(SAVE_TIER, { errorPolicy: 'all' });

  const [
    executeDeleteTier,
    { loading: deleteLoading, data: deleteData, error: deleteError }
  ] = useMutation(DELETE_TIER, {
    errorPolicy: 'all'
  });

  useEffect(() => {
    if (dataTier) {
      console.log('data', dataTier);
      onSave();
    }
    if (errorTier) {
      console.log('error', errorTier);
    }
  }, [dataTier, errorTier]);

  const handleSubmit = (
    values: TierValues,
    actions: FormikHelpers<TierValues>
  ) => {
    const { name } = values;
    console.log('Values', values);
    saveShippingTier({
      variables: {
        id: tier?.id,
        name: values.name,
        carrierCode: values.carrierCode,
        costCad: values.costCad,
        costUsd: values.costUsd,
        countries: checked,
        warehouse: values.warehouse,
        shipDutyPaid: values.shipDutyPaid,
        notes: values.notes,
        queueOrderFulfillment: values.queueOrderFulfillment
      }
    });
  };

  const handleConfirm = async () => {
    setErrorMessage('');
    console.log('SUBMITTING DELETE SHIPPING TIER');
    await executeDeleteTier({
      variables: {
        id: tier.id
      }
    });
  };

  const handleClickDelete = () => {
    setErrorMessage('');
    setConfirmModalOpen(true);
  };

  useEffect(() => {
    setErrorMessage('');
    if (deleteError?.message) {
      setErrorMessage(deleteError.message);
    }
  }, [deleteError]);

  return (
    <>
      <ConfirmModal
        handleConfirm={handleConfirm}
        closeConfirmModal={handleCloseConfirm}
        confirmModalOpen={confirmModalOpen}
        handleCloseConfirm={handleCloseConfirm}
        heading="Confirm Delete Shipping Tier"
        subheading="Type 'DELETE' to delete shipping tier"
        confirmText="DELETE"
      />
      <Card>
        <CardActions>
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography color="textPrimary" variant="subtitle2">
                  {isDefault ? 'Edit Default' : 'Add Shipping Tier'}
                </Typography>
              </Grid>
              <Formik
                initialValues={
                  tier
                    ? { ...tier }
                    : {
                        name: '',
                        warehouse: 'CHR',
                        shipDutyPaid: false,
                        costCad: 0.0,
                        costUsd: 0.0,
                        carrierCode: '',
                        notes: '',
                        queueOrderFulfillment: true
                      }
                }
                onSubmit={(values, actions) => {
                  handleSubmit(values, actions);
                }}
              >
                {({ errors, handleBlur, handleChange, values }) => (
                  <Form style={{ width: '100%' }}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={6}>
                        <TextField
                          label="Name"
                          id="name"
                          type="text"
                          fullWidth
                          disabled={isDefault === true}
                          variant="outlined"
                          onChange={handleChange}
                          value={isDefault ? 'DEFAULT' : values.name}
                        />
                      </Grid>

                      <Grid item md={6} xs={6}>
                        <Select
                          required
                          id="warehouse"
                          autoWidth
                          label="warehouse"
                          variant="outlined"
                          size="small"
                          onBlur={handleBlur}
                          onChange={(event) => {
                            values.warehouse = event.target.value;
                            handleChange(event);
                          }}
                          value={values.warehouse}
                          sx={{ mt: 1 }}
                        >
                          <MenuItem value={'VIR'}>US Warehouse</MenuItem>
                          <MenuItem value={'BOC'}>Germany Warehouse</MenuItem>
                          <MenuItem value={'CHR'}>UK Warehouse</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <TextField
                          label="Carrier Code"
                          fullWidth
                          id="carrierCode"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.carrierCode}
                        />
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.shipDutyPaid}
                              onChange={handleChange}
                              name="shipDutyPaid"
                              color="primary"
                            />
                          }
                          label="Duty Paid"
                        />
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.queueOrderFulfillment}
                              onChange={handleChange}
                              name="queueOrderFulfillment"
                              color="primary"
                            />
                          }
                          label="Queue Order Fulfillment"
                        />
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <TextField
                          label="Cost (CAD)"
                          fullWidth
                          id="costCad"
                          variant="outlined"
                          type="number"
                          onChange={handleChange}
                          value={values.costCad}
                        />
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <TextField
                          label="Cost (USD)"
                          fullWidth
                          variant="outlined"
                          id="costUsd"
                          type="number"
                          onChange={handleChange}
                          value={values.costUsd}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          label="Notes"
                          fullWidth
                          id="notes"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.notes}
                        />
                      </Grid>
                      {!isDefault && (
                        <Grid item xs={12}>
                          <Typography color="textPrimary" variant="subtitle2">
                            Countries
                          </Typography>
                          <MultiListSelector
                            initialSelection={
                              tier ? tier.countries.map((x) => x.name) : []
                            }
                            items={allCountries}
                            onCheckChange={setChecked}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Save Tier
                        </Button>
                        <Button
                          color="warning"
                          variant="contained"
                          onClick={handleClickDelete}
                          sx={{ ml: 2 }}
                        >
                          Delete Tier
                        </Button>
                      </Grid>

                      {errorTier && (
                        <Grid item xs={12}>
                          <Box sx={{ mt: 3 }}>
                            <FormHelperText error>
                              {errorTier.message}
                            </FormHelperText>
                          </Box>
                        </Grid>
                      )}
                      {dataTier && dataTier.SaveShippingTier && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText>
                            Shipping Tier succesfully saved!
                          </FormHelperText>
                        </Box>
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
              {deleteData?.DeleteShippingTier && (
                <Box sx={{ mt: 3 }}>
                  <Typography color="primary" variant="h5">
                    Shipping tier sucessfully deleted
                  </Typography>
                </Box>
              )}
              {errorMessage && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errorMessage}</FormHelperText>
                </Box>
              )}
            </Grid>
          </Container>
        </CardActions>
      </Card>
    </>
  );
};

export default AddShippingTier;
