import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import ConfirmModal from '../../shared/ConfirmModal';
import RefundReasonNotes from '../orders/RefundReasonNotes';
import { useState } from 'react';

interface Props {
  handleConfirm: Function;
  closeConfirmModal: Function;
  handleCloseConfirm: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void;
  confirmModalOpen: boolean;
  heading: string;
  subheading: string;
  confirmText: string;
  bodyText?: string;
  reason: string;
  notes: string;
  revoke: boolean;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UserInvoiceRefundModal = ({
  handleConfirm,
  closeConfirmModal,
  confirmModalOpen,
  handleCloseConfirm,
  heading,
  subheading,
  confirmText,
  reason,
  notes,
  handleBlur,
  handleChange,
  revoke
}: Props) => {
  return (
    <ConfirmModal
      handleConfirm={handleConfirm}
      closeConfirmModal={closeConfirmModal}
      confirmModalOpen={confirmModalOpen}
      handleCloseConfirm={handleCloseConfirm}
      heading={heading}
      subheading={subheading}
      confirmText={confirmText}
    >
      <form>
        <RefundReasonNotes
          reason={reason}
          notes={notes}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
        <Typography color="textPrimary" variant="subtitle2" mt={2} mb={1}>
          Revoking a membership will immediately set the user's membership to
          inactive, removing access to any membership features.
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={revoke}
              onChange={handleChange}
              name="revoke"
              color="primary"
            />
          }
          label="Revoke"
        />
      </form>
    </ConfirmModal>
  );
};

export default UserInvoiceRefundModal;
