import {
  ApolloError,
  useLazyQuery,
  WatchQueryFetchPolicy
} from '@apollo/client';
import { useEffect } from 'react';
import API from 'src/server/serverInterface';

interface Response {
  loading: boolean;
  error?: ApolloError;
  data?: any;
  execute: (options) => void;
}

export const useApiQuery = (
  clientName: string,
  apiName: string,
  variables?: Record<string, any>,
  fetchPolicy?: WatchQueryFetchPolicy,
  lazy?: boolean
): Response => {
  const client = API.getClientDef(clientName);
  const queryDef = client.getDef(apiName);

  // console.log(vars);
  const [execute, { loading, error, data }] = useLazyQuery(queryDef.gql, {
    client: client.client,
    // variables: lazy? variables,
    fetchPolicy: fetchPolicy
  });

  useEffect(() => {
    if (!lazy) {
      execute({ variables });
    }
  }, []);

  return { loading, error, data, execute };
};
