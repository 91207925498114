import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { GridMoreVertIcon } from '@mui/x-data-grid';

interface Props {
  userEmail: string;
  paymentIntentId?: string;
  fulfilmentAltId?: number;
}

const GET_EXTERNAL_PROFILES = gql`
  query UserExternalProfileUrls($email: String!, $paymentIntentId: String) {
    UserExternalProfileUrls(email: $email, paymentIntentId: $paymentIntentId) {
      stripe
      klaviyo
      mixpanel
    }
  }
`;

const UserExternalProfilesMenu = ({
  userEmail,
  paymentIntentId,
  fulfilmentAltId
}: Props) => {
  const [urls, setUrls] = useState({ stripe: '', klaviyo: '', mixpanel: '' });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { loading, error, data } = useQuery(GET_EXTERNAL_PROFILES, {
    variables: { email: userEmail, paymentIntentId }
  });

  useEffect(() => {
    if (data?.UserExternalProfileUrls) {
      setUrls(data.UserExternalProfileUrls);
    }
  }, [data]);

  const bladeUrl =
    process.env.NODE_ENV === 'production'
      ? `https://blade.i-fulfilment.co.uk/app/orders/${fulfilmentAltId}/goodsouts`
      : `https://public-api-sandbox.staging.bladepro.co.uk/app/orders/${fulfilmentAltId}/goodsouts`;

  return (
    <>
      <Grid item>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <GridMoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {error && (
            <Grid item xs={12}>
              <Typography color="red" variant="subtitle2">
                There was an error retrieving the user's external profiles...
              </Typography>
            </Grid>
          )}

          {loading && (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          )}

          {data && urls.stripe && (
            <MenuItem>
              <a
                href={urls.stripe || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography>Stripe Profile</Typography>
              </a>
            </MenuItem>
          )}

          {data && urls.klaviyo && (
            <MenuItem>
              <a
                href={urls.klaviyo || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                Klaviyo Profile
              </a>
            </MenuItem>
          )}

          {data && urls.mixpanel && (
            <MenuItem>
              <a
                href={urls.mixpanel || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                Mixpanel Profile
              </a>
            </MenuItem>
          )}
          {!!fulfilmentAltId && (
            <MenuItem>
              <a
                href={bladeUrl || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                Fulfilment
              </a>
            </MenuItem>
          )}
        </Menu>
      </Grid>
    </>
  );
};

export default UserExternalProfilesMenu;
