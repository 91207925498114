import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { experimentalStyled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { useAuthState } from 'src/contexts/authContext';
import { Link, Outlet } from 'react-router-dom';
import NotLoggedIn from '../NotLoggedIn';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '280px'
  }
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout: FC<DashboardLayoutProps> = (props) => {
  const authState = useAuthState();

  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);

  // return (
  //   <DashboardLayoutRoot>
  //     <DashboardNavbar
  //       onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
  //     />
  //     <DashboardSidebar
  //       onMobileClose={(): void => setIsSidebarMobileOpen(false)}
  //       openMobile={isSidebarMobileOpen}
  //     />
  //     <DashboardLayoutWrapper>
  //       <DashboardLayoutContainer>
  //         <DashboardLayoutContent>
  //           <Outlet />
  //         </DashboardLayoutContent>
  //       </DashboardLayoutContainer>
  //     </DashboardLayoutWrapper>
  //   </DashboardLayoutRoot>
  // )
  return authState.isLoggedIn() ? (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
      />
      <DashboardSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  ) : (
    <NotLoggedIn />
  );
};

export default DashboardLayout;
