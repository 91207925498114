import { ChangeEvent, useState } from 'react';
import {
  TextField,
  Checkbox,
  Paper,
  List,
  ListItemText,
  ListItem,
  ListItemIcon
} from '@mui/material';

const MultiListSelector = ({
  items,
  initialSelection,
  onCheckChange
}: {
  items: string[];
  initialSelection?: string[];
  onCheckChange: (x: string[]) => void;
}) => {
  const [filter, setFileter] = useState<string>('');
  const [checked, setChecked] = useState<string[]>(
    initialSelection ? initialSelection.map((x) => x.toLowerCase()) : []
  );

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value.toLowerCase());
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value.toLowerCase());
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    onCheckChange(newChecked);
  };

  return (
    <Paper>
      <TextField
        label="Filter"
        id="filter"
        type="text"
        variant="filled"
        fullWidth
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setFileter(e.target.value)
        }
        value={filter}
      />
      <List
        dense
        component="div"
        role="list"
        style={{
          height: 150,
          width: '100%',
          minWidth: 420,
          maxHeight: 1500,
          overflow: 'auto'
        }}
      >
        {items
          .filter((i: string) =>
            i.toLowerCase().startsWith(filter.toLowerCase())
          )
          .map((c: string) => {
            const labelId = `transfer-list-item-${c.replace(' ', '_')}-label`;

            return (
              <ListItem
                key={c}
                role="listitem"
                button
                onClick={handleToggle(c)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(c.toLowerCase()) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={c} />
              </ListItem>
            );
          })}
        <ListItem />
      </List>
    </Paper>
  );
};

export default MultiListSelector;
