import { Grid, MenuItem, Select, TextField, Typography } from '@mui/material';

interface Props {
  reason: string;
  notes: string;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RefundReasonNotes = ({
  reason,
  notes,
  handleBlur,
  handleChange
}: Props) => {
  const refundKeys = [
    'other',
    'price_adjustment',
    'cancelled_order',
    'changed_mind',
    'negative_results',
    'wants_different_features',
    'too_difficult_to_use',
    'technical_challenges',
    'hardware_quality',
    'did_not_get_expected_results'
  ];

  const refundReasonOptions = refundKeys.map((k) => {
    return [
      k,
      k
        .replaceAll('_', ' ')
        .split(' ')
        .map((val) => val[0].toUpperCase() + val.slice(1))
        .join(' ')
    ];
  });

  return (
    <>
      <Grid container sx={{ py: 1, fontWeight: 700 }}>
        <Grid item xs={12} sm={6}>
          <Typography>Refund Reason</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>Refund Notes</Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ py: 1 }}>
        <>
          {' '}
          <Grid item xs={12} sm={6}>
            <Select
              id="refund-reason"
              name="refundReason"
              required
              autoWidth
              variant="outlined"
              value={reason}
              onBlur={handleBlur}
              onChange={handleChange}
              sx={{ mt: 1 }}
            >
              {refundReasonOptions.map((option) => (
                <MenuItem key={option[0]} value={option[0]}>
                  {option[1]}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="refund-notes"
              name="refundNotes"
              label="Refund Notes"
              multiline
              rows={2}
              sx={{ width: '100%' }}
              value={notes}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default RefundReasonNotes;
