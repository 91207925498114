import React, { useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, CircularProgress } from '@mui/material';

type Props = {
  handleConfirm: Function;
  closeConfirmModal: Function;
  confirmText: string;
};

const ConfirmModalInput: React.FC<Props> = ({
  handleConfirm,
  closeConfirmModal,
  confirmText
}) => {
  type Values = { confirmMessage: string };
  const [submitting, setSubmitting] = useState(false);

  const handleFormSubmit = async (
    values: Values,
    actions: FormikHelpers<{ confirmMessage: string }>
  ) => {
    setSubmitting(true);
    await handleConfirm();
    closeConfirmModal();
  };

  return (
    <Formik
      initialValues={{
        confirmMessage: ''
      }}
      validationSchema={Yup.object().shape({
        confirmMessage: Yup.string().equals([confirmText])
      })}
      onSubmit={(values, actions) => {
        if (values.confirmMessage === confirmText) {
          handleFormSubmit(values, actions);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, touched, values }) => (
        <Form>
          {!submitting && (
            <>
              <TextField
                error={Boolean(touched.confirmMessage && errors.confirmMessage)}
                fullWidth
                helperText={touched.confirmMessage && errors.confirmMessage}
                label={`Type ${confirmText}`}
                margin="normal"
                name="confirmMessage"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmMessage}
                variant="outlined"
                style={{ color: 'black' }}
              />
              <Button type="submit">Submit</Button>
              <Button onClick={() => closeConfirmModal()}>Cancel</Button>
            </>
          )}
          {submitting && (
            <>
              <CircularProgress />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ConfirmModalInput;
