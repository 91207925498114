import React, { useEffect } from 'react';

import { ChangeEvent, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Container,
  Grid,
  FormHelperText,
  Select,
  MenuItem,
  Button
} from '@mui/material';

import { Helmet } from 'react-helmet';

import { useApiQuery } from 'src/hooks/useApiQuery';
import ErpItem from './erp-item';

export type channelType = 'FZ' | 'PZ' | 'CZ';
export type distractedType = 'UNDISTRACTED' | 'DISTRACTED' | 'OVERALL';

export type metricType =
  | 'P100'
  | 'N100'
  | 'N200'
  | 'P200'
  | 'P3b'
  | 'N450'
  | 'ERN'
  | 'Pe';

const ErpAnnotation: React.FC<{}> = ({}) => {
  const [chan, setChan] = useState<channelType>('FZ');
  const [isGo, setIsGo] = useState<boolean>(true);
  const [erps, setErps] = useState<any[]>([]);

  const [offset, setOffset] = useState<number>(0);
  const [minAge, setMinAge] = useState<number>(0);
  const [maxAge, setMaxAge] = useState<number>(110);
  const [maxAssesssments, setMaxAssesssments] = useState<number>(0);

  const [assessmentId, setAssessmentId] = useState<string | undefined>();

  const { loading, error, data, execute } = useApiQuery(
    'core',
    'GetErpAnnotation',
    {
      offset: offset,
      minAgeMonths: minAge * 12,
      maxAgeMonths: maxAge * 12,
      ...(assessmentId ? { assessmentId } : {}),
      ...(maxAssesssments > 0 ? { maxAssesssments } : {})
    },
    'no-cache',
    true
  );

  useEffect(() => {
    if (data && data.ErpAnnotation) {
      setErps(
        [...data.ErpAnnotation].sort(
          (a, b) => a.assessmentNumber - b.assessmentNumber
        )
      );
    } else {
      setErps([]);
    }
  }, [data]);

  const getNextBatch = () => {
    if (chan === 'FZ' && isGo) {
      setIsGo(false);
    } else if (chan === 'FZ') {
      setIsGo(true);
      setChan('PZ');
    } else if (chan === 'PZ') {
      setChan('CZ');
    }
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Data Tools - ERP Annotation | Sens.ai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={false}>
          <div>
            <TextField
              label="Min Age"
              name="minAge"
              variant="outlined"
              onChange={(e) => {
                setMinAge(parseInt(e.target.value, 10));
              }}
              value={minAge}
              type="number"
              size="small"
              style={{ marginRight: '1rem' }}
            />
            <TextField
              label="Max Age"
              name="maxAge"
              variant="outlined"
              onChange={(e) => {
                setMaxAge(parseInt(e.target.value, 10));
              }}
              value={maxAge}
              type="number"
              size="small"
              style={{ marginRight: '1rem' }}
            />
            <TextField
              label="Skip"
              name="skip"
              variant="outlined"
              onChange={(e) => {
                setOffset(parseInt(e.target.value, 10));
              }}
              value={offset}
              type="number"
              size="small"
              style={{ marginRight: '1rem' }}
            />
            <TextField
              label="Max Assessments"
              name="maxAssessments"
              variant="outlined"
              onChange={(e) => {
                setMaxAssesssments(parseInt(e.target.value, 10));
              }}
              value={maxAssesssments}
              type="number"
              size="small"
              style={{ marginRight: '1rem' }}
            />
            <TextField
              label="ID"
              name="assessmentId"
              variant="outlined"
              onChange={(e) => {
                setAssessmentId(e.target.value ?? undefined);
              }}
              value={assessmentId}
              type="string"
              size="small"
              style={{ marginRight: '1rem' }}
            />
            <Button
              type="submit"
              disabled={loading}
              color="primary"
              variant="outlined"
              onClick={() => {
                console.log('execute');
                execute({
                  variables: {
                    offset: offset,
                    minAgeMonths: minAge * 12,
                    maxAgeMonths: maxAge * 12,
                    ...(assessmentId ? { assessmentId } : {}),
                    ...(maxAssesssments > 0 ? { maxAssesssments } : {})
                  }
                });
              }}
            >
              Load
            </Button>
          </div>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={1}
            padding={1}
            item
            xs={12}
          >
            <Grid item>
              <Typography fontSize="" color="textPrimary" variant="h5">
                Data Tools - ERP Annotation
              </Typography>
            </Grid>
            <Grid item>
              <Select
                autoWidth
                label="Type"
                variant="outlined"
                onChange={(v) => {
                  setChan(v.target.value as any as channelType);
                }}
                value={chan}
              >
                {['FZ', 'PZ', 'CZ'].map((ch) => (
                  <MenuItem key={ch} selected={chan === ch} value={ch}>
                    {ch}
                  </MenuItem>
                ))}
              </Select>

              {/* <Select
                autoWidth
                label="Type"
                variant="outlined"
                onChange={(v) => {
                  setDistracted(v.target.value as any as distractedType);
                }}
                value={distracted}
              >
                {['UNDISTRACTED', 'DISTRACTED', 'OVERALL'].map((ch) => (
                  <MenuItem selected={distracted === ch} value={ch}>
                    {ch}
                  </MenuItem>
                ))}
              </Select> */}

              <Select
                autoWidth
                label="Go-NoGo"
                variant="outlined"
                onChange={(v) => {
                  setIsGo(v.target.value === 'true');
                }}
                value={isGo}
              >
                <MenuItem selected={isGo} value={'true'}>
                  Go
                </MenuItem>
                <MenuItem selected={!isGo} value={'false'}>
                  NoGo
                </MenuItem>
              </Select>
            </Grid>
          </Grid>

          {erps &&
            erps.map((erp) => (
              <Box
                id={`erpid-${erp.id}`}
                key={`erpid-${erp.id}`}
                sx={{ marginBottom: '2rem' }}
              >
                <ErpItem
                  chan={chan}
                  data={erp}
                  isGo={isGo}
                  getNextBatch={getNextBatch}
                />
              </Box>
            ))}
        </Container>
      </Box>
    </>
  );
};

export default ErpAnnotation;
