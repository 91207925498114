import { createSvgIcon } from '@mui/material/utils';

const GuestModeIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <circle cx="12" cy="4" r="2"></circle>
    <path d="M15.89 8.11C15.5 7.72 14.83 7 13.53 7h-2.54C8.24 6.99 6 4.75 6 2H4c0 3.16 2.11 5.84 5 6.71V22h2v-6h2v6h2V10.05L18.95 14l1.41-1.41z"></path>
  </svg>,
  'GuestModeIcon'
);

export default GuestModeIcon;
