import React, { useEffect } from 'react';
import { Formik, FormikErrors, FormikHelpers, Form } from 'formik';
import { useMutation, gql } from '@apollo/client';
import {
  Box,
  Button,
  CardActions,
  Container,
  Grid,
  Typography,
  FormHelperText,
  Select,
  MenuItem
} from '@mui/material';
import ArrowRightIcon from '../../../icons/ArrowRight';
import { ROLES, useAuthState } from 'src/contexts/authContext';
import { GET_USER } from './UserInspector';

const ADD_ROLE = gql`
  mutation AddUserRole($email: String!, $roleId: UserRole!) {
    AddUserRole(email: $email, roleId: $roleId)
  }
`;

const REMOVE_ROLE = gql`
  mutation RemoveUserRole($email: String!, $roleId: UserRole!) {
    RemoveUserRole(email: $email, roleId: $roleId)
  }
`;

type RoleValues = { role: string };

const AddUserRole = ({ email }: { email: string }) => {
  const authState = useAuthState();
  const [
    executeAddRole,
    { loading: loadingAddRole, data: dataAddRole, error: errorAddRole }
  ] = useMutation(ADD_ROLE, { errorPolicy: 'all' });

  const [
    executeRemoveRole,
    { loading: loadingRemoveRole, data: dataRemoveRole, error: errorRemoveRole }
  ] = useMutation(REMOVE_ROLE, { errorPolicy: 'all' });

  useEffect(() => {
    if (dataAddRole) {
      console.log('data', dataAddRole);
    }
    if (errorAddRole) {
      console.log('error', errorAddRole);
    }
  }, [dataAddRole, errorAddRole]);

  const handleRoleFormSubmit = (
    values: RoleValues,
    actions: FormikHelpers<{ role: string }>
  ) => {
    const { role } = values;
    executeAddRole({
      variables: { email, roleId: role },
      refetchQueries: [GET_USER]
    });
  };

  const handleRoleRemoveFormSubmit = (
    values: RoleValues,
    actions: FormikHelpers<{ role: string }>
  ) => {
    const { role } = values;
    executeRemoveRole({
      variables: { email, roleId: role },
      refetchQueries: [GET_USER]
    });
  };

  const ROLES_TO_ADD_REMOVE = [
    { role: 'ADMIN', permission: ROLES.ADMIN },
    { role: 'DEV_ADMIN', permission: ROLES.ADMIN },
    { role: 'CSA_ADMIN', permission: ROLES.ADMIN },
    { role: 'CSA_MANAGER', permission: ROLES.CSA_ADMIN },
    { role: 'CSA', permission: ROLES.CSA_ADMIN },
    { role: 'FINANCIAL_ANALYST', permission: ROLES.CSA_ADMIN },
    { role: 'DEMO', permission: ROLES.ADMIN },
    { role: 'DEMO_REPLAY', permission: ROLES.ADMIN },
    { role: 'DEMO_APP_STORE', permission: ROLES.ADMIN },
    { role: 'DEMO_MEMBERSHIP_GRANTED', permission: ROLES.CSA_MANAGER },
    { role: 'BETA_FIRMWARE', permission: ROLES.ADMIN }
  ];

  return (
    <CardActions>
      <Grid container spacing={3}>
        <Grid item lg={6}>
          <Typography color="textPrimary" variant="subtitle2">
            Add Role
          </Typography>
          <Formik
            initialValues={{
              role: 'CSA'
            }}
            onSubmit={(values, actions) => {
              handleRoleFormSubmit(values, actions);
            }}
          >
            {({ errors, handleBlur, handleChange, values }) => (
              <Form>
                <Select
                  id="role"
                  name="role"
                  required
                  autoWidth
                  variant="outlined"
                  size="small"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.role}
                  sx={{ mt: 1 }}
                >
                  {ROLES_TO_ADD_REMOVE.map((role, idx) => {
                    return authState.minAuthLevelMet(role.permission) ? (
                      <MenuItem key={idx} value={role.role}>
                        {role.role}
                      </MenuItem>
                    ) : null;
                  })}
                </Select>

                <Grid item xs={12}>
                  <Button
                    color="primary"
                    endIcon={<ArrowRightIcon fontSize="small" />}
                    variant="text"
                    type="submit"
                  >
                    Add role
                  </Button>
                </Grid>
                {errorAddRole && (
                  <Grid item xs={12}>
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>
                        {errorAddRole.message}
                      </FormHelperText>
                    </Box>
                  </Grid>
                )}
                {dataAddRole && dataAddRole.AddUserRole && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText>Role succesfully added!</FormHelperText>
                  </Box>
                )}
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item lg={6}>
          <Typography color="textPrimary" variant="subtitle2">
            Remove Role
          </Typography>
          <Formik
            initialValues={{
              role: 'CSA'
            }}
            onSubmit={(values, actions) => {
              handleRoleRemoveFormSubmit(values, actions);
            }}
          >
            {({ errors, handleBlur, handleChange, values }) => (
              <Form>
                <Select
                  id="role-remove"
                  name="role"
                  required
                  autoWidth
                  variant="outlined"
                  size="small"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.role}
                  sx={{ mt: 1 }}
                >
                  {ROLES_TO_ADD_REMOVE.map((role, idx) => {
                    return authState.minAuthLevelMet(role.permission) ? (
                      <MenuItem key={idx} value={role.role}>
                        {role.role}
                      </MenuItem>
                    ) : null;
                  })}
                </Select>

                <Grid item xs={12}>
                  <Button
                    color="primary"
                    endIcon={<ArrowRightIcon fontSize="small" />}
                    variant="text"
                    type="submit"
                  >
                    Remove role
                  </Button>
                </Grid>
                {errorRemoveRole && (
                  <Grid item xs={12}>
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>
                        {errorRemoveRole.message}
                      </FormHelperText>
                    </Box>
                  </Grid>
                )}
                {dataRemoveRole && dataRemoveRole.RemoveUserRole && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText>Role succesfully removed!</FormHelperText>
                  </Box>
                )}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </CardActions>
  );
};

export default AddUserRole;
