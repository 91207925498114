import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Typography } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import { GoodsIn } from 'src/components/dashboard/inventory';

const InventoryGoodsIn: FC = () => {
  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>Dashboard: Confirm Addressess | Sens.ai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Typography color="textSecondary" variant="overline">
            Confirm Addresses for Upcoming Inventory
          </Typography>
          <GoodsIn />
        </Container>
      </Box>
    </>
  );
};

export default InventoryGoodsIn;
