import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import ResetTestUsers from 'src/components/dashboard/configuration/ResetTestUsers';

const Configuration = () => {
  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>Dashboard: Configuration | Sens.ai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize="" color="textPrimary" variant="h5">
                Configuration
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} mb={1}>
                      <Typography color="textSecondary" variant="overline">
                        Reset Appstore Test Users
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ResetTestUsers />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Configuration;
