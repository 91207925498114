import { FC, useEffect, useState } from 'react';
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  CircularProgress,
  experimentalStyled,
  CardActions
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';
import { gql, useMutation, useQuery } from '@apollo/client';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { EmailTemplate } from 'src/@types/email';

const allowStage = ['local', 'dev'];
const pageSize = 50;

const LoaderWrapper = experimentalStyled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
});

const StyledCard = experimentalStyled(Card)({
  padding: '16px',
  margin: '12px 0',
  display: 'flex',
  justifyContent: 'space-between'
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction="up" ref={ref} {...props} children={props.children} />
  );
});

const FETCH_EMAIL_TEMPLATES = gql`
  query FetchEmailTemplates {
    FetchEmailTemplates(orderBy: updated_desc) {
      id
      created {
        formatted
      }
      updated {
        formatted
      }
      emailType
      bodyText
      bodyHtml
      subject
      disabled
    }
  }
`;

const EmailTemplates: FC = () => {
  const { settings } = useSettings();
  const {
    loading: loadingFetchEmailTemplates,
    data: dataFetchEmailTemplates,
    error: errorFetchEmailTemplates
  } = useQuery(FETCH_EMAIL_TEMPLATES);

  useEffect(() => {
    if (dataFetchEmailTemplates) {
      console.log('data', dataFetchEmailTemplates);
    }
    if (errorFetchEmailTemplates) {
      console.log('error', errorFetchEmailTemplates);
    }
  }, [dataFetchEmailTemplates, errorFetchEmailTemplates]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Email Templates | Sens.ai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          {loadingFetchEmailTemplates ? (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          ) : (
            <Grid container spacing={3}>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={3}
                item
                xs={12}
              >
                <Grid item md={12} xs={12}>
                  <Typography color="textSecondary" variant="h5">
                    Email Templates
                  </Typography>

                  {dataFetchEmailTemplates?.FetchEmailTemplates?.map(
                    (template: EmailTemplate, index: number) => (
                      <StyledCard key={`email-template--${index}`}>
                        <Typography>
                          {template.emailType
                            .toLowerCase()
                            .split('_')
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(' ')}
                          {template.disabled ? ' - DISABLED' : ''}
                        </Typography>
                        <RouterLink to={`/dashboard/emails/${template.id}`}>
                          <Button color="primary" variant="contained">
                            Edit Template
                          </Button>
                        </RouterLink>
                      </StyledCard>
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
};

export default EmailTemplates;
