import { gql, useLazyQuery } from '@apollo/client';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CopyToClipboardButton from 'src/components/shared/CopyToClipboardButton';
import ArrowRightIcon from '../../../icons/ArrowRight';

interface Props {
  orderEmail: string;
  orderId: string;
}

const GET_SHIPPING_CONFIRMATION_URL = gql`
  query ShippingConfirmationUrl($orderEmail: String!, $orderId: String!) {
    ShippingConfirmationUrl(orderEmail: $orderEmail, orderId: $orderId)
  }
`;

const OrderShippingConfirmation = ({ orderEmail, orderId }: Props) => {
  const [url, setUrl] = useState('');

  const [getShippingConfirmationUrl, { loading, error, data }] = useLazyQuery(
    GET_SHIPPING_CONFIRMATION_URL,
    {
      variables: { orderEmail, orderId }
    }
  );

  const handleSubmit = () => {
    getShippingConfirmationUrl();
  };

  useEffect(() => {
    if (data?.ShippingConfirmationUrl) {
      setUrl(data.ShippingConfirmationUrl);
    }
  }, [data]);

  return (
    <>
      <Grid item xs={12}>
        <Typography color="textSecondary" variant="overline">
          Shipping Confirmation
        </Typography>
      </Grid>

      {!data?.ShippingConfirmationUrl && (
        <Grid item xs={12}>
          <Box sx={{ display: 'flex' }}>
            <Button
              color="primary"
              variant="contained"
              sx={{ mr: 2 }}
              onClick={handleSubmit}
              disabled={loading}
            >
              Get Confirmation Link
            </Button>
          </Box>
        </Grid>
      )}

      {error && (
        <Grid item xs={12}>
          <Typography color="red" variant="subtitle2">
            There was an error retrieving the shipping confirmation link...
          </Typography>
        </Grid>
      )}

      {loading && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}

      {data && (
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <a href={url} target="_blank" rel="noreferrer">
              <Button
                color="primary"
                endIcon={<ArrowRightIcon fontSize="small" />}
                variant="text"
                type="submit"
                disabled={loading}
              >
                Go to shipping confirmation
              </Button>
            </a>
            <CopyToClipboardButton
              buttonTitle="Copy shipping confirmation link"
              textToCopy={url}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default OrderShippingConfirmation;
