import { FetchResult } from '@apollo/client';
import { Client } from './client';
import { ApiDef } from './apiDef';
import {
  ANNOTATE_ERP,
  GET_USER_ERP
} from '../../src/components/dashboard/data-tools/queries';

const coreApiDefs = new Map<string, ApiDef>();
coreApiDefs.set('GetErpAnnotation', new ApiDef('query', GET_USER_ERP, false));
coreApiDefs.set('AnnotateErp', new ApiDef('mutation', ANNOTATE_ERP, false));

const healthcheckApiDefs = new Map<string, ApiDef>();
healthcheckApiDefs.set(
  'GetAppConfig',
  new ApiDef(
    'query',
    `
    query($type: [AppConfigType]!) {
      GetAppConfig(type: $type) {
        key
        value
      }
    }
`,
    true
  )
);

healthcheckApiDefs.set(
  'UpdateAppConfig',
  new ApiDef(
    'mutation',
    `
    mutation(
      $type: AppConfigType!,
      $config: String!
    ) {
      UpdateAppConfig(
        input: {
          type: $type,
          config: $config
        }
      )
    }
`,
    true
  )
);

healthcheckApiDefs.set(
  'HealthCheck',
  new ApiDef(
    'query',
    `
    query($keys: [String]!) {
      HealthCheck(keys: $keys) {
        key
        value
      }
    }
`,
    false
  )
);

healthcheckApiDefs.set(
  'HealthCheckUpdate',
  new ApiDef(
    'mutation',
    `
    mutation UpdateHealthCheckConfigs(
      $items: [UpdateHealthCheckConfigInput]
    ) {
      UpdateHealthCheckConfigs(input: $items)
    }
`,
    false
  )
);

class ServerInterface {
  clients: Map<string, Client>;

  constructor() {
    this.clients = new Map<string, Client>();
    let uri = process.env.REACT_APP_SENSAI_SERVER_BASE_URL;
    if (!uri || uri === 'localhost' || uri === 'undefined') {
      uri = 'http://localhost:2017/local';
    }

    this.clients.set(
      'healthcheck',
      new Client(`${uri}/healthcheck`, healthcheckApiDefs)
    );

    if (process.env.REACT_APP_SENSAI_SERVER_BASE_URL === 'localhost') {
      uri = 'http://localhost:2003/local';
    }
    this.clients.set('core', new Client(`${uri}/api`, coreApiDefs));
  }

  getClientDef(endPointKey: string): Client | null {
    const c = this.clients.get(endPointKey);
    if (c === null || c === undefined) {
      return null;
    }
    return c;
  }

  async execute(
    endPointKey: string,
    apiKey: string,
    variables: Record<string, unknown>
  ): Promise<FetchResult | null> {
    const c = this.clients.get(endPointKey);
    if (!c) {
      return null;
    }
    return c.execute(apiKey, variables);
  }
}

const API = new ServerInterface();

export default API;
