import { useState, useEffect, FC } from 'react';
import { Box, Card, Grid, Typography, Divider, Modal } from '@mui/material';

import { OrderData, User } from './UserOrders';
import { getDollars } from '../../../utils/currency';
import { mapFulfillmentStatus } from 'src/utils/fulfillmentUtils';
import UserExternalProfiles from '../users/UserExternalProfiles';
import OrderShippingConfirmation from './OrderShippingConfirmation';
import { format } from 'date-fns';
import UserExternalProfilesMenu from '../users/UserExternalProfilesMenu';
import { ROLES, useAuthState } from 'src/contexts/authContext';

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  minHeight: '30px'
};

type Props = {
  currentOrder: OrderData;
  handleCloseConfirm: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void;
};

const UserOrderDetails: FC<Props> = ({ currentOrder, handleCloseConfirm }) => {
  const authState = useAuthState();
  const { created, refundDate, shippedDate } = currentOrder;
  const utmKeys = [
    'utmCampaign',
    'utmContent',
    'utmMedium',
    'utmSource',
    'utmTerm'
  ];
  const utmProps = new Map(
    utmKeys.map((key) => [key, currentOrder[key] as string])
  );

  const getUtmList = () => {
    const utmList = [];
    utmProps.forEach((value, key) => {
      if (value) {
        utmList.push(
          <Typography key={`${key}-${value}`}>{`${key}: ${value}`}</Typography>
        );
      }
    });
    return utmList;
  };

  const getDate = (date: { formatted: string }) => {
    const dateObj = new Date(date.formatted);
    return dateObj.getFullYear() > 1970
      ? format(dateObj, 'MM/dd/yyyy HH:mm:ss')
      : 'n/a';
  };

  return (
    <Modal
      open={currentOrder !== null}
      onClose={handleCloseConfirm}
      aria-labelledby="modal-confirm"
      aria-describedby="modal-confirm"
      sx={{
        overflow: 'scroll',
        top: '5%',
        bottom: '5%',
        left: '5%',
        right: '5%',
        borderRadius: '16px'
      }}
    >
      <Box>
        <Card sx={{ p: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              id="modal-refund"
              variant="h4"
              component="h2"
              sx={{ pb: 2 }}
            >
              Order Details ({currentOrder.confirmationCode})
            </Typography>
            {currentOrder.email && authState.minAuthLevelMet(ROLES.CSA) && (
              <UserExternalProfilesMenu
                userEmail={currentOrder.email}
                paymentIntentId={currentOrder.paymentIntentId}
                fulfilmentAltId={currentOrder.fulfilmentAltId}
              />
            )}
          </Box>
          <Divider />
          <Grid container spacing={3} marginBottom={5} marginTop={1}>
            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                Customer
              </Typography>
              <Typography>
                {currentOrder.user
                  ? `${currentOrder.user.firstName} ${currentOrder.user.lastName}`
                  : 'Guest'}
              </Typography>
              <Typography>
                {currentOrder.user ? currentOrder.user.email : ''}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                Order Email
              </Typography>
              <Typography>
                <Typography>{currentOrder.email}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                UTM Properties
              </Typography>
              <div>{getUtmList()}</div>
            </Grid>
            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                Affiliate ID
              </Typography>
              <Typography>{currentOrder.affiliateId}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                Created Date
              </Typography>
              <Typography>{getDate(created)}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                Shipped Date
              </Typography>
              <Typography>{getDate(shippedDate)}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                Refunded Date
              </Typography>
              <Typography>{getDate(refundDate)}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                Payment ({currentOrder.paymentProvider})
              </Typography>
              <Typography>{currentOrder.status}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                Payment Id
              </Typography>
              <Typography>{currentOrder.paymentIntentId}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                Fulfillment
              </Typography>
              <Typography>
                {mapFulfillmentStatus(currentOrder.fulfilmentStatus as any)}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                Fulfillment Id
              </Typography>
              <Typography>{currentOrder.fulfilmentId}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography color="textSecondary" variant="overline">
                Coupon Code
              </Typography>
              <Typography>{currentOrder.couponCode ?? 'n/a'}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography color="textSecondary" variant="overline">
                Shipping Address
              </Typography>
              {currentOrder.shippingAddress && (
                <>
                  <Typography>
                    {currentOrder.shippingAddress.firstName}{' '}
                    {currentOrder.shippingAddress.lastName}
                  </Typography>
                  <Typography>
                    phone: {currentOrder.shippingAddress.phone}
                  </Typography>
                  <Typography>
                    {currentOrder.shippingAddress.street}
                    {currentOrder.shippingAddress.unit
                      ? `, unit ${currentOrder.shippingAddress.unit}`
                      : ''}
                  </Typography>
                  <Typography>
                    {currentOrder.shippingAddress.city}
                    {currentOrder.shippingAddress.province
                      ? `, ${currentOrder.shippingAddress.province}`
                      : ''}
                  </Typography>
                  <Typography>
                    {currentOrder.shippingAddress.country}
                  </Typography>
                  <Typography>{currentOrder.shippingAddress.postal}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography color="textSecondary" variant="overline">
                Billing Address
              </Typography>
              {currentOrder.billingAddress && (
                <>
                  <Typography>
                    {currentOrder.billingAddress.firstName}{' '}
                    {currentOrder.billingAddress.lastName}
                  </Typography>
                  <Typography>
                    phone: {currentOrder.billingAddress.phone}
                  </Typography>
                  <Typography>
                    {currentOrder.billingAddress.street}
                    {currentOrder.billingAddress.unit
                      ? `, unit ${currentOrder.billingAddress.unit}`
                      : ''}
                  </Typography>
                  <Typography>
                    {currentOrder.billingAddress.city}
                    {currentOrder.billingAddress.province
                      ? `, ${currentOrder.billingAddress.province}`
                      : ''}
                  </Typography>
                  <Typography>{currentOrder.billingAddress.country}</Typography>
                  <Typography>{currentOrder.billingAddress.postal}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography color="textSecondary" variant="overline">
                Tracking
              </Typography>
              {currentOrder.tracking && currentOrder.tracking.courierName && (
                <Typography>
                  <a
                    href={currentOrder.tracking.trackingUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {currentOrder.tracking.courierName} -{' '}
                    {currentOrder.tracking.trackingNumber}
                  </a>
                </Typography>
              )}
            </Grid>

            {currentOrder.refundReason &&
              currentOrder.refundReason !== 'none' && (
                <>
                  <Grid item xs={3}>
                    <Typography color="textSecondary" variant="overline">
                      Refund Reason
                    </Typography>
                    <Typography>{currentOrder.refundReason}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color="textSecondary" variant="overline">
                      Refund Notes
                    </Typography>
                    <Typography>{currentOrder.refundNotes}</Typography>
                  </Grid>
                </>
              )}

            <Grid item xs={12}>
              <OrderShippingConfirmation
                orderEmail={currentOrder.email}
                orderId={currentOrder.id}
              />
            </Grid>
          </Grid>

          <Divider />
          <Grid container sx={{ py: 1, fontWeight: 700 }}>
            <Grid item xs={6}>
              <Typography>Description</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ width: '100%', textAlign: 'right' }}>
                Amount
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ width: '100%', textAlign: 'right' }}>
                Refunded
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ width: '100%', textAlign: 'right' }}>
                Net
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          {currentOrder &&
            currentOrder.lineItems &&
            currentOrder.lineItems
              .filter(
                (li) => li.lineType !== 'subtotal' && li.lineType !== 'total'
              )
              .sort((a, b) => (a.lineType > b.lineType ? 1 : -1))
              .map((item) => {
                const itemTotal =
                  item.lineType === 'sales_tax' || item.lineType === 'shipping'
                    ? item.unitAmount
                    : item.quantity * item.unitAmount;
                return (
                  <Grid key={item.id} container spacing={2}>
                    <Grid item xs={6}>
                      <Box sx={boxStyle}>
                        <Typography>
                          {item.description}{' '}
                          {item.quantity > 1 ? `(x${item.quantity})` : ''}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box sx={boxStyle}>
                        <Typography sx={{ width: '100%', textAlign: 'right' }}>
                          {getDollars(itemTotal)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box sx={boxStyle}>
                        <Typography sx={{ width: '100%', textAlign: 'right' }}>
                          {getDollars(item.refundAmount || 0)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box sx={boxStyle}>
                        <Typography sx={{ width: '100%', textAlign: 'right' }}>
                          {getDollars(itemTotal - item.refundAmount)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
        </Card>
      </Box>
    </Modal>
  );
};

export default UserOrderDetails;
