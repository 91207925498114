import React, { useState } from 'react';
import { Chart as ChartComponent, getElementsAtEvent } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';
import { CategoryScale, LinearScale, Point } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { rangeSelect } from './range-select';
import { PeakResults } from './erp-item';
import { FormControlLabel, Switch } from '@mui/material';

Chart.register(LinearScale);
Chart.register(CategoryScale);
Chart.register(annotationPlugin);
Chart.register(rangeSelect);

export const SIGNAL_SHIFT = 12;

const START_MOMENTUM = 80.0;
const NEAR_DROP_RATE_MOMENTUM = 0.95;
const DROP_RATE_MOMENTUM = 0.3;
const MIN_MOMENTUM = 0.035;
const MAX_DROP = 3;

const getFalloffFromPeak = (
  peakIndex: number,
  from: number,
  to: number,
  data: number[],
  isPositive: boolean
) => {
  let momentum = START_MOMENTUM;
  let endIndex = peakIndex;

  const peakVal = data[peakIndex];
  let dropFactor = NEAR_DROP_RATE_MOMENTUM;

  for (let i = peakIndex; i <= to + 1; i++) {
    const v2 = isPositive ? data[i] : data[i + 1];
    const v1 = isPositive ? data[i + 1] : data[i];
    const drop = Math.abs(peakVal - v2);
    const isNear = drop < 0.7;
    dropFactor = isNear
      ? NEAR_DROP_RATE_MOMENTUM
      : Math.max(dropFactor - 0.05, DROP_RATE_MOMENTUM);

    momentum = momentum * dropFactor + (v2 - v1) * 4;
    // console.log('m: ', momentum);
    endIndex = i;
    if (momentum <= MIN_MOMENTUM || drop >= MAX_DROP) {
      // console.log(endIndex);
      break;
    }
  }

  momentum = START_MOMENTUM;
  let startIndex = peakIndex;

  dropFactor = NEAR_DROP_RATE_MOMENTUM;
  for (let i = peakIndex; i >= from + 1; i--) {
    const v2 = isPositive ? data[i + 1] : data[i];
    const v1 = isPositive ? data[i] : data[i + 1];
    const drop = Math.abs(peakVal - v2);
    const isNear = drop < 0.7;
    dropFactor = isNear
      ? NEAR_DROP_RATE_MOMENTUM
      : Math.max(dropFactor - 0.05, DROP_RATE_MOMENTUM);

    momentum = momentum * dropFactor + (v2 - v1) * 4;
    // console.log(momentum, v2 - v1);
    startIndex = i + 1;
    if (momentum <= MIN_MOMENTUM || drop >= MAX_DROP) {
      // console.log(startIndex);
      break;
    }
  }
  // console.log('MOMENTUM: ', startIndex, endIndex);
  return [startIndex, endIndex];
};

const findPeaks = (
  dataSets: any[],
  range: number[],
  offset: number,
  isPositive: boolean,
  exactCenter: boolean
): PeakResults => {
  const from = range[0] + offset;
  const to = range[1] + offset;
  const results: PeakResults = [];
  dataSets.forEach((d) => {
    let peakIndex = from;
    let peak = d.data[from];
    for (let i = from; i <= to; i++) {
      if (isPositive && d.data[i] > peak) {
        peakIndex = i;
        peak = d.data[i];
      } else if (!isPositive && d.data[i] < peak) {
        peakIndex = i;
        peak = d.data[i];
      }
    }

    const falloff = getFalloffFromPeak(peakIndex, from, to, d.data, isPositive);

    const start = d.data[falloff[0]];
    const end = d.data[falloff[1]];

    let x1 = -1;
    let x2 = -1;
    if ((isPositive && start < end) || (!isPositive && start > end)) {
      //start is lower starting point
      //find where start crosses end
      // console.log('start is lower');
      x2 = falloff[1];
      for (let i = falloff[0]; i <= peakIndex; i++) {
        if (isPositive && d.data[i] >= end) {
          x1 = i;
          break;
        } else if (!isPositive && d.data[i] <= end) {
          x1 = i;
          break;
        }
      }
    } else {
      //end is lower starting point
      //find where end crosses start

      x1 = falloff[0];
      for (let i = falloff[1]; i >= peakIndex; i--) {
        if (isPositive && d.data[i] >= start) {
          x2 = i;
          break;
        } else if (!isPositive && d.data[i] <= start) {
          x2 = i;
          break;
        }
      }
      // console.log('end is lower', x1, x2);
    }
    let centerIndex = x1 + Math.floor((x2 - x1) / 2) - offset;
    if (centerIndex < range[0] || centerIndex > range[1]) {
      //out of range
      centerIndex = peakIndex - offset;
    }
    // console.log(d.label, peakIndex - offset, centerIndex);
    // console.log(d.label, range[0], centerIndex, range[1]);
    // subtract signal shift of 12
    results.push({
      segmentId: d.label,
      peakTime: peakIndex - offset - SIGNAL_SHIFT,
      centerTime: exactCenter
        ? Math.floor(range[0] + (range[1] - range[0]) / 2) - SIGNAL_SHIFT
        : centerIndex - SIGNAL_SHIFT,
      amplitude: d.data[peakIndex],
      startTime: range[0] - SIGNAL_SHIFT,
      endTime: range[1] - SIGNAL_SHIFT
    });
  });
  return results;
};

const LineChart: React.FC<{
  chartData: any;
  lines: { x: number; color?: string; dashed?: boolean }[];
  offset: number;
  positivePeaks?: boolean;
  hasActiveSelection?: boolean;
  ticks: number[];
  callback?: (peaks: PeakResults) => void;
  clickCallback?: (p: number) => void;
}> = ({
  chartData,
  lines,
  offset,
  positivePeaks,
  hasActiveSelection,
  ticks,
  callback,
  clickCallback
}) => {
  const [limitHeight, setLimitHeight] = useState<boolean>(true);
  const [exactCenter, setExactCenter] = useState<boolean>(false);

  let min = 100;
  let max = -100;
  chartData.forEach((cd) => {
    if (!cd.hidden) {
      cd.data.forEach((n) => {
        if (n < min) {
          min = n;
        }
        if (n > max) {
          max = n;
        }
      });
    }
  });

  let lastPeaks: PeakResults = [];

  return (
    <>
      <div style={{ marginTop: '-1rem', marginLeft: '4rem' }}>
        <FormControlLabel
          control={
            <Switch
              checked={limitHeight}
              onChange={() => {
                setLimitHeight(!limitHeight);
              }}
            />
          }
          label="Limit Height"
        />
        <FormControlLabel
          control={
            <Switch
              checked={exactCenter}
              onChange={() => {
                setExactCenter(!exactCenter);
              }}
            />
          }
          label="Exact Center"
        />
      </div>
      <ChartComponent
        id="chartContainer"
        type="line"
        data={{
          labels: chartData[0].data.map((data, i) => i - offset),

          datasets: chartData
        }}
        options={{
          maintainAspectRatio: false,
          scales: {
            y: {
              min: limitHeight
                ? Math.max(-12, Math.floor(min))
                : Math.floor(min),
              max: limitHeight ? Math.min(12, Math.ceil(max)) : Math.ceil(max)
            },
            x: {
              min: -50,
              // max: 1050,

              display: true,
              beginAtZero: true,
              ticks: {
                callback: (value: number) =>
                  value % 50 === 0 ? value - offset : null,
                autoSkip: false,
                stepSize: 100,
                precision: 100
              }
            }
          },

          onClick: function (evt, elmts) {
            if (elmts.length > 0 && clickCallback) {
              clickCallback(elmts[0].index - offset - SIGNAL_SHIFT);
            }
            // console.log(this);
            // console.log((this as any).getElementsAtEvent(evt as any));
          },
          plugins: {
            // title: {
            //   display: true,
            //   text: 'Users Gained between 2016-2020'
            // },
            rangeSelect: {
              enabled: true,
              onSelectComplete: (event) => {
                // Get selected range
                if (event.range[0] !== event.range[1] && callback) {
                  lastPeaks = findPeaks(
                    chartData.filter((d) => !d.hidden),
                    event.range,
                    offset,
                    positivePeaks ?? true,
                    exactCenter
                  );
                  callback(lastPeaks);
                }
              }
            },
            legend: {
              display: true
            },

            annotation: {
              annotations: [
                {
                  type: 'line',

                  yMin: Math.floor(min),
                  yMax: Math.ceil(max),
                  xMin: offset,
                  xMax: offset,
                  borderColor: 'rgb(150, 150, 150)',
                  borderWidth: 2
                },
                // {
                //   type: 'line',

                //   yMin: Math.floor(min),
                //   yMax: Math.ceil(max),
                //   xMin: 353 + offset,
                //   xMax: 353 + offset,
                //   borderColor: 'rgb(250, 150, 150)',
                //   borderWidth: 2
                // },
                // {
                //   type: 'line',

                //   yMin: 10.2 - 1,
                //   yMax: 10.2 + 1,
                //   xMin: 353 + SIGNAL_SHIFT + offset,
                //   xMax: 353 + SIGNAL_SHIFT + offset,
                //   borderColor: 'rgb(50, 50, 150)',
                //   borderWidth: 2
                // },
                ...lines
                  .filter((a) => a && a.x > -100)
                  .map((a, i) => ({
                    yMin: Math.floor(min),
                    yMax: Math.ceil(max),
                    xMin: a.x + offset,
                    xMax: a.x + offset,
                    borderColor: a.color
                      ? a.color
                      : i > 0
                      ? `rgb(0, ${hasActiveSelection ? '200' : '0'},250, ${
                          !hasActiveSelection ? '0.5' : i === 1 ? '0.85' : '1'
                        })`
                      : `rgb(120,120,120)`,
                    borderWidth: 2,
                    borderDash: a.dashed ? [15, 15] : []
                  })),
                ...ticks.map((t) => ({
                  yMin: Math.floor(min),
                  yMax: Math.floor(min) + 0.25,
                  xMin: t + offset,
                  xMax: t + offset,
                  borderColor: `rgb(120,120,120)`,
                  borderWidth: 1
                }))
              ]
            }
          }
        }}
      />
    </>
  );
};
export default LineChart;
