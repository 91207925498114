import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';
import { useLazyQuery, gql } from '@apollo/client';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  FormHelperText
} from '@mui/material';
import { useAuthDispatch, useAuthState } from '../contexts/authContext';
import { LoadingButton } from '@mui/lab';

const LOGIN = gql`
  query Login($email: String!, $password: String!) {
    Login(email: $email, password: $password) {
      token
      exp
      firstName
      lastName
      email
      updated
      roles
    }
  }
`;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAuthDispatch();
  const authState = useAuthState();
  const [executeLogin, { loading, data, error }] = useLazyQuery(LOGIN);

  useEffect(() => {
    if (data) {
      dispatch({
        type: 'LOGIN',
        payload: data.Login,
        redirectUrl: '/dashboard'
      });
      setTimeout(() => {
        navigate('/dashboard', { replace: true });
      }, 700);
    }
  }, [data]);

  type Values = { email: string; password: string };
  const handleFormSubmit = (values: Values) => {
    const { email, password } = values;

    executeLogin({ variables: { email, password } });
  };

  return !authState.isLoggedIn() ? (
    <>
      <Helmet>
        <title>Login | Sens.ai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values, otherProps) => handleFormSubmit(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in to the Sens.ai admin portal
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                  disabled={loading}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  disabled={loading}
                />
                <Box sx={{ py: 2 }}>
                  <LoadingButton
                    loading={loading}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </LoadingButton>
                </Box>
                {error && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{error.message}</FormHelperText>
                  </Box>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default Login;
