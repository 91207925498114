import { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material';
import { useQuery, gql } from '@apollo/client';

interface Props {
  userEmail: string;
  paymentIntentId?: string;
  typeColor?: string;
  typeVariant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
}

const GET_EXTERNAL_PROFILES = gql`
  query UserExternalProfileUrls($email: String!, $paymentIntentId: String) {
    UserExternalProfileUrls(email: $email, paymentIntentId: $paymentIntentId) {
      stripe
      klaviyo
      mixpanel
      freshdesk
    }
  }
`;

const UserExternalProfiles = ({
  userEmail,
  paymentIntentId,
  typeColor,
  typeVariant
}: Props) => {
  const [urls, setUrls] = useState({
    stripe: '',
    klaviyo: '',
    mixpanel: '',
    freshdesk: ''
  });

  const { loading, error, data } = useQuery(GET_EXTERNAL_PROFILES, {
    variables: { email: userEmail, paymentIntentId }
  });

  useEffect(() => {
    if (data?.UserExternalProfileUrls) {
      setUrls(data.UserExternalProfileUrls);
    }
  }, [data]);

  return (
    <>
      <Grid item xs={12}>
        <Typography
          color={typeColor ? typeColor : 'textPrimary'}
          variant={typeVariant ? typeVariant : 'subtitle2'}
        >
          Links to External Profiles
        </Typography>
      </Grid>

      {error && (
        <Grid item xs={12}>
          <Typography color="red" variant="subtitle2">
            There was an error retrieving the user's external profiles...
          </Typography>
        </Grid>
      )}

      {loading && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}

      {data && (
        <Grid item xs={12}>
          <Box sx={{ display: 'flex' }}>
            {urls.stripe && (
              <a
                href={urls.stripe || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button color="primary" variant="contained" sx={{ mr: 2 }}>
                  Stripe
                </Button>
              </a>
            )}

            {urls.klaviyo && (
              <a
                href={urls.klaviyo || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button color="primary" variant="contained" sx={{ mr: 2 }}>
                  Klaviyo
                </Button>
              </a>
            )}

            {urls.mixpanel && (
              <a
                href={urls.mixpanel || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button color="primary" variant="contained" sx={{ mr: 2 }}>
                  Mixpanel
                </Button>
              </a>
            )}

            {urls.freshdesk && (
              <a
                href={urls.freshdesk || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button color="primary" variant="contained">
                  Freshdesk
                </Button>
              </a>
            )}
          </Box>
        </Grid>
      )}
    </>
  );
};

export default UserExternalProfiles;
