import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Helmet } from 'react-helmet';
import useSettings from '../../hooks/useSettings';
import {
  Box,
  CircularProgress,
  experimentalStyled,
  Container,
  Grid,
  Typography,
  TextField,
  InputLabel,
  Button,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { EmailTemplate as EmailTemplateType } from 'src/@types/email';
import { Formik, FormikErrors, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

const FETCH_EMAIL_TEMPLATE_BY_ID = gql`
  query FetchEmailTemplates($id: ID!) {
    FetchEmailTemplates(filter: { id: $id }) {
      id
      created {
        formatted
      }
      updated {
        formatted
      }
      emailType
      bodyText
      bodyHtml
      subject
      disabled
    }
  }
`;

const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UpdateEmailTemplate($input: UpdateEmailInput!) {
    UpdateEmailTemplate(input: $input)
  }
`;

const LoaderWrapper = experimentalStyled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
});

const StyledForm = experimentalStyled(Form)({
  marginTop: '24px'
});

const StyledTextField = experimentalStyled(TextField)({
  marginBottom: '32px'
});

const SubmitWrapper = experimentalStyled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '16px'
});

const EmailTemplate = () => {
  const { id } = useParams();
  const { settings } = useSettings();

  const [
    getEmailTemplateById,
    {
      loading: getEmailTemplateLoading,
      data: getEmailTemplateData,
      error: getEmailTemplateError
    }
  ] = useLazyQuery(FETCH_EMAIL_TEMPLATE_BY_ID, {
    fetchPolicy: 'network-only',
    variables: { id }
  });

  const emailTemplate: EmailTemplateType | null =
    getEmailTemplateData?.FetchEmailTemplates[0];

  const [
    updateEmailTemplate,
    {
      loading: updateEmailTemplateLoading,
      data: updateEmailTemplateData,
      error: updateEmailTemplateError
    }
  ] = useMutation(UPDATE_EMAIL_TEMPLATE, { errorPolicy: 'all' });

  const handleFormSubmit = async (values: {
    bodyText: string;
    bodyHtml: string;
    subject: string;
    disabled: boolean;
  }) => {
    const input = {
      id: emailTemplate.id,
      bodyText: values.bodyText,
      bodyHtml: values.bodyHtml,
      subject: values.subject,
      disabled: values.disabled
    };

    await updateEmailTemplate({
      variables: {
        input: input
      }
    });
    await getEmailTemplateById();
  };

  useEffect(() => {
    getEmailTemplateById();
  }, []);

  useEffect(() => {
    if (getEmailTemplateLoading) {
      console.log('LOADING');
    }
    if (getEmailTemplateData) {
      console.log('DATA', getEmailTemplateData);
    }
    if (getEmailTemplateError) {
      console.log('ERROR', getEmailTemplateError);
    }
  }, [getEmailTemplateLoading, getEmailTemplateData, getEmailTemplateError]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Email Template | Sens.ai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          {getEmailTemplateLoading || updateEmailTemplateLoading ? (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          ) : (
            <Grid container spacing={3}>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={3}
                item
                xs={12}
              >
                <Grid item md={12} xs={12}>
                  {emailTemplate && (
                    <Formik
                      initialValues={{
                        bodyText: emailTemplate.bodyText,
                        bodyHtml: emailTemplate.bodyHtml,
                        subject: emailTemplate.subject,
                        disabled: emailTemplate.disabled
                      }}
                      validationSchema={Yup.object().shape({
                        bodyText: Yup.string().required(
                          'Body text is required'
                        ),
                        subject: Yup.string().required('Subject is required'),
                        bodyHtml: Yup.string().required('Body html is required')
                      })}
                      onSubmit={(values) => {
                        handleFormSubmit(values);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue
                      }) => (
                        <>
                          <Typography color="textSecondary" variant="h5">
                            {emailTemplate.emailType
                              .toLowerCase()
                              .split('_')
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(' ')}
                          </Typography>
                          <StyledForm>
                            <SubmitWrapper>
                              <Button type="submit">Submit Changes</Button>
                            </SubmitWrapper>
                            <StyledTextField
                              error={Boolean(touched.subject && errors.subject)}
                              fullWidth
                              helperText={errors.subject}
                              label="Subject"
                              name="subject"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.subject}
                              variant="outlined"
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.disabled}
                                  onChange={handleChange}
                                  name="disabled"
                                  color="primary"
                                />
                              }
                              label="Disabled"
                            />

                            <StyledTextField
                              error={Boolean(
                                touched.bodyText && errors.bodyText
                              )}
                              fullWidth
                              multiline
                              helperText={errors.bodyText}
                              label="Body Text"
                              name="bodyText"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.bodyText}
                              variant="outlined"
                            />
                            <StyledTextField
                              error={Boolean(
                                touched.bodyHtml && errors.bodyHtml
                              )}
                              fullWidth
                              multiline
                              helperText={errors.bodyHtml}
                              label="Body Html"
                              name="bodyHtml"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.bodyHtml}
                              variant="outlined"
                            />
                          </StyledForm>
                        </>
                      )}
                    </Formik>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
};

export default EmailTemplate;
