import {
  ApolloClient,
  FetchResult,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ApiDef } from './apiDef';
import fetch from 'node-fetch';
import * as Polyfillheaders from 'fetch-headers';

global.Headers = fetch.Headers || Polyfillheaders;

export class Client {
  client: ApolloClient<NormalizedCacheObject>;

  apis: Map<string, ApiDef>;

  constructor(url: string, apis: Map<string, ApiDef>) {
    const httpLink = new HttpLink({
      uri: url,
      fetch
    });
    const authLink = setContext((req, { headers, ...context }) => {
      // get the authentication token from local storage if it exists
      const userDataRaw = localStorage.getItem('userData');

      const userData = userDataRaw && JSON.parse(userDataRaw);

      const hasContextToken = context && context.customAuthToken;
      const token =
        (context && context.customAuthToken) || (userData && userData.token);
      const hasToken = token !== undefined && token !== '' && token !== null;

      const expiryDate = userData && userData.exp && Date.parse(userData.exp);
      const currentDate = Date.now();
      const isExpired =
        !hasContextToken &&
        (!userData || !expiryDate || expiryDate < currentDate);

      // return the headers to the context so httpLink can read them

      return {
        headers: {
          ...headers,
          authorization: hasToken && !isExpired ? `Bearer ${token}` : ''
        }
      };
    });

    this.client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    });

    this.apis = apis;
  }

  getDef(apiKey: string): ApiDef | null {
    const a = this.apis.get(apiKey);
    if (a === null || a === undefined) {
      return null;
    }
    return a;
  }

  async execute(
    apiKey: string,
    variables: Record<string, unknown>
  ): Promise<FetchResult> {
    const a = this.apis.get(apiKey);
    if (a === null || a === undefined) {
      return null;
    }
    let result;
    if (a.type === 'query') {
      result = await this.client.query({ query: a.gql, variables });
    } else {
      result = await this.client.mutate({ mutation: a.gql, variables });
    }
    return result;
  }
}
