import { FC } from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  CircularProgress,
  Grid,
  Typography,
  Divider,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { experimentalStyled } from '@mui/material';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { format } from 'date-fns';

export const GET_COOLDOWN_INFO = gql`
  query GetCooldowns(
  	$start: String!
    $email: String!
  ) {
    GetCooldowns(
      input: {
        start: {
          formatted: $start
        },
        email: $email,
      }
    ) {
      programId
      programName
      type
      endDate
    }
  }
`;

export const RESET_COOLDOWN = gql`
  mutation ResetCooldowns(
    $email: String!
  	$cooldownIds: [String]
  ) {
    ResetCooldowns(input: {
      email: $email,
      cooldownIds: $cooldownIds,
    })
  }
`;

const LoaderWrapper = experimentalStyled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
});

type Props = {
  loading: boolean;
  user: any;
};

type CooldownMapItem = {
  id: string,
  name: string,
  expire: Date,
};

type CooldownMap = {
  globalCooldowns: CooldownMapItem[],
  programCooldowns: CooldownMapItem[],
};

const UserCooldowns: FC<Props> = ({ loading, user }) => {
  const [time, setTime] = useState(new Date());
  const [hasCooldowns, setHasCooldowns] = useState<boolean>(false);
  const [cooldownMap, setCooldownMap] = useState<CooldownMap>({ globalCooldowns: [], programCooldowns: [] });

  const [
    executeGetUserCooldowns,
    { loading: loadingCooldowns, data: dataCooldowns, error: errorCooldowns }
  ] = useLazyQuery(GET_COOLDOWN_INFO, {
    fetchPolicy: 'no-cache'
  });

  const [executeResetCooldown, {
    loading: loadingResetCooldown,
    data: resetCooldownData,
    error: resetCooldownError,
  }] = useMutation(RESET_COOLDOWN);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const email: string = user.email;
    const cooldownId: string = event.currentTarget.getAttribute("data-reset-id");

    if (email && cooldownId) {
      executeResetCooldown({
        variables: {
          email: email,
          cooldownIds: [
            cooldownId,
          ],
        }
      });
    }
  };

  useEffect(() => {
    const cooldowns = dataCooldowns?.GetCooldowns;
    const hasCooldowns: boolean = !errorCooldowns && cooldowns && cooldowns.length > 0;
    setHasCooldowns(hasCooldowns);

    if (hasCooldowns) {
      const updatedCooldownMap: CooldownMap = {
        globalCooldowns: [],
        programCooldowns: [],
      };

      let globalTrainCooldown;
      let globalBoostCooldown;

      for (const cooldown of cooldowns) {
        if ((cooldown.type as string).includes('SESSION_GLOBAL')) {
          if (!globalTrainCooldown || new Date(cooldown.endDate).valueOf() < globalTrainCooldown.expire.valueOf()) {
            globalTrainCooldown = {
              id: 'TRAIN',
              name: 'Training',
              expire: new Date(cooldown.endDate),
            };
          }
        } else if ((cooldown.type as string).includes('BOOST_GLOBAL')) {
          if (!globalBoostCooldown || new Date(cooldown.endDate).valueOf() < globalBoostCooldown.expire.valueOf()) {
            globalBoostCooldown = {
              id: 'BOOST',
              name: 'Boost',
              expire: new Date(cooldown.endDate),
            };
          }
        } else if (!(cooldown.type as string).includes('SESSION_GLOBAL') && !(cooldown.type as string).includes('BOOST_GLOBAL')) {
          updatedCooldownMap.programCooldowns.push({
            id: cooldown.programId,
            name: cooldown.programName,
            expire: new Date(cooldown.endDate),
          });
        }
      }

      if (globalTrainCooldown) {
        updatedCooldownMap.globalCooldowns.push(globalTrainCooldown);
      }

      if (globalBoostCooldown) {
        updatedCooldownMap.globalCooldowns.push(globalBoostCooldown);
      }

      setCooldownMap(updatedCooldownMap);
    }
  }, [dataCooldowns, errorCooldowns]);

  useEffect(() => {
    const updatedCooldownMap: CooldownMap = {
      globalCooldowns: cooldownMap.globalCooldowns,
      programCooldowns: cooldownMap.programCooldowns,
    };

    const resetCooldowns = resetCooldownData?.ResetCooldowns;
    if (!resetCooldownError && resetCooldowns && resetCooldowns.length > 0) {
      updatedCooldownMap.globalCooldowns = updatedCooldownMap.globalCooldowns.filter(gc => !resetCooldowns.includes(gc.id));
      updatedCooldownMap.programCooldowns = updatedCooldownMap.programCooldowns.filter(pc => !resetCooldowns.includes(pc.id));
    }
    setHasCooldowns((updatedCooldownMap?.globalCooldowns && updatedCooldownMap.globalCooldowns.length > 0) || (updatedCooldownMap?.programCooldowns && updatedCooldownMap.programCooldowns.length > 0));
    setCooldownMap(updatedCooldownMap);
  }, [resetCooldownData, resetCooldownError]);

  useEffect(() => {
    const isoString: string = new Date().toISOString();
    const email: string = user.email;

    executeGetUserCooldowns({
      variables: {
        start: isoString,
        email: email
      }
    });

    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Card sx={{ mt: 3 }}>
        <Card>
          <Typography
            sx={{ mt: 1, mb: 1, ml: 2 }}
            color="textSecondary"
            variant="overline"
          >
            Cooldowns
          </Typography>
          <Divider />
          <CardContent
            sx={{
              alignItems: 'center',
              flex: '1 0 auto'
            }}
          >
            {loading || loadingCooldowns ? (
              <LoaderWrapper>
                <CircularProgress />
              </LoaderWrapper>
            ) : (
              <Grid container spacing={3}>
                {!hasCooldowns || cooldownMap.globalCooldowns.length <= 0 ?
                  <>
                    <Grid item md={6} xs={12}>
                      <Typography mb={1} color="textPrimary" variant="subtitle1">
                        No Global Cooldowns
                      </Typography>
                    </Grid>
                  </> :
                  <>
                    <Grid item md={6} xs={12}>
                      <Typography mb={1} color="textPrimary" variant="subtitle1">
                        Global Cooldowns
                      </Typography>
                      {cooldownMap.globalCooldowns.map(dc => {
                        const diff = dc.expire.getTime() > time.getTime() ? dc.expire.getTime() - time.getTime() : 0;
                        const d = Math.floor(diff / (24 * 60 * 60 * 1000));
                        const h = Math.floor((diff / (1000 * 60 * 60)) % 24);
                        const m = Math.floor((diff / (1000 * 60)) % 60);
                        const s = Math.floor((diff / 1000) % 60);
                        const diffString = diff <= 0 ? 'Expired' : `${d > 0 ? `${d} day${d > 1 ? 's' : ''} ` : ''}${h > 0 ? `${h}h ` : ''}${m}m${h <= 0 ? ` ${s}s` : ''} remaining`;

                        return (
                          <Grid sx={{ display: 'flex', alignItems: 'center' }} md={12} xs={12} >
                            <Typography display="inline" color="textSecondary" variant="subtitle2">
                              {dc.name}&nbsp;-&nbsp;
                            </Typography>
                            <Typography display="inline" color="textSecondary" variant="subtitle2">
                              {diffString}
                            </Typography>
                            {diff > 0 && (!loadingResetCooldown ?
                              <Button
                                color="primary"
                                variant="text"
                                type="submit"
                                data-reset-id={dc.id}
                                onClick={handleClick}
                              >
                                Expire
                              </Button> :
                              <CircularProgress sx={{ mx: 1, my: 1 }} size="18px" />
                            )}
                          </Grid>
                        )
                      })}
                    </Grid>
                  </>
                }
                {!hasCooldowns || cooldownMap.programCooldowns.length <= 0 ? <>
                  <Grid item md={6} xs={12}>
                    <Typography mb={1} color="textPrimary" variant="subtitle1">
                      No Program Cooldowns
                    </Typography>
                  </Grid>
                </> :
                  <>
                    <Grid item md={6} xs={12}>
                      <Typography mb={1} color="textPrimary" variant="subtitle1">
                        Program Cooldowns
                      </Typography>
                      {cooldownMap.programCooldowns.map(dc => {
                        const diff = dc.expire.getTime() > time.getTime() ? dc.expire.getTime() - time.getTime() : 0;
                        const d = Math.floor(diff / (24 * 60 * 60 * 1000));
                        const h = Math.floor((diff / (1000 * 60 * 60)) % 24);
                        const m = Math.floor((diff / (1000 * 60)) % 60);
                        const s = Math.floor((diff / 1000) % 60);
                        const diffString = diff <= 0 ? 'Expired' : `${d > 0 ? `${d} day${d > 1 ? 's' : ''} ` : ''}${h > 0 ? `${h}h ` : ''}${m}m${h <= 0 ? ` ${s}s` : ''} remaining`;

                        return (<Grid sx={{ display: 'flex', alignItems: 'center' }} md={12} xs={12} >
                          <Typography display="inline" color="textSecondary" variant="subtitle2">
                            {dc.name}&nbsp;-&nbsp;
                          </Typography>
                          <Typography display="inline" color="textSecondary" variant="subtitle2">
                            {diffString}
                          </Typography>
                          {diff > 0 && (!loadingResetCooldown ?
                            <Button
                              color="primary"
                              variant="text"
                              type="submit"
                              data-reset-id={dc.id}
                              onClick={handleClick}
                            >
                              Expire
                            </Button> :
                            <CircularProgress sx={{ mx: 1, my: 1 }} size="18px" />
                          )}
                        </Grid>
                        )
                      })}
                    </Grid>
                  </>}
              </Grid>
            )}
          </CardContent>
        </Card>
      </Card>
    </>
  );
};

export default UserCooldowns;
