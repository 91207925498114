export type PaymentIntentStatus =
  | 'created'
  | 'requires_payment_method'
  | 'requires_confirmation'
  | 'requires_action'
  | 'processing'
  | 'requires_capture'
  | 'disputed_refunded'
  | 'canceled'
  | 'succeeded'
  | 'failed'
  | 'refunded'
  | 'partially_refunded';

export type FulfilmentStatus =
  | 'UNDEFINED' //initially created in our system
  | 'QUEUED_BACKORDER' //backorder not sent to fulfiment - requires manual release action
  | 'ERROR'
  | 'draft' //only if created through blade UI
  | 'awaiting_allocation' //initally created in Blade
  | 'hung'
  | 'returned'
  | 'awaiting_payment'
  | 'awaiting_picking' //enough stock
  | 'awaiting_despatch' //fully picked
  | 'backorder_release'
  | 'backorder' //not enough stock
  | 'cancelled' //we cancelled the order
  | 'return_open'
  | 'item_refunded'
  | 'item_replaced'
  | 'awaiting_collection' //book the shipment (if we use our own then awaying collection).
  | 'gift'
  | 'despatched'; //sent

export const mapFulfillmentStatus = (status: FulfilmentStatus): string => {
  switch (status) {
    case 'UNDEFINED':
      return 'None';
    case 'QUEUED_BACKORDER':
      return 'Queued';
    case 'awaiting_allocation':
      return 'Awaiting Allocation';
    case 'awaiting_picking':
      return 'Picking';
    case 'awaiting_despatch':
      return 'Pending Dispatch';
    case 'despatched':
      return 'Dispatched';
    case 'backorder':
      return 'Backorder';
    case 'gift':
      return 'Gift';
    default:
      break;
  }
  return status;
};

export const WAREHOUSE_MAP: Map<string, string> = new Map<string, string>([
  ['VIR', 'US'],
  ['BOC', 'Germany'],
  ['CHR', 'UK']
]);
