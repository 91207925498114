import { createSvgIcon } from '@mui/material/utils';

const BandSettings = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M7 18h2V6H7zm4 4h2V2h-2zm-8-8h2v-4H3zm12 4h2V6h-2zm4-8v4h2v-4z"></path>
  </svg>,
  'BandSettings'
);

export default BandSettings;
