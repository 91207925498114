import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { OrderData } from './UserMemberships';
import { format } from 'date-fns';
import { getDollars } from 'src/utils/currency';

interface Props {
  data: {
    membership: OrderData;
    expiredMemberships: OrderData[];
  };
  handleClickExpiredMembership: (membership: OrderData) => void;
}

const UserMembershipHistory = ({
  data,
  handleClickExpiredMembership
}: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">ID</TableCell>
            <TableCell align="left">Start Date</TableCell>
            <TableCell align="left">End Date</TableCell>
            <TableCell align="left">Provider Status</TableCell>
            <TableCell align="left">Provider</TableCell>
            <TableCell align="left">Period</TableCell>
            <TableCell align="left">Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.expiredMemberships.map((row) => (
            <TableRow
              onClick={() => handleClickExpiredMembership(row)}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="left">
                {format(new Date(row.startDate.formatted), 'MMM dd, yyyy')}
              </TableCell>
              <TableCell align="left">
                {format(
                  new Date(row.expiredSubscription.updated.formatted),
                  'MMM dd, yyyy'
                )}
              </TableCell>
              <TableCell align="left">
                {row.expiredSubscription.status}
              </TableCell>
              <TableCell align="left">
                {row.expiredSubscription.provider}
              </TableCell>
              <TableCell align="left">
                {row.expiredSubscription.period}
              </TableCell>
              <TableCell align="left">
                {`${getDollars(
                  row.expiredSubscription.price
                )} ${row.expiredSubscription.currency.toUpperCase()}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserMembershipHistory;
