import { Button, Snackbar } from '@mui/material';
import { useState } from 'react';

import Clipboard from '../../icons/Clipboard';

interface Props {
  buttonTitle?: string;
  textToCopy: string;
}

const CopyToClipboardButton = ({ buttonTitle, textToCopy }: Props) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <>
      <div>
        <Button
          onClick={handleClick}
          color="primary"
          endIcon={<Clipboard fontSize="small" />}
          variant="text"
          type="submit"
        >
          {buttonTitle ? buttonTitle : 'Copy to clipboard'}
        </Button>
      </div>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={3000}
        message="Copied to clipboard"
      />
    </>
  );
};

export default CopyToClipboardButton;
