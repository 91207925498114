import React, { useEffect, useState } from 'react';
import { Formik, FormikErrors, FormikHelpers, Form } from 'formik';
import { useMutation, gql } from '@apollo/client';
import {
  Box,
  Button,
  CardActions,
  Container,
  Grid,
  Typography,
  FormHelperText,
  TextField
} from '@mui/material';
import ArrowRightIcon from '../../../icons/ArrowRight';
import UnlockUserProtocolsExecuter from './UnlockUserProtocolsExecuter';

type UnlockUserMissionProtocolsInput = {
  missionId: string;
};

const UNLOCK_USER_MISSION_PROTOCOLS = gql`
  mutation UnlockUserMissionProtocols(
    $userEmail: String!
    $missionId: String!
  ) {
    UnlockUserMissionProtocols(userEmail: $userEmail, missionId: $missionId) {
      protocolId
      numSessions
    }
  }
`;

const UnlockUserMissionProtocols = ({ email }: { email: string }) => {
  const [executeUnlockUserMissionProtocols, { loading, data, error }] =
    useMutation(UNLOCK_USER_MISSION_PROTOCOLS, { errorPolicy: 'all' });

  const [requirements, setRequirements] = useState<
    { protocolId: string; numberOfSessions: number }[]
  >([]);

  useEffect(() => {
    if (data) {
      console.log('data', data);
      if (
        data.UnlockUserMissionProtocols &&
        data.UnlockUserMissionProtocols.length > 0
      ) {
        const req = data.UnlockUserMissionProtocols.map((d) => ({
          protocolId: d.protocolId,
          numberOfSessions: d.numSessions
        }));
        setRequirements(req);
      }
    }
    if (error) {
      console.log('error', error);
    }
  }, [data, error]);

  const handleFormSubmit = (
    values: UnlockUserMissionProtocolsInput,
    actions: FormikHelpers<{
      missionId: string;
    }>
  ) => {
    const { missionId } = values;
    setRequirements([]);
    executeUnlockUserMissionProtocols({
      variables: {
        userEmail: email,
        missionId
      }
    });
  };

  return (
    <CardActions>
      <Grid>
        <Grid item lg={12}>
          <Typography color="textPrimary" variant="subtitle2">
            Execute Mission Sessions
          </Typography>
          <Formik
            initialValues={{
              missionId: ''
            }}
            onSubmit={(values, actions) => {
              handleFormSubmit(values, actions);
            }}
          >
            {({ errors, handleBlur, handleChange, touched, values }) => (
              <Form>
                <TextField
                  error={Boolean(touched.missionId && errors.missionId)}
                  helperText={touched.missionId && errors.missionId}
                  label="Mission Id"
                  margin="normal"
                  name="missionId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.missionId}
                  variant="outlined"
                />
                {requirements && requirements.length > 0 && (
                  <UnlockUserProtocolsExecuter
                    email={email}
                    requirements={requirements}
                  />
                )}

                {error && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{error.message}</FormHelperText>
                  </Box>
                )}
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    endIcon={<ArrowRightIcon fontSize="small" />}
                    variant="text"
                    type="submit"
                  >
                    Execute Sessions
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </CardActions>
  );
};

export default UnlockUserMissionProtocols;
